import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import PropTypes from "prop-types";

import AutoSuggest from "../AutoSuggest.js/AutoSuggest";
import CartBag from "../CartBag/CartBag";
import SideBar from "./components/SideBar";
// import ModalRegistrationButton from '../ModalRegistrationButton/ModalRegistrationButton';
// import ModalLoginButton from '../ModalLoginButton/ModalLoginButton';
import UserDropDown from "../UserDropDown/UserDropDown";

import {
    getSiteOption,
    getCategoryTree,
    scrollToTop,
    toggleLoginModal,
    getPage,
    findInCategory,
    loadProducts,
    logoutUser,
    getPageItemsSeeMore
} from "../../../helpers/caboodleHelper";
// import History from '../../../History';

import { ASSET_URL } from "../../../constants/config";
import logo from "../../../assets/img/apicuria_logo.png";
import sampleIcon from "../../../assets/img/sampleicon.png";
import navLeaf1 from "../../../assets/img/nav-leaf-1.png";
import navLeaf2 from "../../../assets/img/nav-leaf-2.png";
import axios from "axios";
import { getUser, isLoggedIn } from "../../../helpers/sessionHelper";
import { Fragment } from "react";
import { setCustomerAddressToAddressForm } from "../../../actions/address_form.actions";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import ProfileDropDown from "./components/ProfileDropDown";
import Cart from "./components/Cart";

const Nav = ({ location, user, page, products, site_options }) => {
    const cancelToken = useRef(null);

    const [open, setOpen] = useState(false);
    const [animating, setAnimating] = useState(false);
    const [isMoved, setIsMoved] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [journalOn, setJournalOn] = useState(false);

    const productCount =
        products.list && products.list.length ? products.list.length : 0;

    useEffect(() => {
        cancelToken.current = axios.CancelToken.source();
        getPage("navbar", cancelToken.current);
        getPageItemsSeeMore("journals").then(res => {
            if (res.value && res.value.items.length > 0) {
                setJournalOn(true);
            }
        });

        loadProducts({ page_size: 2 }, cancelToken.current);

        const slug = [
            "free-shipping-note",
            "facebook-page",
            "instagram-page",
            "twitter-page"
        ];
        getSiteOption({ slug }, cancelToken.current);

        return () => {
            if (cancelToken.current) cancelToken.current.cancel();
            cancelToken.current = null;
        };
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", handleWindowScroll);

        return () => {
            window.removeEventListener("scroll", handleWindowScroll);
        };
    });

    useEffect(() => {
        if (open) {
            toggle();
        }

        if (showSearch) {
            toggleSearch();
        }
    }, [location]);

    const handleWindowScroll = e => {
        const nav = document.querySelector(".nav-fixed");
        if (nav) {
            if (window.scrollY >= nav.clientHeight) {
                if (!isMoved) {
                    setIsMoved(true);
                }
            } else {
                if (isMoved) {
                    setIsMoved(false);
                }
            }
        }
    };

    const toggle = () => {
        if (animating) {
            return;
        }

        setTimeout(() => {
            setAnimating(false);
        }, 500);

        setOpen(!open);
        setAnimating(true);
    };

    const navToggle = () => {
        if (open) {
            return [
                "nav-overlay open",
                "hamburger open",
                "nav navbar-nav open"
            ];
        }
        return ["nav-overlay", "hamburger", "nav navbar-nav"];
    };

    const checkUserInfo = () => {
        let alertStatus = false;

        if (user.hasOwnProperty("name")) {
            if (
                !user.customer.firstname ||
                !user.customer.lastname ||
                !user.customer.phone ||
                !user.customer.gender ||
                !user.customer.birthday
            ) {
                alertStatus = true;
            }
        }

        return alertStatus;
    };

    const toggleSearch = state => {
        let newState = state ? state : !showSearch;
        setShowSearch(newState);
    };

    return (
        <div>
            <div className={`main-navbar ${isMoved && "moved"}`}>
                <SideBar
                    open={open}
                    toggle={toggle}
                    user={user}
                    links={page && page.navbar && page.navbar.contents}
                    productCount={productCount}
                    products={products}
                />
                <div onClick={toggle} className={navToggle()[0]} />
                <div className="nav-fixed">
                    <nav
                        className={`navbar navbar-default scrolled`}
                        style={{ backgroundColor: "#164E36" }}
                    >
                        <img className="leaf leaf-left" src={navLeaf1} />
                        <img className="leaf leaf-right" src={navLeaf2} />
                        <div
                            className="nav-stickout"
                            style={{ backgroundColor: "#164E36" }}
                        >
                            <div className="relative">
                                <img
                                    className="leaf leaf-left"
                                    src={navLeaf1}
                                />
                                <img
                                    className="leaf leaf-right"
                                    src={navLeaf2}
                                />
                                <div className="container">
                                    <div className="navbar-header txt-white">
                                        <NavLink
                                            to="/"
                                            className="navbar-brand"
                                        >
                                            <img src={logo} alt="Apicuria" />
                                        </NavLink>
                                        {productCount ? (
                                            productCount > 1 ? (
                                                <NavLink
                                                    to="/products"
                                                    exact
                                                    className="navbar-link hide-md"
                                                    text="Shop"
                                                >
                                                    Shop
                                                </NavLink>
                                            ) : (
                                                <NavLink
                                                    to={`/product/${products.list[0].slug}/view`}
                                                    exact
                                                    className="navbar-link hide-md"
                                                    text="About the Product"
                                                >
                                                    About the Product
                                                </NavLink>
                                            )
                                        ) : (
                                            <></>
                                        )}
                                        <NavLink
                                            to="/about"
                                            exact
                                            className="navbar-link hide-md"
                                            text="About us"
                                        >
                                            About us
                                        </NavLink>
                                        {journalOn && (
                                            <NavLink
                                                to="/journals"
                                                exact
                                                className="navbar-link hide-md"
                                            >
                                                Journal
                                            </NavLink>
                                        )}
                                        <NavLink
                                            to="/faqs"
                                            exact
                                            className="navbar-link hide-md"
                                        >
                                            FAQs
                                        </NavLink>
                                        <NavLink
                                            to="/contact"
                                            exact
                                            className="navbar-link hide-md"
                                        >
                                            Contact Us
                                        </NavLink>
                                    </div>
                                    <div className="navbar-right">
                                        {!isLoggedIn() ? (
                                            <Fragment>
                                                <NavLink
                                                    to="/registration"
                                                    exact
                                                    className="navbar-link hide-md"
                                                >
                                                    <i className="fas fa-user" />{" "}
                                                    Registration
                                                </NavLink>
                                                <NavLink
                                                    to="/login"
                                                    exact
                                                    className="navbar-link hide-md margin-left-20"
                                                >
                                                    <i className="fas fa-circle txt-green" />{" "}
                                                    Log in
                                                </NavLink>
                                            </Fragment>
                                        ) : (
                                            <ProfileDropDown />
                                        )}
                                        <Cart />
                                        {products.list &&
                                            products.list.length == 1 && (
                                                <Link
                                                    to={`/product/${products.list[0].slug}/view`}
                                                    exact
                                                    className="btn btn-primary margin-right-20"
                                                >
                                                    <i className="fas fa-shopping-basket" />{" "}
                                                    <span className="hide-xs">
                                                        Shop Now
                                                    </span>
                                                </Link>
                                            )}
                                        {site_options.facebook_page && (
                                            <div className="margin-right-20 hide-xs">
                                                <a
                                                    target="_blank"
                                                    href={
                                                        site_options.facebook_page
                                                    }
                                                >
                                                    <i className="fab fa-facebook txt-white txt-xlarge" />
                                                </a>
                                            </div>
                                        )}
                                        {site_options.twitter_page && (
                                            <div className="margin-right-20 hide-xs">
                                                <a
                                                    target="_blank"
                                                    href={
                                                        site_options.twitter_page
                                                    }
                                                >
                                                    <i className="fab fa-twitter txt-white txt-xlarge" />
                                                </a>
                                            </div>
                                        )}
                                        {site_options.instagram_page && (
                                            <div className="margin-right-20 hide-xs">
                                                <a
                                                    target="_blank"
                                                    href={
                                                        site_options.instagram_page
                                                    }
                                                >
                                                    <i className="fab fa-instagram txt-white txt-xlarge" />
                                                </a>
                                            </div>
                                        )}
                                        <div className="show-md">
                                            <div
                                                className={`hamburger`}
                                                onClick={toggle}
                                            >
                                                <span />
                                                <span />
                                                <span />
                                            </div>
                                        </div>
                                        <button
                                            className="btn-icon btn btn-primary"
                                            onClick={() => scrollToTop()}
                                        >
                                            <i className="far fa-long-arrow-up" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            {/* <div className={`hamburger`} onClick={toggle}>
                                        <span />
                                        <span />
                                        <span />
                                    </div> */}
                            <div className="navbar-header txt-white">
                                <NavLink to="/" className="navbar-brand">
                                    <img src={logo} alt="Apicuria" />
                                </NavLink>

                                {productCount ? (
                                    productCount > 1 ? (
                                        <NavLink
                                            to="/products"
                                            exact
                                            className="navbar-link hide-md"
                                            text="Shop"
                                        >
                                            Shop
                                        </NavLink>
                                    ) : (
                                        <NavLink
                                            to={`/product/${products.list[0].slug}/view`}
                                            exact
                                            className="navbar-link hide-md"
                                            text="About the Product"
                                        >
                                            About the Product
                                        </NavLink>
                                    )
                                ) : (
                                    <></>
                                )}
                                <NavLink
                                    to="/about"
                                    exact
                                    className="navbar-link hide-md"
                                >
                                    About us
                                </NavLink>
                                {journalOn && (
                                    <NavLink
                                        to="/journals"
                                        exact
                                        className="navbar-link hide-md"
                                    >
                                        Journal
                                    </NavLink>
                                )}
                                <NavLink
                                    to="/faqs"
                                    exact
                                    className="navbar-link hide-md"
                                >
                                    FAQs
                                </NavLink>
                                <NavLink
                                    to="/contact"
                                    exact
                                    className="navbar-link hide-md"
                                >
                                    Contact Us
                                </NavLink>
                            </div>
                            <div className="navbar-right">
                                {!isLoggedIn() ? (
                                    <Fragment>
                                        <NavLink
                                            to="/registration"
                                            exact
                                            className="navbar-link hide-md"
                                        >
                                            <i className="fas fa-user" />{" "}
                                            Registration
                                        </NavLink>
                                        <NavLink
                                            to="/login"
                                            exact
                                            className="navbar-link hide-md"
                                        >
                                            <i className="fas fa-circle" /> Log
                                            In
                                        </NavLink>
                                    </Fragment>
                                ) : (
                                    <ProfileDropDown />
                                )}

                                <Cart />

                                {products.list && products.list.length == 1 && (
                                    <Link
                                        to={`/product/${products.list[0].slug}/view`}
                                        exact
                                        className="btn btn-primary margin-right-20"
                                    >
                                        <i className="fas fa-shopping-basket" />{" "}
                                        <span className="hide-xs">
                                            Shop Now
                                        </span>
                                    </Link>
                                )}
                                {site_options.facebook_page && (
                                    <div className="margin-right-20 hide-xs">
                                        <a
                                            target="_blank"
                                            href={site_options.facebook_page}
                                        >
                                            <i className="fab fa-facebook txt-white txt-xlarge" />
                                        </a>
                                    </div>
                                )}
                                {site_options.twitter_page && (
                                    <div className="margin-right-20 hide-xs">
                                        <a
                                            target="_blank"
                                            href={site_options.twitter_page}
                                        >
                                            <i className="fab fa-twitter txt-white txt-xlarge" />
                                        </a>
                                    </div>
                                )}
                                {site_options.instagram_page && (
                                    <div className="margin-right-20 hide-xs">
                                        <a
                                            target="_blank"
                                            href={site_options.instagram_page}
                                        >
                                            <i className="fab fa-instagram txt-white txt-xlarge" />
                                        </a>
                                    </div>
                                )}
                                <div className="show-md margin-right-20">
                                    <div
                                        className={`hamburger`}
                                        onClick={toggle}
                                        style={{ marginRight: 0 }}
                                    >
                                        <span style={{}} />
                                        <span style={{}} />
                                        <span style={{}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="mobile-search-container">
                    <AutoSuggest location={location} />
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        page: state.page,
        cart: state.cart,
        order: state.order,
        site_options: state.site_options,
        products: state.products
    };
}

export default connect(mapStateToProps, null)(Nav);
