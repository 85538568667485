import React, {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle
} from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
    getAreaList,
    getCityList,
    getCountryList,
    getProvinceList
} from "../../../helpers/caboodleHelper";
import TextInput from "../Form/TextInput";

const AddressForm = forwardRef(({ type }, ref) => {
    const select_options = useSelector(state => state.select_options);
    const address_form = useSelector(state => state.address_form);
    const user = useSelector(state => state.user);
    let address = {};

    if (type == "shipping") {
        address = address_form.shipping;
    } else {
        address = address_form.billing;
    }

    const [form, setForm] = useState(address);
    const [mounted, setMounted] = useState(false);

    useImperativeHandle(ref, () => ({
        submitForm() {
            return new Promise((resolve, reject) => {
                try {
                    setShowError(true);
                    if (validateForm()) {
                        return resolve({ status: true, form });
                    }
                    return resolve({ status: false });
                } catch (err) {
                    return reject(err);
                }
            });
        }
    }));

    const initialFormError = {
        firstname: "First name is required.",
        lastname: "Last name is required.",
        phone: "Number is required.",
        country_id: "Select a country.",
        province_id: "Select a province.",
        city_id: "Select a city.",
        area_id: "Select a area.",
        zip: "Postal code is required.",
        address_line_1: "Address is required."
    };

    const [fetchingCountry, setFetchingCountry] = useState(true);
    const [fetchingProvince, setFetchingProvince] = useState(true);
    const [fetchingCity, setFetchingCity] = useState(true);
    const [fetchingArea, setFetchingArea] = useState(true);

    const [showError, setShowError] = useState(false);
    const [formError, setFormError] = useState(initialFormError);

    const validateForm = () => {
        const rules = {
            firstname: { required: true },
            lastname: { required: true },
            phone: { required: true, phone: true },
            email: { required: true },
            country_id: { required: true },
            province_id: { required: true },
            city_id: { required: true },
            area_id: { required: true },
            zip: { required: true },
            address_line_1: { required: true }
        };

        let isValid = true;

        Object.keys(rules).map(key => {
            if (rules[key].required) {
                if (form[key] == "" || form[key] == undefined) {
                    setFormError(prevFormError => ({
                        ...prevFormError,
                        [key]: initialFormError[key]
                    }));
                    isValid = false;
                } else {
                    setFormError(prevFormError => ({
                        ...prevFormError,
                        [key]: undefined
                    }));
                }
            }
        });

        return isValid;
    };

    useEffect(() => {
        getCountryList().then(res => {
            setFetchingCountry(false);
        });
    }, []);

    useEffect(() => {
        if (form.country_id) {
            getProvinceList().then(res => {
                setFetchingProvince(false);
            });
        }
    }, [form.country_id]);

    useEffect(() => {
        if (form.province_id) {
            getCityList({
                region_id: form.province_id
            }).then(res => {
                setFetchingCity(false);
            });
        }
    }, [form.province_id]);

    useEffect(() => {
        if (form.city_id) {
            getAreaList({
                city_id: form.city_id
            }).then(res => {
                setFetchingArea(false);
            });
        }
    }, [form.city_id]);

    useEffect(() => {
        if (mounted) {
            validateForm();
        } else {
            setMounted(!mounted);
        }
    }, [form]);

    const handleFormChange = e => {
        const name = e.target.name;
        let value = e.target.value;

        if (name == "phone") {
            value = parseInt(value);

            if (value) {
                value = "0" + value;
            }

            if (Number.isNaN(value)) {
                value = "";
            }
        }

        setForm(prevForm => ({ ...prevForm, [name]: value }));
    };

    const countryChange = e => {
        setFetchingProvince(true);
        setFetchingCity(true);
        setFetchingArea(true);
        setForm(prevForm => ({
            ...prevForm,
            province_id: "",
            city_id: "",
            area_id: ""
        }));
    };

    const provinceChange = e => {
        setFetchingCity(true);
        setFetchingArea(true);
        setForm(prevForm => ({
            ...prevForm,
            city_id: "",
            area_id: ""
        }));
    };

    const cityChange = e => {
        setFetchingArea(true);
        setForm(prevForm => ({
            ...prevForm,
            area_id: ""
        }));
    };

    return (
        <div className="shipping-form">
            <div className="row">
                <div className="col-md-6">
                    <TextInput
                        name="firstname"
                        value={form.firstname}
                        onChange={e => handleFormChange(e)}
                        placeholder="First Name"
                        error={showError ? formError.firstname : undefined}
                    />
                </div>
                <div className="col-md-6">
                    <TextInput
                        name="lastname"
                        value={form.lastname}
                        onChange={e => handleFormChange(e)}
                        placeholder="Last name"
                        error={showError ? formError.lastname : undefined}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <TextInput
                        name="phone"
                        value={form.phone}
                        onChange={e => handleFormChange(e)}
                        placeholder="Mobile (ex. 09123456789)"
                        error={showError ? formError.phone : undefined}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <TextInput
                        name="email"
                        value={form.email}
                        onChange={e => handleFormChange(e)}
                        placeholder="Email address"
                        error={showError ? formError.email : undefined}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <TextInput
                        name="company"
                        value={form.company}
                        onChange={e => handleFormChange(e)}
                        placeholder="Company (optional)"
                        error={showError ? formError.company : undefined}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <TextInput
                        name="country_id"
                        value={form.country_id}
                        onChange={e => {
                            countryChange();
                            handleFormChange(e);
                        }}
                        placeholder="country"
                        disabled={fetchingCountry}
                        type="select"
                        error={showError ? formError.country_id : undefined}
                    >
                        <option value="" selected>
                            Country
                        </option>
                        {select_options.country_list &&
                            select_options.country_list.length > 0 &&
                            select_options.country_list.map((country, idx) => (
                                <option key={idx} value={country.id}>
                                    {country.name}
                                </option>
                            ))}
                    </TextInput>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <TextInput
                        name="province_id"
                        value={form.province_id}
                        onChange={e => {
                            provinceChange();
                            handleFormChange(e);
                        }}
                        placeholder="Province"
                        disabled={fetchingProvince}
                        type="select"
                        error={showError ? formError.province_id : undefined}
                    >
                        <option value="" selected>
                            Province
                        </option>
                        {select_options.province_list &&
                            select_options.province_list.length > 0 &&
                            select_options.province_list.map(
                                (province, idx) => (
                                    <option key={idx} value={province.id}>
                                        {province.name}
                                    </option>
                                )
                            )}
                    </TextInput>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <TextInput
                        name="city_id"
                        value={form.city_id}
                        onChange={e => {
                            cityChange();
                            handleFormChange(e);
                        }}
                        placeholder="City"
                        disabled={fetchingCity}
                        type="select"
                        error={showError ? formError.city_id : undefined}
                    >
                        <option value="" selected>
                            City
                        </option>
                        {select_options.city_list &&
                            select_options.city_list.length > 0 &&
                            select_options.city_list.map((city, idx) => (
                                <option key={idx} value={city.id}>
                                    {city.name}
                                </option>
                            ))}
                    </TextInput>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <TextInput
                        name="area_id"
                        value={form.area_id}
                        onChange={e => handleFormChange(e)}
                        placeholder="City"
                        disabled={fetchingArea}
                        type="select"
                        error={showError ? formError.area_id : undefined}
                    >
                        <option value="" selected>
                            Area
                        </option>
                        {select_options.area_list &&
                            select_options.area_list.length > 0 &&
                            select_options.area_list.map((area, idx) => (
                                <option key={idx} value={area.id}>
                                    {area.name}
                                </option>
                            ))}
                    </TextInput>
                </div>
                <div className="col-md-6">
                    <TextInput
                        name="zip"
                        value={form.zip}
                        onChange={e => handleFormChange(e)}
                        placeholder="Postal Code"
                        error={showError ? formError.zip : undefined}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <TextInput
                        name="address_line_1"
                        value={form.address_line_1}
                        onChange={e => handleFormChange(e)}
                        placeholder="Address"
                        error={showError ? formError.address_line_1 : undefined}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{ marginBottom: 0 }}>
                    <TextInput
                        name="address_line_2"
                        value={form.address_line_2}
                        onChange={e => handleFormChange(e)}
                        placeholder="Apartment, suite, etc. (optional)"
                        error={showError ? formError.address_line_2 : undefined}
                    />
                </div>
            </div>
        </div>
    );
});

AddressForm.propTypes = {
    type: PropTypes.string
};

function mapStateToProps(state, ownProps) {
    return {
        select_options: state.select_options,
        shipping_form: state.shipping_form,
        user: state.user
    };
}

export default AddressForm;
