import { store } from "../index";
import moment from "moment";
import * as apiHelper from "../helpers/apiHelper";
import * as types from "../constants/actionTypes";
import { getUser } from "../helpers/sessionHelper";
// import { SITE_URL } from '../constants/config';

export function updateCustomerDetails(user) {
    return store.dispatch({
        type: types.UPDATE_USER,
        payload: user
    });
}

export function activateAccount(token) {
    return store.dispatch({
        type: types.CONFIRM_USER,
        payload: apiHelper.connect(false).post(`/confirm`, { token })
    });
}

export function getCustomerCreditHistories(customer_id, sorting) {
    return store.dispatch({
        type: types.GET_USER_CREDITS,
        payload: apiHelper
            .connect(false)
            .get(
                `/customers/get-credits?cid=${customer_id}${
                    sorting && sorting.type
                        ? `&sortBy[type]=${sorting.type}`
                        : ""
                }${
                    sorting && sorting.date
                        ? `&sortBy[date]=${sorting.date}`
                        : ""
                }`
            )
    });
}

// ADDRESS RELATED ACTIONS --- START ----
export function getCustomerAddress(user) {
    return store.dispatch({
        type: types.GET_CUSTOMER_ADDRESS,
        payload: apiHelper
            .connect(false)
            .get(`/customers/get-addresses?customer_id=${user.customer.id}`)
    });
}

export function setCustomerPrimaryAddress(params) {
    return store.dispatch({
        type: types.SET_CUSTOMER_PRIMARY_ADDRESS,
        payload: apiHelper
            .connect(false)
            .post(`/customers/set-default-address`, params)
    });
}

export function deleteAddress(params) {
    return store.dispatch({
        type: types.DELETE_CUSTOMER_ADDRESS,
        payload: apiHelper
            .connect(false)
            .post(`/customers/delete-address`, params)
    });
}

// export function createAddress(address) {
//     return store.dispatch({
//         type: types.CREATE_CUSTOMER_ADDRESS,
//         payload: apiHelper.connect(false).post(`/customers/create-address`, address)
//     });
// }

// export function updateAddress(address) {
//     return store.dispatch({
//         type: types.UPDATE_CUSTOMER_ADDRESS,
//         payload: apiHelper.connect(false).post(`/customers/update-address`, address)
//     });
// }
// ADDRESS RELATED ACTIONS ---- END ----

export function getCustomerDetails(identifiers) {
    return apiHelper.connect(false).post(`/customer/find`, identifiers);
}

export function getCustomerOrders(params) {
    return apiHelper
        .connect(false)
        .get(`/customers/get-customer-order`, { params });
}

export function removeWishlist(data) {
    return apiHelper.connect(false).post(`/customers/remove-wish-list`, data);
}

export function requestUpdate(data) {
    return apiHelper.connect(false).post(`/customers/update-details`, data);
}

export function updatePassword(data) {
    return apiHelper.connect(false).post(`/customers/update-password`, data);
}

export function voidOrder(data) {
    return apiHelper.connect(false).post(`/orders/void`, data);
}

export function sendOrderRequest(params) {
    return store.dispatch({
        type: types.ORDER_REQUEST_SENT,
        payload: apiHelper
            .connect(false)
            .post(`/customer/order-request/`, params)
    });
}

export function getCustomerCart() {
    return apiHelper
        .connect(false)
        .get(`/orders/user/cart`)
        .then(res => {
            if (getUser().hasOwnProperty("name") && res.data) {
                let params = {
                    customer_id: res.data.customer_id,
                    source: res.data.source,
                    last_device: res.data.last_device,
                    last_active: moment(res.data.last_active).format(
                        "YYYY-MM-DD"
                    ),
                    customer_type: res.data.customer_type
                };

                params.items = JSON.parse(res.data.cart_data);
                if (params.items && params.items.length > 0) {
                    return store.dispatch({
                        type: types.SET_CART,
                        payload: params
                    });
                } else {
                    store.dispatch({
                        type: types.RESET_CART,
                        payload: {}
                    });
                    return false;
                }
            } else {
                store.dispatch({
                    type: types.RESET_CART,
                    payload: {}
                });
                return false;
            }
        });
}
