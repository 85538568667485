import React from "react";
import axios from "axios";
import { NavLink } from "react-router-dom";
import moment from "moment";

import { store } from "../index";
import * as apiHelper from "./apiHelper";
import * as types from "../constants/actionTypes";
import { ASSET_URL, SITE_URL } from "../constants/config";
import { getCart, isLoggedIn, getUser, setCartDate } from "./sessionHelper";
import Login from "../modules/_global/Login/Login";
import Registration from "../modules/_global/Registration/Registration";
import ForgotPassword from "../modules/_global/ForgotPassword/ForgotPassword";
import EllipsisLoader from "../modules/_global/Loaders/EllipsisLoader";
import ImageNotFound from "../assets/img/notfound.png";
import history from "../History";

export function getPage(slug, cancelToken) {
    if (slug && slug !== "") {
        let request = {};
        if (cancelToken) {
            request = apiHelper
                .connect(false)
                .get("/page/" + slug, { cancelToken: cancelToken.token });
        } else {
            request = apiHelper.connect(false).get("/page/" + slug);
        }

        return store.dispatch({
            type: types.GET_PAGE,
            payload: request
        });
    } else {
        const request = { data: {} };

        return store.dispatch({
            type: types.GET_PAGE + "_RESET",
            payload: request
        });
    }
}

export function getBanks() {
    return apiHelper.connect(false).get("/checkout/bank_details");
}

export function resetPage(slug) {
    return store.dispatch({
        type: types.GET_PAGE + types.FULFILLED,
        payload: { data: { slug } }
    });
}

export function getCountryList() {
    const request = apiHelper.connect(false).get("/zones/country_list");
    return store.dispatch({
        type: types.COUNTRY_LIST,
        payload: request
    });
}
export function getProvinceList() {
    const request = apiHelper.connect(false).get("/zones/province_list");
    return store.dispatch({
        type: types.PROVINCE_LIST,
        payload: request
    });
}
export function getCityList(params) {
    const request = apiHelper.connect(false).get("/zones/city_list", {
        params: params
    });
    return store.dispatch({
        type: types.CITY_LIST,
        payload: request
    });
}
export function getAreaList(params) {
    const request = apiHelper.connect(false).get("/zones/area_list", {
        params: params
    });
    return store.dispatch({
        type: types.AREA_LIST,
        payload: request
    });
}

export function getBranchList() {
    return store.dispatch({
        type: types.GET_BRANCH_LIST,
        payload: apiHelper.connect(false).get("/branches/list")
    });
}

export function createAddress(params) {
    if (params.customer_id != 0) {
        return {
            type: "TEST", //temporary type
            payload: apiHelper
                .connect(false)
                .post("/customers/create-address", params)
        };
    }

    return apiHelper.connect(false).post("/customers/create-address", params);
}

export function editQty(action, product, currQty, inputQty) {
    const step = product.unit ? 0.25 : 1;
    let minCartQty = product.parent.minimum_qty,
        newQty = parseFloat(currQty);

    if (action === "add") {
        newQty += parseFloat(step);
    } else if (action === "sub") {
        newQty -= parseFloat(step);
    } else {
        if (isNaN(inputQty.replace(/[^\d.-]/g, ""))) {
            newQty = 0;
        } else {
            newQty = parseFloat(inputQty);
        }
    }

    //--- for selling unit with weight type only
    //--- check if qty step is incorrect
    if (step < 1 && newQty - parseInt(newQty) !== 0) {
        if (((newQty - parseInt(newQty)) * 100) % 25 !== 0) {
            let key = 0,
                offset = 0,
                excess = (newQty - parseInt(newQty)) * 100,
                steps = [25, 50, 75];

            steps.map(s => {
                if (offset === 0 || offset > Math.abs(s - excess)) {
                    offset = Math.abs(s - excess);
                    key = s;
                }
            });

            newQty = parseFloat(parseInt(newQty) + key / 100).toFixed(2);
        }
    }

    if (newQty < minCartQty) {
        newQty = minCartQty * 1;
    }

    if (newQty > product.inventory && product.enable_inventory === 1) {
        newQty = product.inventory * 1;
    }

    return newQty;
}

export function removeToCart(product, massRemove = false) {
    const cart = getCart();
    setCartDate();
    const items = cart.items.slice();
    // eslint-disable-next-line

    if (massRemove) {
        let index = -1;

        product.map(prod => {
            index = items.findIndex(item => item.product_id == prod.product.id);

            if (index > -1) {
                items.splice(index, 1);
            }
        });
    } else {
        let index = -1;
        if (product.giftcard) {
            index = items.findIndex(
                item =>
                    item.giftcard &&
                    item.gift_card.send_to == product.gift_card.send_to
            );
        } else if (product.bundle) {
            index = items.findIndex(
                item =>
                    item.bundle &&
                    item.bundleObj.id == product.bundleObj.id &&
                    item.included_products == product.included_products
            );
        } else {
            index = items.findIndex(item => item.product_id == product.id);
        }

        if (index > -1) {
            items.splice(index, 1);
        }
    }

    const user = getUser();
    let params = {
        customer_id: user.hasOwnProperty("name") ? user.customer_id : 0,
        source: "Webiste",
        last_device: window.mobilecheck() ? "Mobile" : "Desktop",
        last_active: moment().format("YYYY-MM-DD"),
        items,
        customer_type: user.hasOwnProperty("name")
            ? user.customer_active_type
            : "regular"
    };

    if (user.hasOwnProperty("name")) {
        toggleWholePageLoader(true);
        return apiHelper
            .connect()
            .post("/orders/sync-cart", params)
            .then(res => {
                toggleWholePageLoader(false);
                return store.dispatch({
                    type: types.SET_CART,
                    payload: params
                });
            });
    } else {
        return store.dispatch({
            type: types.SET_CART,
            payload: params
        });
    }
}
export function resetCart() {
    return store.dispatch({
        type: types.RESET_CART,
        payload: {}
    });
}
export function resetOrder() {
    return store.dispatch({
        type: types.RESET_ORDER,
        payload: {}
    });
}

export function resetShippingForm() {
    return store.dispatch({
        type: types.RESET_SHIPPING_FROM,
        payload: {}
    });
}

export function resetBillingForm() {
    return store.dispatch({
        type: types.RESET_BILLING_FROM,
        payload: {}
    });
}

export function resetAddressForm() {
    return store.dispatch({
        type: types.RESET_ADDRESS_FORM,
        payload: {}
    });
}

export function syncOrderToCart(orderItems) {
    let cartItems = [];
    orderItems.map(item => {
        let newItem;
        if (item.type === "giftcard") {
            newItem = {
                gift_card: {
                    denomination: item.price,
                    price: item.price,
                    quantity: item.quantity,
                    send_to: item.notes,
                    currency: item.order.currency
                },
                giftcard: 1,
                quantity: item.quantity
            };
        } else if (item.type === "bundle") {
            newItem = {
                bundleObj: item.bundle,
                bundle: 1,
                included_products: item.included_products,
                quantity: item.quantity
            };
        } else {
            newItem = {
                product: item.product,
                product_id: item.product.id,
                quantity: item.quantity,
                replacement: {
                    type: item.replacement_type,
                    customer_reference: item.customer_reference_id
                }
            };
        }

        cartItems.push(newItem);
    });

    return store.dispatch({
        type: types.SYNC_ORDER_TO_CART,
        payload: { items: cartItems }
    });
}

export function updateUserCartProducts(items) {
    apiHelper
        .connect(false)
        .post(`/order/update-cart-products`, { items })
        .then(res => {
            if (res.data) {
                return store.dispatch({
                    type: types.CART_UPDATE_ITEMS,
                    payload: res.data
                });
            }
        });
}

export function addToCart(
    product,
    qty,
    openModalSuccess = true,
    overWriteQty = false,
    proceedToCheckout = false
) {
    product = Object.assign({}, product);
    const cart = getCart();
    setCartDate();
    const items = cart.items.slice();
    const cartData = [];
    let total = 0;
    // eslint-disable-next-line
    if (product.giftcard) {
        let index = items.findIndex(
            item =>
                item.giftcard == 1 && item.gift_card.send_to === product.send_to
        );
        if (index > -1) {
            items[index].gift_card.denomination += parseFloat(
                product.denomination
            );
            items[index].gift_card.price += parseFloat(product.denomination);
        } else {
            items.push({
                giftcard: 1,
                quantity: 1,
                gift_card: product
            });
        }
    } else if (product.bundle) {
        let included_products = [];
        // format selected products
        if (product.included_products) {
            included_products = product.included_products;
        } else {
            if (product.selected) {
                product.selected.map(sItem => {
                    included_products.push(
                        `${sItem.id}|${sItem.pivot.quantity}`
                    );
                });
            } else {
                product.products.map(sItem => {
                    included_products.push(
                        `${sItem.id}|${sItem.pivot.quantity}`
                    );
                });
            }
            included_products = included_products.join(":");
        }

        let index = items.findIndex(
            item =>
                item.bundle === 1 &&
                item.bundleObj.id === product.id &&
                item.included_products === included_products
        );
        if (index > -1) {
            if (overWriteQty) {
                items[index].quantity = parseFloat(qty);
            } else {
                items[index].quantity =
                    parseFloat(items[index].quantity) + parseFloat(qty);
            }
        } else {
            items.push({
                bundle: 1,
                quantity: qty ? qty : 1,
                included_products,
                bundleObj: product
            });
        }
    } else {
        let index = items.findIndex(item => item.product_id == product.id);

        if (index > -1) {
            if (overWriteQty) {
                total = qty;
            } else {
                total = +items[index].quantity + +qty;
            }
            // eslint-disable-next-line
            if (
                items[index].product.enable_inventory == 1 &&
                items[index].product.inventory >= total
            ) {
                items[index].quantity = total;
                // eslint-disable-next-line
            } else if (items[index].product.enable_inventory == 1) {
                items[index].quantity = items[index].product.inventory;
            } else {
                items[index].quantity = total;
            }
        } else {
            delete product.order_items;
            delete product.product_reviews;
            delete product.parent;

            items.push({
                product_id: product.id,
                quantity: qty,
                product: product
            });
        }
    }

    const user = getUser();
    let params = {
        customer_id: 0,
        source: "Webiste",
        last_device: window.mobilecheck() ? "Mobile" : "Desktop",
        last_active: moment().format("YYYY-MM-DD"),
        items,
        customer_type: user.hasOwnProperty("name")
            ? user.customer_active_type
            : "regular"
    };

    if (user.hasOwnProperty("name")) {
        apiHelper.connect().post("/orders/sync-cart", params);
    }

    // if (openModalSuccess) {
    //     toggleModal(true);
    //     setModalContent(product, qty);
    //     setTimeout(
    //         function() {
    //             toggleModal(false);
    //         }.bind(this),
    //         1500
    //     );
    // }

    if (isLoggedIn()) {
        params = Object.assign({}, params, {
            customer_id: getUser().customer.id
        });
    }

    delete params.cart_data;

    if (proceedToCheckout) {
        store.dispatch({
            type: types.SET_CART,
            payload: params
        });
        
        history.push("/checkout");
    } else {
        return store.dispatch({
            type: types.SET_CART,
            payload: params
        });
    }
}

export function setCartItemReplacement(product, replacement) {
    product = Object.assign({}, product);
    const cart = getCart();
    setCartDate();
    const items = cart.items.slice();

    let index = items.findIndex(item => item.product_id == product.product_id);

    if (index > -1) {
        product.replacement = replacement;
        items[index] = product;
    }

    const user = getUser();
    let params = {
        customer_id: 0,
        source: "Webiste",
        last_device: window.mobilecheck() ? "Mobile" : "Desktop",
        last_active: moment().format("YYYY-MM-DD"),
        items,
        customer_type: user.hasOwnProperty("name")
            ? user.customer_active_type
            : "regular"
    };

    if (user.hasOwnProperty("name")) {
        apiHelper.connect().post("/orders/sync-cart", params);
    }

    if (isLoggedIn()) {
        params = Object.assign({}, params, {
            customer_id: getUser().customer.id
        });
    }

    delete params.cart_data;

    return store.dispatch({
        type: types.SET_CART,
        payload: params
    });
}

export function updateCart(cart) {
    return apiHelper.connect().post("/orders/sync-cart", cart);
}

export function retrieveCart(ctkn) {
    return apiHelper.connect(false).post(`/customer/retieve-cart`, { ctkn });
}

export function getShippingZonesList(cancelToken) {
    let request = {};

    if (cancelToken) {
        request = apiHelper
            .connect(false)
            .get(`/get/shipping/zones`, { cancelToken: cancelToken.token });
    } else {
        request = apiHelper.connect(false).get(`/get/shipping/zones`);
    }

    return store.dispatch({
        type: types.GET_SHIPPING_ZONE_LIST,
        payload: request
    });
}

export function getFeatured(take, withMerchants = 1, params) {
    params = {
        page: 1,
        page_size: take,
        featured: "featured",
        sortBy: "order-asc",
        ...params
    };

    return store.dispatch({
        type: types.PRODUCTS_GET_FEATURED,
        payload: apiHelper.connect(false).get(`/v2/products/caboodle_get`, {
            params
        })
    });
}

export function getBestSeller(take = 6, withMerchants = 1) {
    let params = {
        page: 1,
        page_size: take,
        best_seller: "best-seller"
    };

    if (withMerchants === 0) {
        params.no_merchants = 1;
    }

    return store.dispatch({
        type: types.PRODUCTS_GET_BEST_SELLER,
        payload: apiHelper.connect(false).get(`/v2/products/caboodle_get`, {
            params
        })
    });
}

export function getSiteOption(param, cancelToken) {
    let request = {};
    if (cancelToken) {
        request = apiHelper.connect(false).post("/get_site_option", param, {
            cancelToken: cancelToken.token
        });
    } else {
        request = apiHelper.connect(false).post("/get_site_option", param);
    }
    return store.dispatch({
        type: types.GET_SITE_OPTION,
        payload: request
    });
}

export function getNewArrivals(take = 6, params) {
    return store.dispatch({
        type: types.PRODUCTS_GET_NEW_ARRIVAL,
        payload: apiHelper.connect(false).get(`/v2/products/caboodle_get`, {
            params: {
                page: 1,
                page_size: take,
                new_arrival: "new-arrival",
                ...params
            }
        })
    });
}

export function getActiveFlashSale() {
    return store.dispatch({
        type: types.GET_ACTIVE_FLASH_SALE,
        payload: apiHelper.connect(false).get(`/active-flash-sale/get`)
    });
}

export function getWholesalerDiscounts() {
    return apiHelper.connect(false).get(`/wholesaler-discounts`);
}

export function userRegister(data) {
    return apiHelper.connect(false).post("/auth/register", data);
}

export function userSocialMediaLogin(creds) {
    return store.dispatch({
        type: types.LOGIN_USER,
        payload: apiHelper.connect(false).post(`/oauth/login`, creds)
    });
}

export function connectSocialMediaAccount(creds) {
    return store.dispatch({
        type: types.CONNECT_SOCIAL_MEDIA,
        payload: apiHelper.connect(false).post(`/oauth/connect/account`, creds)
    });
}

export function userComingSoonRegsitration(data) {
    const request = apiHelper.connect(false).post(`/oauth/login`, data);

    return request;
}

export function loginUser(creds) {
    if (creds.type === undefined) {
        creds.type = "regular";
    }

    return store.dispatch({
        type: types.LOGIN_USER,
        payload: apiHelper.connect(false).post(`/auth`, creds)
    });
}

export function logoutConfirmation() {
    const confimartionEl = (
        <div className="text-center txt-dark-link pad-50 logout-confirmation">
            <div className="txt-bold txt-xxlarge margin-top-20">
                Leaving your cart too soon?
            </div>
            <div className="txt-medium">Are you sure you want to log out?</div>
            <div className="flex justify-center margin-top-30">
                <button
                    className="btn btn-green btn-pad-xlarge margin-right-10"
                    onClick={() => logoutUser(false)}
                >
                    YES, I'M SURE
                </button>
                <button
                    className="btn btn-clear-bordered-light-gray btn-pad-xlarge"
                    onClick={() => toggleModal(false)}
                >
                    CANCEL
                </button>
            </div>
        </div>
    );

    setModalContent(false, false, confimartionEl);
    toggleModal(true);
}

export function logoutUser(withNotif = true) {
    resetAddressForm();
    resetCart();
    resetOrder();

    if (withNotif) {
        const confimartionEl = (
            <div className="text-center txt-dark-link pad-50 logout-confirmation relative-container">
                <div>
                    <i className="fas fa-check-circle txt-giantsize txt-warning" />
                </div>
                <div className="txt-header-2 txt-bold margin-top-30">
                    You have successfully logged out
                </div>
                <div className="txt-large">
                    Come back soon! Your cart already misses you.
                </div>
                <div
                    className="margin-top-50 txt-success txt-large"
                    onClick={() => toggleModal(false)}
                >
                    <NavLink to="/login">Wait, I want to go back in!</NavLink>
                </div>
                <div
                    className="dismiss-btn clickable"
                    onClick={() => toggleModal(false)}
                >
                    <i className="far fa-times-circle txt-xxlarge" />
                </div>
            </div>
        );

        setModalContent(false, false, confimartionEl);
    } else {
        toggleModal(false);
    }

    history.push("/");
    return store.dispatch({
        type: types.LOGOUT_USER
    });
}

export function sendInquiry(data) {
    return apiHelper.connect(false).post("/inquiry/send", data);
}

export function resetPassword(email) {
    return apiHelper
        .connect(false)
        .get(`/auth/request-reset-password?email=${encodeURIComponent(email)}`);
}

export function changePassword(params) {
    return apiHelper.connect(false).post(`/auth/reset-password`, params);
}

export function toggleWholePageLoader(state = true) {
    return store.dispatch({
        type: types.TOGGLE_WHOLE_PAGE_LOADER,
        payload: {
            show: state
        }
    });
}

export function setModalContent(product, qty, content = false) {
    // if (!content) {
    //     content = getModalContent(product, qty);
    // }

    return store.dispatch({
        type: types.SET_MODAL_CONTENT,
        payload: {
            content: content
        }
    });
}

function getModalContent(product, qty) {
    if (product.giftcard) {
        return (
            <div className="pad-50">
                <div className="txt-dark-link txt-bold txt-header">
                    Yay! you added a gift card
                </div>
                <div className="txt-dark-link txt-bold txt-large">
                    You've just added a {product.denomination} denomination
                    worth of gift card for{" "}
                    <span className="txt-orange">{product.send_to}</span>
                </div>
                <div className="flex align-center flex-end margin-top-20 button-container">
                    <button
                        className="btn btn-clear-bordered-dark-blue margin-right"
                        onClick={() => toggleModal(false)}
                    >
                        Continue Shopping
                    </button>
                    <button
                        className="btn btn-green"
                        onClick={() => {
                            history.push("/checkout/my-cart");
                            toggleModal(false);
                        }}
                    >
                        Proceed to Checkout
                    </button>
                </div>
            </div>
        );
    } else if (product.bundle) {
        return (
            <div className="added-product-container">
                <div
                    className="image-container"
                    style={{
                        backgroundImage: `url(${extractAssetObj(product, [
                            "asset",
                            "path"
                        ])})`
                    }}
                />
                <div className="line" />
                <div className="details">
                    <div className="txt-dark-link txt-bold txt-header">
                        Yay! you added a new item
                    </div>
                    <div className="txt-dark-link txt-bold txt-large">
                        You've just added{" "}
                        <span className="txt-orange">
                            <>
                                {qty} {product.name}
                            </>
                        </span>{" "}
                        in your basket.
                    </div>
                    <div className="flex align-center flex-end margin-top-20 button-container">
                        <button
                            className="btn btn-clear-bordered-dark-blue margin-right"
                            onClick={() => toggleModal(false)}
                        >
                            Continue Shopping
                        </button>
                        <button
                            className="btn btn-green"
                            onClick={() => {
                                history.push("/checkout/my-cart");
                                toggleModal(false);
                            }}
                        >
                            Proceed to Checkout
                        </button>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="added-product-container">
                {/* <div className="image-container" 
                    style={{
                        backgroundImage: `url(${extractAssetObj(product, ['image_paths', 'path'])})`
                    }}
                /> */}
                <div className="line" />
                <div className="details">
                    <div className="txt-dark-link txt-bold txt-header">
                        Yay! you added a new item
                    </div>
                    <div className="txt-dark-link txt-bold txt-large">
                        You've just added{" "}
                        <span className="txt-orange">
                            <>
                                {qty}
                                {product.unit
                                    ? `${product.unit.short_name}`
                                    : ""}{" "}
                                {product.title}
                            </>
                        </span>{" "}
                        in your basket.
                    </div>
                    {/* <div className="flex align-center flex-end margin-top-20 button-container">
                        <button
                            className="btn btn-clear-bordered-dark-blue margin-right"
                            onClick={() => toggleModal(false)}
                        >
                            Continue Shopping
                        </button>
                        <button
                            className="btn btn-green"
                            onClick={() => {history.push('/checkout/my-cart'); toggleModal(false);}}
                        >
                            Proceed to Checkout
                        </button>
                    </div> */}
                </div>
            </div>
        );
    }
}

export function toggleModal(
    isOpen,
    animateIn = "zoomIn",
    animateOut = "zoomOut"
) {
    return store.dispatch({
        type: types.TOGGLE_MODAL,
        payload: {
            isOpen,
            animateIn,
            animateOut
        }
    });
}

export function toggleLoginModal() {
    const login = <Login />;

    setModalContent(false, false, login);
    toggleModal();
}

export function setSelectedCategory(category) {
    if (typeof category === "object" || !category) {
        return store.dispatch({
            type: types.SET_SELECTED_CATEGORY,
            payload: category
        });
    } else {
        return store.dispatch({
            type: types.GET_SELECTED_CATEGORY,
            payload: apiHelper.connect(false).get(`/categories/find`, {
                params: {
                    slug: category
                }
            })
        });
    }
}

export function setSelectedCategoryAndParents(category) {
    return store.dispatch({
        type: types.SET_NEW_PARENTS_CATEGORY,
        payload: apiHelper.connect(false).get(`/categories/find`, {
            params: {
                slug: category
            }
        })
    });
}

export function getParentsCategory() {
    return store.dispatch({
        type: types.GET_PARENTS_CATEGORY,
        payload: apiHelper.connect(false).get(`/categories/parents`)
    });
}

export function getCategoryTree() {
    return store.dispatch({
        type: types.GET_PARENTS_CATEGORY,
        payload: apiHelper.connect(false).get(`/categories/tree`)
    });
}

export function getCategoryBrands(categoryId) {
    return apiHelper.connect(false).get(`/categories/brands?id=${categoryId}`);
}

export function loadProducts(params = {}, source) {
    let baseParams = {
        parent: 0
    };

    if (!params.page) {
        baseParams = Object.assign(baseParams, {
            page: 1
        });
    }

    if (!params.page_size) {
        baseParams = Object.assign(baseParams, {
            page_size: 40
        });
    }

    if (source) {
        return store.dispatch({
            type: types.LOAD_PRODUCTS,
            payload: apiHelper.connect(false).get(`/v2/products/caboodle_get`, {
                params: Object.assign({}, params, baseParams),
                cancelToken: source.token
            })
        });
    } else {
        return store.dispatch({
            type: types.LOAD_PRODUCTS,
            payload: apiHelper.connect(false).get(`/v2/products/caboodle_get`, {
                params: Object.assign({}, params, baseParams)
            })
        });
    }
}

export function toggleRegistrationModal() {
    const registration = <Registration />;

    setModalContent(false, false, registration);
    toggleModal();
}

export function toggleForgotPasswordModal() {
    const forgotPassword = <ForgotPassword />;

    setModalContent(false, false, forgotPassword);
    toggleModal();
}

export function extractAssetObj(
    obj,
    custom_path = false,
    type = "path",
    customPlaceholder = false
) {
    if (custom_path && custom_path.length > 0) {
        for (let i = 0; i < custom_path.length; i++) {
            let prop = custom_path[i];
            if (!obj || !obj.hasOwnProperty(prop)) {
                return customPlaceholder ? customPlaceholder : ImageNotFound;
            } else {
                obj = obj[prop];
            }
        }
        if (!obj) {
            return customPlaceholder ? customPlaceholder : ImageNotFound;
        }
        return `${ASSET_URL}/${obj}`;
    }

    if (obj && obj.asset && obj.asset[type]) {
        return `${ASSET_URL}/${obj.asset[type]}`;
    } else if (obj && obj[type]) {
        return `${ASSET_URL}/${obj[type]}`;
    }

    return customPlaceholder ? customPlaceholder : ImageNotFound;
}

export function validateFormObj(formObj) {
    let obj = Object.assign({}, formObj),
        isValid = true;

    Object.keys(obj).map(field => {
        if (obj[field] === "") {
            isValid = false;
        }
    });

    return isValid;
}

export function toggleCollapse(e, target, callBack) {
    const elTarget = document.querySelectorAll(target);
    let isOpen = true;

    if (elTarget.length > 0) {
        for (let i = 0; i < elTarget.length; i++) {
            if (elTarget[i].clientHeight == 0) {
                elTarget[i].style.maxHeight =
                    elTarget[i].firstChild.clientHeight + "px";

                if (e) {
                    e.target.style.transform = `rotate(180deg)`;
                }
            } else {
                elTarget[i].style.maxHeight = 0;
                if (e) {
                    e.target.style.transform = `rotate(0deg)`;
                }

                isOpen = false;
            }
        }
    }

    if (callBack) {
        callBack(isOpen);
    }
}

export function makeASquare(elements, basis, ratio) {
    if (!ratio) {
        ratio = 1;
    }

    if (!basis) {
        basis = "width";
    }

    for (let el of elements) {
        if (basis === "width") {
            el.height = el.clientWidth * ratio;
            el.style.height = el.clientWidth * ratio + "px";
        } else {
            el.width = el.clientHeight * ratio;
            el.style.width = el.clientHeight * ratio + "px";
        }
    }
}

export function numberWithCommas(n) {
    var parts = n.toString().split(".");
    return (
        parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        (parts[1] ? "." + parts[1] : "")
    );
}

export function slugify(string, direction) {
    let slugified = "";
    direction = direction === undefined ? "forward" : direction;

    if (direction === "forward") {
        slugified = string.replace(new RegExp(" ", "g"), "-");
    } else {
        slugified = string.replace(new RegExp("-", "g"), " ");
    }

    return slugified.toLowerCase();
}

export function subscribeEmail(data) {
    return apiHelper.connect(false).post("/subscriber/send", data);
}

export function computeDiscountPercentage(discount, product) {
    let percentage = 0;
    // console.log(product);
    percentage = parseInt(
        (parseFloat(discount.pivot.discount_value) /
            parseFloat(product.converted_price)) *
            100
    );

    return percentage;
}

export function flagReview(data) {
    return apiHelper.connect(false).post("/product_reviews/flag", data);
}

// PAGINATION LINKS
export function paginationLinks(data, onPaginate) {
    if (
        !data ||
        !data.pagination ||
        data.pagination.last_page === 1 ||
        data.loading ||
        data.list.length === 0
    ) {
        return null;
    }

    const { current_page, last_page } = data.pagination;
    const to = last_page;
    const from = 1;
    const pages = [];

    pages.push(
        <button
            className={`${
                current_page === 1 ? "disabled" : ""
            } txt-medium pagination-btn page-arrow`}
            onClick={() => {
                if (current_page === 1) {
                    return;
                }
                return onPaginate(current_page - 1);
            }}
        >
            <i className="far fa-angle-left" />
        </button>
    );

    if (to <= 5) {
        for (let i = from; i <= to; i++) {
            pages.push(
                <button
                    className={`${
                        i === current_page ? "active" : ""
                    } pagination-btn`}
                    onClick={() => onPaginate(i)}
                >
                    {i}
                </button>
            );
        }
    } else {
        if (current_page >= (window.innerWidth > 767 ? 5 : 3)) {
            if (current_page >= to - 3) {
                pages.push(
                    <button
                        className={`pagination-btn txt-medium`}
                        onClick={() => onPaginate(1)}
                    >
                        {1}
                    </button>
                );
                pages.push(
                    <button
                        className={`pagination-btn txt-medium margin-0 disabled`}
                    >
                        .....
                    </button>
                );

                for (
                    let i = to - (window.innerWidth > 767 ? 5 : 2);
                    i <= to;
                    i++
                ) {
                    pages.push(
                        <button
                            className={`${
                                i === current_page ? "active" : ""
                            } pagination-btn txt-medium`}
                            onClick={() => onPaginate(i)}
                        >
                            {i}
                        </button>
                    );
                }
            } else {
                for (
                    let i = current_page - (window.innerWidth > 767 ? 2 : 1);
                    i <= current_page + (window.innerWidth > 767 ? 3 : 1);
                    i++
                ) {
                    pages.push(
                        <button
                            className={`${
                                i === current_page ? "active" : ""
                            } pagination-btn txt-medium`}
                            onClick={() => onPaginate(i)}
                        >
                            {i}
                        </button>
                    );
                }

                pages.push(
                    <button
                        className={`pagination-btn txt-medium margin-0 disabled`}
                    >
                        .....
                    </button>
                );
                pages.push(
                    <button
                        className={`pagination-btn txt-medium`}
                        onClick={() => onPaginate(to)}
                    >
                        {to}
                    </button>
                );
            }
        } else {
            for (let i = from; i <= (window.innerWidth > 767 ? 5 : 3); i++) {
                pages.push(
                    <button
                        className={`${
                            i === current_page ? "active" : ""
                        } pagination-btn txt-medium`}
                        onClick={() => onPaginate(i)}
                    >
                        {i}
                    </button>
                );
            }

            pages.push(
                <button
                    className={`pagination-btn txt-medium margin-0 disabled`}
                >
                    .....
                </button>
            );
            pages.push(
                <button
                    className={`pagination-btn txt-medium`}
                    onClick={() => onPaginate(to)}
                >
                    {to}
                </button>
            );
        }
    }

    pages.push(
        <button
            className={`${
                last_page === current_page ? "disabled" : ""
            } pagination-btn txt-medium page-arrow`}
            onClick={() => {
                if (last_page === current_page) {
                    return;
                }
                return onPaginate(current_page + 1);
            }}
        >
            <i className="far fa-angle-right" />
        </button>
    );

    return pages;
}

export function addToStockAlert(params) {
    return apiHelper.connect(false).post(`/product-stock-alert`, params);
}

export function viewImage(src) {
    const content = (
        <div
            className="relative-container text-center"
            style={{ width: "100%", background: "#111" }}
        >
            <img
                src={src}
                style={{
                    objectFit: "contain",
                    objectPosition: "center",
                    background: "#111",
                    width: "100%"
                }}
            />
            <button
                className="btn btn-icon btn-icon-sm btn-red"
                style={{ position: "absolute", right: "10px", top: "10px" }}
                onClick={() => toggleModal()}
            >
                <i className="far fa-times" />
            </button>
        </div>
    );
    setModalContent(false, false, content);
    toggleModal();
}

export function moreChar(text, limit = 100, textOverflow = "...") {
    if (text) {
        if (text.length > limit) {
            text =
                text.substr(
                    0,
                    limit + textOverflow.length < text.length
                        ? limit
                        : text.length - textOverflow.length
                ) + textOverflow;
        }
    }

    return text;
}

export function scrollToTop() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
}

export function setSessionCurrency(countryId) {
    return apiHelper
        .connect(false)
        .post(`/set-currency`, { country_id: countryId });
}

export function getDeliveryType() {
    return apiHelper.connect(false).get(`/checkout/delivery_types_details`);
}

export function getDeliveryTimes() {
    return apiHelper.connect(false).get("/checkout/get_delivery_times");
}

export function getBlockOffDates() {
    return store.dispatch({
        type: types.GET_BLOCK_OFF_DATES,
        payload: apiHelper.connect(false).get("/checkout/get_block_off_dates")
    });
}

// for checkout data encryption
export function encryptCheckout(data) {
    let encAlgo = [],
        encrypted = "",
        currentCypher = 0;
    data = JSON.stringify(data);
    // console.log(data);
    [...Array(5)].map(count => {
        encAlgo.push(Math.round(Math.random() * 5) + 1);
    });

    [...Array(data.length)].map((count, index) => {
        encrypted += String.fromCharCode(
            data.charCodeAt(index) + encAlgo[currentCypher]
        );

        if (currentCypher >= encAlgo.length - 1) {
            currentCypher = 0;
        } else {
            currentCypher += 1;
        }
    });

    return btoa(encrypted + encAlgo.join(""));
}

export function acceptDataPolicy() {
    return store.dispatch({
        type: types.GET_SITE_OPTION + types.FULFILLED,
        payload: { data: { tnc: true } }
    });
}

//---- USE THIS FOR TESTING SCRIPTS
export function returnTest(params) {
    console.log(params);
}

// GET PAGE CATEGORY ---- contentItems = TRUE <- get inner content categories
export function getPageContentCategories(slug, contentItems = false) {
    return store.dispatch({
        type: types.GET_PAGES_CATEGORY,
        payload: apiHelper.connect(false).get(`/page/content/categories`, {
            params: {
                slug: slug,
                contentItems
            }
        })
    });
}

export function getPageItems(slug = null, params = {}) {
    const baseParams = Object.assign({}, params, {
        slug: slug
    });
    return store.dispatch({
        type: types.GET_PAGE_ITEMS,
        payload: apiHelper.connect(false).get(`/page/items/get`, {
            params: baseParams
        })
    });
}

export function getPageItemsSeeMore(slug = null, params = {}) {
    const baseParams = Object.assign(
        {},
        {
            slug: slug,
            page: 1,
            page_size: 9,
            view: params.view
        },
        params
    );

    return store.dispatch({
        payload: apiHelper.connect(false).get(`/page/items/get`, {
            params: baseParams
        })
    });
}

export function getPageItem(params) {
    return apiHelper.connect(false).get(`/page/item/get`, {
        params
    });
}

export function findInCategory(haystack, needle) {
    let found = false;
    if (haystack.id === needle.id) {
        found = haystack;
    } else {
        if (haystack.children && haystack.children.length > 0) {
            haystack.children.map(child => {
                found = findInCategory(child, needle) || found;
            });
        }
    }

    return found;
}

export function computeBundleSavePrice(bundle) {
    let savePrice = 0;

    bundle.products.map(product => {
        savePrice +=
            parseFloat(product.converted_price) *
            parseInt(product.pivot.quantity);
    });

    savePrice = savePrice - parseFloat(bundle.converted_price);
    // console.log(bundle);
    return savePrice > 0 ? savePrice : 0;
}

export function toFormalCase(text) {
    return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
}

// DRAGON PAY RELATED
export function getDPChannels(amount) {
    return apiHelper
        .connect(false)
        .get(`orders/get-dp-channels?amount=${amount}`);
}

export function isInternalLink(link) {
    let isInternal = false;

    if (link.indexOf(SITE_URL) > -1) {
        isInternal = true;
    }

    return isInternal;
}

export function getInternalLinkFromUrl(link) {
    console.log(link.replace(SITE_URL, ""));
    return link.replace(SITE_URL, "");
}

//Check if mobile
window.mobilecheck = function() {
    let check = false;
    // eslint-disable-next-line
    (function(a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};
