import React from "react";
import TextInput from "../Form/TextInput";
import SuccessMessage from "../Modal/SuccessMessage";
import {
    validateFormObj,
    resetPassword,
    toggleLoginModal,
    toggleModal,
    setModalContent
} from "../../../helpers/caboodleHelper";

class ForgotPassword extends React.PureComponent {
    state = {
        resetForm: {
            email: ""
        },
        isLaoding: false,
        errorMessage: ""
    };

    componentDidMount() {}

    componentWillMount() {}

    onInputChange = e => {
        let resetForm = Object.assign({}, this.state.resetForm);

        if (e.target.name !== "remember") {
            resetForm[e.target.name] = e.target.value;
        } else {
            resetForm.remember = e.target.checked;
        }

        this.setState({
            resetForm
        });
    };

    openLoginModal() {
        toggleModal();
        setTimeout(() => {
            toggleLoginModal();
        }, 300);
    }

    doForgotPassword = () => {
        // eslint-disable-next-line
        const resetForm = Object.assign({}, this.state.resetForm);
        // remember = resetForm.remember;

        // delete resetForm.remember;
        this.setState(
            {
                isLoading: true,
                errorMessage: ""
            },
            () => {
                resetPassword(resetForm.email)
                    .then(res => {
                        const successMessage = (
                            <SuccessMessage
                                title="Successful!"
                                message="A reset password link has been sent to your email."
                            />
                        );

                        toggleModal();
                        this.setState(
                            {
                                resetForm: {
                                    email: ""
                                },
                                isLaoding: false,
                                errorMessage: ""
                            },
                            () => {
                                setTimeout(() => {
                                    setModalContent(
                                        false,
                                        false,
                                        successMessage
                                    );
                                    toggleModal();
                                }, 300);
                            }
                        );
                    })
                    .catch(err => {
                        console.log(err);
                        this.setState({
                            isLoading: false,
                            errorMessage: err.data.message
                        });
                    });
            }
        );
    };

    render() {
        return (
            <div className="login">
                <div className="icon-badge">
                    <i className="far fa-lock" />
                </div>
                <div className="login-header txt-header-2 text-center">
                    RESET PASSWORD
                </div>
                <div className="login-form">
                    <TextInput
                        placeholder="Email Address"
                        name="email"
                        value={this.state.resetForm.email}
                        onChange={this.onInputChange}
                        onKeyEnter={
                            validateFormObj(this.state.resetForm)
                                ? this.doForgotPassword
                                : false
                        }
                    />
                    {this.state.errorMessage && (
                        <div className="text-center txt-error txt-medium">
                            {this.state.errorMessage}
                        </div>
                    )}
                    <div className="text-center">
                        <button
                            className="btn btn-black btn-sqr btn-block btn-block-center submit-btn"
                            disabled={
                                !validateFormObj(this.state.resetForm) ||
                                this.state.isLoading
                            }
                            onClick={() => this.doForgotPassword()}
                        >
                            Send an Email
                        </button>
                    </div>
                    <div className="registration-link text-center txt-small">
                        <a
                            className="giga-link clickable"
                            onClick={() => this.openLoginModal()}
                            href="#"
                        >
                            Login
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;
