import {
    createCustomerAddress,
    updateCustomerAddress
} from "../actions/address_form.actions";
import { updateCustomerDetails } from "../actions/customer.actions";

export default class UserFactory {
    constructor({ user = {}, address_form = {} }) {
        this.user = user;
        this.address_form = address_form;
    }

    updateAddress(form) {
        return new Promise((resolve, reject) => {
            try {
                let user = Object.assign({}, this.user);
                let address_form = JSON.parse(
                    JSON.stringify(this.address_form)
                );
                let address = {};

                if (form.type == "shipping") {
                    address = address_form.shipping;

                    if (
                        (user &&
                            user.customer &&
                            user.customer.shipping_address_id !== 0) ||
                        (address_form &&
                            address_form.shipping &&
                            address_form.shipping.id > 0)
                    ) {
                        updateCustomerAddress(form).then(res => {
                            if (user && user.customer) {
                                user.customer.primary_address = res.value.data;
                                user.customer.shipping_address_id =
                                    res.value.data.id;
                                updateCustomerDetails(user);
                            }
                            return resolve({ status: true });
                        });
                    } else {
                        createCustomerAddress(form).then(res => {
                            if (user && user.customer) {
                                user.customer.primary_address = res.value.data;
                                user.customer.shipping_address_id =
                                    res.value.data.id;
                                updateCustomerDetails(user);
                            }

                            return resolve({ status: true });
                        });
                    }
                } else {
                    address = address_form.billing;

                    if (
                        (user &&
                            user.customer &&
                            user.customer.billing_address_id !== 0) ||
                        (address_form &&
                            address_form.billing &&
                            address_form.billing.id > 0)
                    ) {
                        updateCustomerAddress(form).then(res => {
                            if (user && user.customer) {
                                user.customer.billing_address = res.value.data;
                                user.customer.billing_address_id =
                                    res.value.data.id;
                                updateCustomerDetails(user);
                            }
                            return resolve({ status: true });
                        });
                    } else
                        createCustomerAddress(form).then(res => {
                            if (user && user.customer) {
                                user.customer.billing_address = res.value.data;
                                user.customer.billing_address_id =
                                    res.value.data.id;
                                updateCustomerDetails(user);
                            }

                            return resolve({ status: true });
                        });
                }
            } catch (err) {
                return reject(err);
            }
        });
    }
}
