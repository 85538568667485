import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
import {
    getSiteOption,
    // subscribeEmail,
    acceptDataPolicy,
    getPage,
    toggleLoginModal
} from "../../../helpers/caboodleHelper";

import { SITE_URL } from "../../../constants/config";
import logo from "../../../assets/img/apicuria_logo.png";

import human_nature from "../../../assets/img/human-nature.png";
import lazada from "../../../assets/img/lazada.png";
import shopee from "../../../assets/img/shoppee.png";
import landscapeGreen3 from "../../../assets/img/landscape_green3.png";
import axios from "axios";

const Footer = ({ page, site_options }) => {
    const cancelToken = useRef(null);
    const [extendDataPolicy, setExtendDataPolicy] = useState(false);

    useEffect(() => {
        cancelToken.current = axios.CancelToken.source();

        const slug = [
            "contact-number",
            "contact-mobile",
            "contact-email",
            "contact-address",
            "facebook-page",
            "instagram-page",
            "twitter-page",
            "lazada",
            "human-nature",
            "shopee"
        ];

        getSiteOption({ slug }, cancelToken.current);
        getPage("footer", cancelToken.current);
        getPage("terms", cancelToken.current);
        getPage("datapolicy", cancelToken.current);

        return () => {
            if (cancelToken.current) cancelToken.current.cancel();
            cancelToken.current = null;
        };
    }, []);

    const backgroundImage =
        page.footer &&
        page.footer.background &&
        page.footer.background.image &&
        page.footer.background.image.path;

    return (
        <div className="footer page-body-container pad-bottom-10">
            <img className="landscape z-index-1" src={landscapeGreen3} />
            {backgroundImage && (
                <img
                    className="footer--bg-overlay"
                    src={`${SITE_URL + "/" + backgroundImage}`}
                />
            )}
            <div className="page-container position-relative z-index-2">
                <div className="flex flex-rm-md pad-top-20 pad-left-20 pad-right-20">
                    <div className="footer-store-details flex direction-column">
                        <div className="grid-150-1fr txt-white upper">
                            <div className="margin-top-30 margin-bottom-10 flex align-center soc-med">
                                {site_options.facebook_page && (
                                    <div className="margin-right-10 margin-left-10">
                                        <a
                                            target="_blank"
                                            href={site_options.facebook_page}
                                        >
                                            <i className="fab fa-facebook txt-white txt-xxlarge" />
                                        </a>
                                    </div>
                                )}
                                {site_options.twitter_page && (
                                    <div className="margin-right-10 margin-left-10">
                                        <a
                                            target="_blank"
                                            href={site_options.twitter_page}
                                        >
                                            <i className="fab fa-twitter txt-white txt-xxlarge" />
                                        </a>
                                    </div>
                                )}
                                {site_options.instagram_page && (
                                    <div className="margin-right-10 margin-left-10">
                                        <a
                                            target="_blank"
                                            href={site_options.instagram_page}
                                        >
                                            <i className="fab fa-instagram txt-white txt-xxlarge" />
                                        </a>
                                    </div>
                                )}
                                {site_options.google_page && (
                                    <div className="margin-right-20">
                                        <a
                                            target="_blank"
                                            href={site_options.google_page}
                                        >
                                            <i className="fab fa-google txt-white txt-xxlarge" />
                                        </a>
                                    </div>
                                )}
                            </div>
                            <div className="margin-top-30">
                                <div className="txt-medium txt-cerapro-light txt-white margin-right-10 margin-left-10 margin-bottom-10">
                                    Also available at
                                </div>
                                <div className="flex align-center shops">
                                    {site_options.human_nature && (
                                        <a
                                            target="_blank"
                                            href={site_options.human_nature}
                                            className="margin-right-10 margin-left-10 btn btn-white"
                                        >
                                            <img
                                                src={human_nature}
                                                alt="Human Nature"
                                                style={{
                                                    transform:
                                                        "translateY(-2px)"
                                                }}
                                            />
                                        </a>
                                    )}
                                    {site_options.lazada && (
                                        <a
                                            target="_blank"
                                            href={site_options.lazada}
                                            className="margin-right-10 margin-left-10 btn btn-white"
                                        >
                                            <img src={lazada} alt="Lazada" />
                                        </a>
                                    )}
                                    {site_options.shopee && (
                                        <a
                                            target="_blank"
                                            href={site_options.shopee}
                                            className="margin-right-10 margin-left-10 btn btn-white"
                                        >
                                            <img
                                                src={shopee}
                                                alt="Shopee"
                                                style={{
                                                    transform:
                                                        "translateY(-3px)"
                                                }}
                                            />
                                        </a>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="grid-150-1fr txt-gray-lighter">
                            <div className="copy-right margin-right-50">
                                <NavLink to="/">
                                    <img src={logo} alt="Apicuria" />
                                </NavLink>
                                <div className="margin-top-15 txt-small">
                                    Copyright © {moment().format("YYYY")} The
                                    BeEMPIRE. All Rights Reserved.
                                </div>
                                <a
                                    href="https://sumofy.me/"
                                    target="_blank"
                                    className="margin-top-10 txt-small"
                                >
                                    Design by Think Sumo
                                </a>
                            </div>
                            <div className="contacts">
                                {site_options.contact_address && (
                                    <div className="flex footer--quicklink justify-unset">
                                        <i
                                            className="fas fa-map-marker txt-medium txt-light-green"
                                            style={{ lineHeight: 1.5 }}
                                        />
                                        <div className="txt-white txt-small margin-left-10">
                                            {site_options.contact_address}
                                        </div>
                                    </div>
                                )}
                                {site_options.contact_number && (
                                    <div className="margin-top-15 flex footer--quicklink justify-unset">
                                        <i
                                            className="fas fa-phone txt-medium txt-light-green"
                                            style={{ lineHeight: 1.5 }}
                                        />
                                        <div
                                            className="txt-white txt-small margin-left-10"
                                            href={`tel:${site_options.contact_number}`}
                                        >
                                            {site_options.contact_number}
                                        </div>
                                    </div>
                                )}
                                {site_options.contact_mobile && (
                                    <div className="margin-top-15 flex footer--quicklink justify-unset">
                                        <i
                                            className="fas fa-mobile txt-medium txt-light-green"
                                            style={{ lineHeight: 1.5 }}
                                        />
                                        <a
                                            className="txt-white txt-small margin-left-10"
                                            href={`tel:${site_options.contact_mobile}`}
                                        >
                                            {site_options.contact_mobile}
                                        </a>
                                    </div>
                                )}
                                {site_options.contact_email && (
                                    <div className="margin-top-15 flex footer--quicklink justify-unset">
                                        <i
                                            className="fas fa-envelope txt-medium txt-light-green"
                                            style={{ lineHeight: 1.5 }}
                                        />
                                        <a
                                            className="txt-white txt-small margin-left-10"
                                            href={`mailto:${site_options.contact_email}`}
                                        >
                                            {site_options.contact_email}
                                        </a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {page.datapolicy &&
            page.datapolicy.content &&
            page.datapolicy.content.content &&
            !site_options.tnc ? (
                <div
                    id="dataPolicy"
                    className={extendDataPolicy ? "" : "short"}
                >
                    <div className="pad-20">
                        <div className="pad-10">
                            <div className="txt-white txt-bold txt-large policy-title">
                                Data Privacy Policy
                            </div>
                            <div
                                className="txt-medium txt-white policy-content"
                                dangerouslySetInnerHTML={{
                                    __html: page.datapolicy.content.content
                                }}
                            />
                        </div>
                        <div className="pad-10 text-center button-container">
                            <button
                                className="btn btn-clear see-more"
                                onClick={() => setExtendDataPolicy(true)}
                            >
                                See more
                            </button>
                            <button
                                className="btn btn-primary"
                                onClick={() => acceptDataPolicy()}
                            >
                                Accept
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        site_options: state.site_options,
        categories: state.categories,
        page: state.page,
        user: state.user,
        cart: state.cart
    };
}

export default connect(mapStateToProps, null)(Footer);
