import React from "react";
import PropTypes from "prop-types";

const Skeleton = ({
    className,
    width = "auto",
    height = "auto",
    center = false,
    clear = false
}) => {
    return (
        <div
            className={`skeleton ${className ? className : ""} ${
                center ? "center" : ""
            } ${clear ? "clear" : ""}`}
            style={{ maxWidth: width, height: height }}
        />
    );
};

Skeleton.propTypes = {
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    center: PropTypes.bool,
    clear: PropTypes.bool
};

export default Skeleton;
