import React from "react";
import Skeleton from "../_global/Elements/Skeleton";
import TitleLeaf from "../_global/Elements/TitleLeaf";

const AboutSkeleton = props => {
    return (
        <>
            <div className="banners">
                <Skeleton height="500px" />
            </div>

            <div className="about-us margin-top-50">
                <div className="container">
                    <TitleLeaf
                        className="txt-header txt-cerapro-bold"
                        loading={true}
                    />

                    <h5>
                        <Skeleton
                            className="margin-bottom-10"
                            width="60%"
                            center={true}
                        />
                        <Skeleton
                            className="margin-bottom-10"
                            width="40%"
                            center={true}
                        />
                    </h5>
                    <div className="margin-top-30">
                        <Skeleton
                            className="margin-bottom-10"
                            width="50%"
                            center={true}
                        />
                        <Skeleton
                            className="margin-bottom-10"
                            width="60%"
                            center={true}
                        />
                        <Skeleton
                            className="margin-bottom-10"
                            width="40%"
                            center={true}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutSkeleton;
