import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.categories, action) {
    switch(action.type) {
        case types.GET_PARENTS_CATEGORY + types.PENDING:
            return Object.assign({},
                state,
                {
                    loading: true
                }
            );
        case types.GET_PARENTS_CATEGORY + types.REJECTED:
            return Object.assign({},
                state, 
                {
                    loading: false
                }
            );
        case types.GET_PARENTS_CATEGORY + types.FULFILLED:
            return Object.assign({},
                state,
                {
                    list: action.payload.data && action.payload.data.slice()
                },
                {
                    loading: false
                }
            );
        case types.GET_SELECTED_CATEGORY + types.REJECTED:
                return Object.assign({},
                    state,
                    {
                        selected: null
                    },
                    {
                        breadcrumbs: []
                    },
                    {
                        loading: false
                    }
                );
        case types.GET_SELECTED_CATEGORY + types.FULFILLED:
            return Object.assign({},
                state,
                {
                    selected: action.payload.data
                },
                {
                    breadcrumbs: action.payload.breadcrumbs && action.payload.breadcrumbs.slice() ? action.payload.breadcrumbs.slice() : []
                },
                {
                    loading: false
                }
            );
        case types.SET_NEW_PARENTS_CATEGORY + types.FULFILLED: {
            const newState = Object.assign({}, { selected: action.payload.data });
            return Object.assign({},
                state,
                newState,
                {
                    breadcrumbs: action.payload.breadcrumbs && action.payload.breadcrumbs.slice() ? action.payload.breadcrumbs.slice() : []
                    
                },
                {
                    loading: false
                }
            );
        }
        case types.SET_SELECTED_CATEGORY:
                return Object.assign({},
                    state,
                    {
                        selected: action.payload
                    }
                );
        default: 
            return state;
    }
}