import PropTypes from "prop-types";
import React, { useState } from "react";
import Slider from "react-slick";
import { extractAssetObj } from "../../../helpers/caboodleHelper";
import TitleLeaf from "../Elements/TitleLeaf";
import TestimonialSectionSkeleton from "./TestimonialSectionSkeleton";

const TestimonialSection = ({ data }) => {
    if (data == undefined) {
        return <TestimonialSectionSkeleton />;
    }

    const [slides, setSlides] = useState(data.contents);
    const [showSlide, setShowSlide] = useState(0);

    if (slides == undefined || slides.length <= 0) {
        return <></>;
    }

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => {
        return (
            <button
                {...props}
                className="slick-prev slick-arrow"
                aria-hidden="true"
                type="button"
            >
                <i className="far fa-arrow-alt-circle-left" />
            </button>
        );
    };

    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => {
        return (
            <button
                {...props}
                className="slick-next slick-arrow"
                aria-hidden="true"
                type="button"
            >
                <i className="far fa-arrow-alt-circle-right" />
            </button>
        );
    };

    const slickSettings = {
        dots: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        centerMode: true,
        prevArrow: <SlickArrowLeft />,
        nextArrow: <SlickArrowRight />,
        beforeChange: (current, next) => {
            setShowSlide(next);
        },
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false
                }
            }
        ]
    };

    return (
        <div className="testimonial-section container">
            <TitleLeaf
                className="txt-header txt-cerapro-bold"
                title="Testimonials"
            />

            <div className="items-container">
                <Slider {...slickSettings}>
                    {slides.map((slide, index) => {
                        let rating =
                            parseInt(slide.rating) > 5
                                ? 5
                                : parseInt(slide.rating);

                        let isActive = false;

                        if (slides.length > 2 && index == showSlide) {
                            isActive = true;
                        } else if (slides.length <= 2) {
                            isActive = true;
                        }

                        return (
                            <div
                                key={index}
                                className={`item flex direction-column align-center justify-between ${
                                    isActive ? "active" : ""
                                }`}
                            >
                                <img
                                    src={extractAssetObj(slide, [
                                        "image",
                                        "path"
                                    ])}
                                />
                                <div className="flex direction-column align-center">
                                    <h5 className="txt-18-px txt-cerapro-bold text-center">
                                        {slide.title}
                                    </h5>
                                    <div className="stars txt-light-red">
                                        {[...Array(rating)].map((e, i) => (
                                            <i
                                                key={i}
                                                className="fas fa-star"
                                            />
                                        ))}
                                        {[...Array(5 - rating)].map((e, i) => (
                                            <i
                                                key={i}
                                                className="far fa-star"
                                            />
                                        ))}
                                    </div>
                                    <div
                                        className="txt-small text-center"
                                        dangerouslySetInnerHTML={{
                                            __html: slide.content
                                        }}
                                    />
                                </div>
                                <h5 className="txt-light-red text-center">
                                    - {slide.author}
                                </h5>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

TestimonialSection.propTypes = {
    data: PropTypes.object
};

export default TestimonialSection;
