import React from 'react';
import {connect} from 'react-redux';
import queryString from 'query-string';
// import PropTypes from 'prop-types';
import TextInput from '../Form/TextInput';
import History from '../../../History';

class AutoSuggest extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            keyword: queryString.parse(props.location.search).keyword
        };
    }

    componentDidUpdate(oldProps) {
        if (oldProps.location.search !== this.props.location.search) {
            this.setState({
                keyword: queryString.parse(this.props.location.search).keyword
            });
        }
    }

    render() {
        const { keyword } = this.state;

        return (
            <div style={{flex:1}}>
                <div className="txt-header-2 text-center mobile-label">
                    Search your product
                </div>
                <div className="search-container auto-suggest">
                    <i
                        className="fas fa-search"
                        style={{
                            color: this.props.theme ? this.props.theme : "#009EE2"
                        }}
                    />
                    <TextInput
                        value={keyword}
                        onKeyEnter={() => handleSearch(keyword, this.props.location, this.props.toggleSearch)}
                        placeholder="Search for a product"
                        onChange={(e) => this.setState({ keyword: e.target.value }
                        )}
                    />
                    <button
                        className="btn"
                        style={{
                            backgroundColor: this.props.theme ? this.props.theme : "#009EE2",
                            borderColor: this.props.theme ? this.props.theme : "#009EE2"
                        }}
                        onClick={() => handleSearch(keyword, this.props.location, this.props.toggleSearch)}
                    >
                        <i className="fa fa-chevron-right txt-medium" />
                    </button>
                </div>
                <div className="cliackalbe dismiss-search">
                    <button
                        className="btn btn-icon"
                        onClick={() => this.props.toggleSearch()}
                    >
                        <i className="fas fa-times-circle txt-xxlarge txt-error" />
                    </button>
                </div>
            </div>
        );
    }
}

function handleSearch(keyword, location, callback) {
    if (!keyword) {
        return;
    }
    if (location.pathname === '/products') {
        // let params = queryString.parse(location.search);
        // params = Object.assign(params, {
        let params = Object.assign({}, {
            keyword: keyword
        });
        callback(false);
        return History.push({
            pathname: '/products',
            search: `?${queryString.stringify(params)}`
        });
    }
    return History.push(`/products?keyword=${keyword}`);
}

function mapStateToProps(state, ownProps) {
	return {
	};
}

export default connect(mapStateToProps, null)(AutoSuggest);
