import * as types from "../constants/actionTypes";
import * as apiHelper from "../helpers/apiHelper";
import { store } from "../index";

export function getShippingMethods() {
    const request = apiHelper
        .connect(false)
        .get(`/checkout/delivery_types_details`);

    return store.dispatch({
        type: types.GET_SHIPPING_METHODS,
        payload: request
    });
}

export function setShippingMethod(params) {
    return store.dispatch({
        type: types.SET_SHIPPING_METHOD,
        payload: params
    });
}
