import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import {
    sendInquiry,
    validateFormObj,
    getSiteOption,
    getPage,
    toggleWholePageLoader
} from "../../helpers/caboodleHelper";
import { ContactBottomLeaves } from "./FloatingLeaves";
import EllipsisLoader from "../_global/Loaders/EllipsisLoader";
import pageBG from "../../assets/img/contact.jpg";
import iconShop from "../../assets/img/icon-shop.png";
import iconTime from "../../assets/img/icon-time.png";
import iconWhatsApp from "../../assets/img/icon-whatsapp.png";
import iconPhone from "../../assets/img/icon-phone.png";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ContactUs = ({ page, site_options }) => {
    const cancelToken = useRef(null);
    const slug = ["contact-number", "contact-address"];
    const contact = page.contact ? page.contact : undefined;
    const MySwal = withReactContent(Swal);

    const initialInput = {
        first_name: { value: "", error: "This field is required." },
        last_name: { value: "", error: "This field is required." },
        email: { value: "", error: "This field is required." },
        message: { value: "", error: "This field is required." }
    };

    const [inputs, setInputs] = useState(initialInput);
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        cancelToken.current = axios.CancelToken.source();

        getPage("contact", cancelToken.current);
        getSiteOption({ slug }, cancelToken.current);

        return () => {
            if (cancelToken.current) cancelToken.current.cancel();
            cancelToken.current = null;
        };
    }, []);

    const handleChangeInput = e => {
        let name = e.target.name;
        let value = e.target.value;
        let type = e.target.type;
        let error = "";

        if (value == "") {
            error = "This field is required.";
        }
        if (type == "email") {
            if (
                !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                    value
                )
            ) {
                error = "Email is not valid.";
            }
        }

        setInputs({
            ...inputs,
            [name]: { value, error }
        });
    };

    const hasError = () => {
        let withError = false;

        Object.keys(inputs).map(field => {
            if (inputs[field].error != "") {
                withError = true;
            }
        });

        return withError;
    };

    const handleSubmit = () => {
        if (hasError()) {
            setShowError(true);
            return;
        }
        toggleWholePageLoader(true);
        let form = {};

        Object.keys(inputs).map(
            idx => (form = { ...form, [idx]: inputs[idx].value })
        );

        sendInquiry(form)
            .then(res => {
                MySwal.fire({
                    title: <p>Success</p>,
                    text: "Message Sent!",
                    icon: "success"
                });
            })
            .catch(res => {
                MySwal.fire({
                    title: <p>Error</p>,
                    text: "Something went wrong. Try again later.",
                    icon: "error"
                });
            })
            .finally(() => {
                setInputs(initialInput);
                setShowError(false);
                toggleWholePageLoader(false);
            });
    };

    return (
        <div className="contact-us page-body-container">
            <ContactBottomLeaves />
            {contact == undefined ? (
                <div className="container pad-bottom-100 pad-top-100" />
            ) : (
                <div className="container pad-bottom-100 pad-top-100">
                    <h1 className="txt-header-2 txt-cerapro-bold">
                        {contact.contactForm && contact.contactForm.title
                            ? contact.contactForm.title
                            : "Send Us A Message"}
                    </h1>

                    <div className="grid margin-top-50 margin-bottom-50">
                        <div className="contact-form pad-right-20">
                            <div className="flex align-center">
                                <div className="form-group flex-1">
                                    <input
                                        className="form-control"
                                        name="first_name"
                                        placeholder="First Name"
                                        onChange={e => handleChangeInput(e)}
                                        value={inputs.first_name.value}
                                    />

                                    {showError ? (
                                        <div className="error txt-small txt-error">
                                            {inputs.first_name.error}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="form-group flex-1 margin-left-20">
                                    <input
                                        className="form-control"
                                        name="last_name"
                                        placeholder="Last Name"
                                        onChange={e => handleChangeInput(e)}
                                        value={inputs.last_name.value}
                                    />
                                    {showError ? (
                                        <div className="error txt-small txt-error">
                                            {inputs.last_name.error}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-group flex-1">
                                    <input
                                        className="form-control with-top-margin"
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        onChange={e => handleChangeInput(e)}
                                        value={inputs.email.value}
                                    />
                                    {showError ? (
                                        <div className="error txt-small txt-error">
                                            {inputs.email.error}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="form-group flex-1">
                                    <textarea
                                        className="form-control with-top-margin"
                                        name="message"
                                        rows="10"
                                        placeholder="Message"
                                        onChange={e => handleChangeInput(e)}
                                        value={inputs.message.value}
                                    />
                                    {showError ? (
                                        <div className="error txt-small txt-error">
                                            {inputs.message.error}
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                            <div className="text-right pad-bottom-20 with-top-margin">
                                <button
                                    className="txt-poppins-regular submit-btn"
                                    // className={`btn btn-${
                                    //     this.state.sent ? "primary" : "secondary"
                                    // } btn-sqr btn-block btn-block-center btn-pad-large txt-bold submit-btn`}
                                    onClick={() => handleSubmit()}
                                >
                                    Send Message
                                </button>
                            </div>
                        </div>
                        <div className="contact-info">
                            <h5 className="txt-light-green txt-large txt-cerapro-medium margin-bottom-20">
                                {contact.contactInfo &&
                                contact.contactInfo.title
                                    ? contact.contactInfo.title
                                    : "We Love to Hear from You"}
                            </h5>
                            <div className="contact-information">
                                <div>
                                    <div className="info margin-bottom-20">
                                        <img src={iconPhone} />
                                        <div className="contact-info txt-medium txt-cerapro-regular">
                                            {site_options.contact_number}
                                        </div>
                                    </div>
                                    <div className="info margin-bottom-20">
                                        <img src={iconShop} />
                                        <div className="contact-info txt-medium txt-cerapro-regular">
                                            {site_options.contact_address}
                                        </div>
                                    </div>
                                    <div className="info margin-bottom-20">
                                        <img src={iconTime} />
                                        <div
                                            className="contact-info txt-medium txt-cerapro-regular"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    contact.contactInfo.schedule
                                            }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className="info">
                                        <img src={iconWhatsApp} />
                                        <div>
                                            <div className="contact-info txt-medium txt-cerapro-regular">
                                                {contact.contactInfo &&
                                                contact.contactInfo.header
                                                    ? contact.contactInfo.header
                                                    : "Message us on WhatsApp"}
                                            </div>
                                            <br />
                                            <div className="txt-gray-med-light txt-small txt-cerapro-regular">
                                                {contact.contactInfo &&
                                                contact.contactInfo.description
                                                    ? contact.contactInfo
                                                          .description
                                                    : "Please contact us directly with any questions, or comments you may have."}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            ;
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        page: state.page,
        site_options: state.site_options
    };
}

ContactUs.propTypes = {
    page: PropTypes.object
};

export default connect(mapStateToProps, null)(ContactUs);
