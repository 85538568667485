import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import { logoutUser, toggleWholePageLoader, removeToCart, syncOrderToCart } from "../../../../helpers/caboodleHelper";
import { getUser } from "../../../../helpers/sessionHelper";
import { useSelector } from "react-redux";
import {
    extractAssetObj,
} from '../../../../helpers/caboodleHelper';

const Cart = () => {
    const order = useSelector(state => state.cart);
    const total = useSelector(state => state.order);
    const [cartOpen, setCartOpen] = useState(false);

    let subTotal = 0;

    if (order && order.items && order.items.length > 0) {
        order.items.map(item => {
            subTotal += +(item.product.converted_price * parseFloat(item.quantity)).toFixed(2);
        });
    }
    useEffect(() => {
        if (order.hasOwnProperty("id")) {
            syncOrderToCart(total.items);
        }
    }, [order]);

    return (
        <Dropdown
            className="profile-dropdown cart-dropdown"
            isOpen={cartOpen}
            toggle={e => setCartOpen(!cartOpen)}
            onClick={e => setCartOpen(!cartOpen)}
        >
            <DropdownToggle>
                <div className="nav-circle-container cart">
                    {
                        order && order.items && order.items.length > 0 && (
                            <span className="cart-indicator" ></span>
                        )
                    }
                    <div className="cart-icon-container">
                        <i className="fas fa-shopping-cart"></i>
                    </div>
                </div>
            </DropdownToggle>
            <DropdownMenu>
                <div className="cart-items">
                    <div className="cart-container">
                        { order && order.items && order.items.length > 0 ? (
                            order.items.map((item, idx) => (
                                <div key={idx} className="item mb-10">
                                    <div className="product-details">
                                        <img
                                            src={extractAssetObj(item.product, [
                                                "image_paths",
                                                "small_thumbnail"
                                            ])}
                                            alt={item.product.title}
                                        />
                                        <div>
                                            <div className="delete-item"
                                                onClick={() => removeToCart(item.product)}
                                            >x</div>
                                            <div>
                                                {item.product.title}
                                            </div>
                                            <div className="item-details">
                                                <div className="txt-small">
                                                x {item.quantity}
                                                </div>
                                                <div className="txt-small">
                                                    ₱ {+(item.product.converted_price * parseFloat(item.quantity)).toFixed(2)}
                                                </div>
                                            </div>
                                
                                        </div>
                                    </div>
                                </div>
                            ))
                        ):(   
                            <div className="cart-popup-empty" >Your cart is empty</div>
                        )}
                    </div>
                { order && order.items && order.items.length > 0 ? (
                <div className="cart-popup-footer">
                    <div className="price-container">
                        <div className="label txt-bold">TOTAL</div>
                        <div className="price">₱ {subTotal}</div>
                    </div>
                    <div className="btn-container">
                        <NavLink
                            to="/checkout/information"
                            className="btn btn-primary"
                        ><span>Checkout</span></NavLink>
                    </div>
                </div>):(
                    <div className="cart-popup-empty"></div>
                )
                }
                </div>
                
            </DropdownMenu>
        </Dropdown>
    );
};

export default Cart;
