import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import queryString from "query-string";
import {
    extractAssetObj,
    getInternalLinkFromUrl,
    isInternalLink
} from "../../../helpers/caboodleHelper";
import YouTube from "react-youtube";
import History from "../../../History";
import { NavLink } from "react-router-dom";
import Skeleton from "../Elements/Skeleton";

class Banner extends React.PureComponent {
    state = {
        activeSlide: {},
        lastActiveSlide: {},
        activeSlideDimensions: { width: 0, height: 0 },
        activeSlideIndex: 0,
        lastActiveSlideIndex: 0,
        controlsDisabled: false,
        slideMovement: "",
        autoPlayInterval: false,
        autoPlayDelay: 8000,
        keyword: ""
    };
    sliderTimeout;
    resizeTimeout;

    componentDidMount() {
        // window.addEventListener('resize', this.handleWindowResize);
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/player_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    UNSAFE_componentWillMount() {
        // this.initializeSlider();
    }

    componentDidUpdate() {
        // this.initializeSlider();
    }

    componentWillUnmount() {
        // this.setState({
        //     unmounted: true
        // }, () => {
        //     window.removeEventListener('resize', () => {
        //         clearTimeout(this.resizeTimeout);
        //     });
        // });
    }

    // initializeSlider = () => {
    //     if (Object.keys(this.state.activeSlide).length == 0 && this.props.banners) {
    //         this.setState({
    //             activeSlide: this.props.banners[0],
    //             lastActiveSlide: this.props.banners[0]
    //         }, () => {
    //             if (this.props.banners && this.props.banners.length > 1) {
    //                 this.handleWindowResize();
    //                 this.setState({
    //                     autoPlayInterval: this.state.activeSlide.video_embed ? false : setInterval(() => {
    //                                             this.handleSetActiveSlide((this.state.activeSlideIndex + 1) < this.props.banners.length ? (this.state.activeSlideIndex + 1) : 0, "next")
    //                                         }, this.state.autoPlayDelay)
    //                 });
    //             }
    //         });
    //     }
    // }

    // onYouTubePlayerAPIReady = () => {
    //     const player = new window.YT.Player('player', {
    //       height: '390',
    //       width: '640',
    //       videoId: 'u1zgFlCw8Aw',
    //       events: {
    //         'onReady': () => {console.log(123)},
    //         'onStateChange': () => {console.log(123)}
    //       }
    //     });
    // }

    // handleSetActiveSlide = (index, movement) => {
    //     const activeSlide = Object.assign({}, this.state.activeSlide);
    //     clearInterval(this.state.autoPlayInterval);
    //     this.setState({
    //         activeSlide: this.props.banners[index],
    //         lastActiveSlide: activeSlide,
    //         lastActiveSlideIndex: this.state.activeSlideIndex,
    //         activeSlideIndex: index,
    //         controlsDisabled: true,
    //         slideMovement: movement ? movement : (index > this.state.activeSlideIndex ? "next" : "prev")
    //     }, () => {
    //         this.sliderTimeout = setTimeout(() => {
    //             this.setState({
    //                 controlsDisabled: false,
    //                 slideMovement: "",
    //                 autoPlayInterval: this.state.activeSlide.video_embed ? false : setInterval(() => {
    //                                         this.handleSetActiveSlide((this.state.activeSlideIndex + 1) < this.props.banners.length ? (this.state.activeSlideIndex + 1) : 0, "next")
    //                                     }, this.state.autoPlayDelay)
    //             });
    //         }, 1500);
    //     });
    // }

    // handleWindowResize = () => {
    //     if (this.props.banners && this.props.banners.length > 0) {
    //         if (this.resizeTimeout) {
    //             clearTimeout(this.resizeTimeout);
    //         }

    //         if (!this.state.unmounted) {
    //             this.resizeTimeout = setTimeout(() => {
    //                 const activeImage = document.querySelector(".home");
    //                 this.setState({
    //                     activeSlideDimensions: {
    //                         width: activeImage.clientWidth,
    //                         height: 500
    //                     }
    //                 });
    //             }, 500);
    //         }
    //     }
    // }

    render() {
        const { banners, loading } = this.props;

        const {
            activeSlide,
            lastActiveSlide,
            activeSlideDimensions
        } = this.state;

        if (loading || !banners) {
            return (
                <div className="page-container-full-xs banner-slider banner-js">
                    <div className="main-banner">
                        <Skeleton height="650px" clear={true} />
                    </div>
                </div>
            );
        }

        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            pauseOnHover: false,
            autoplaySpeed: 5000
        };

        return (
            <div className="page-container-full-xs">
                {banners.length > 0 && (
                    <div className="banner-js banner-slider">
                        <div className="main-banner">
                            <Slider {...settings}>
                                {banners.map((b, idx) => (
                                    <div key={idx}>
                                        <div className="container pad-top-150 pad-bottom-150">
                                            <div className="grid grid-autofit-250">
                                                <img
                                                    src={extractAssetObj(b, [
                                                        "image",
                                                        "path"
                                                    ])}
                                                    alt={b.title}
                                                />
                                                <div>
                                                    <h1 className="txt-oversized txt-cerapro-bold txt-oneline-title-500px">
                                                        {b.title}
                                                    </h1>

                                                    <div
                                                        className="txt-large margin-top-20 pad-right-20 txt-cerapro-regular"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                b.description
                                                        }}
                                                    />

                                                    {b.link && (
                                                        <>
                                                            {isInternalLink(
                                                                b.link
                                                            ) ? (
                                                                <NavLink
                                                                    to={getInternalLinkFromUrl(
                                                                        b.link
                                                                    )}
                                                                    className="btn btn-clear margin-top-20"
                                                                >
                                                                    {b.linkName}
                                                                </NavLink>
                                                            ) : (
                                                                <a
                                                                    href={
                                                                        b.link
                                                                    }
                                                                    className="btn btn-clear margin-top-20"
                                                                >
                                                                    {b.linkName}
                                                                </a>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>

                            {/* <div className={`active-banner ${this.state.slideMovement !== '' && 'in-transition'}  fade-in`}>
                                    <div className="active-image">
                                        <div className="flex-1 image-details">
                                            {
                                                activeSlide.video_embed ? (
                                                    <div
                                                        className="embed_video"
                                                    >
                                                        <YouTube
                                                            videoId={activeSlide.video_embed}
                                                            containerClassName="iframe-container"
                                                            opts={{
                                                                height: '390',
                                                                width: '640',
                                                                playerVars: {
                                                                    modestbranding: 1
                                                                }
                                                            }}
                                                            onReady={() => {
                                                                setTimeout(() => {
                                                                    this.handleSetActiveSlide((this.state.activeSlideIndex + 1) < this.props.banners.length ? (this.state.activeSlideIndex + 1) : 0, "next");
                                                                }, this.state.autoPlayDelay);
                                                            }}
                                                            onPlay={() => {clearInterval(this.state.autoPlayInterval);}}
                                                            onPause={() => this.onVideoStop()}
                                                            onEnd={() => this.onVideoStop()}
                                                        />
                                                    </div>
                                                ) : (
                                                    <>
                                                    {console.log(activeSlide)}
                                                        <img
                                                            className="image-transition"
                                                            src={extractAssetObj(lastActiveSlide, ['image', 'path'])}
                                                            alt={lastActiveSlide.title}
                                                        />
                                                        <img
                                                            src={extractAssetObj(activeSlide, ['image', 'path'])}
                                                            alt={activeSlide.title}
                                                        />
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className={`transition-overlay ${this.state.slideMovement}`}>
                                        <div
                                            className="flex align-center"
                                            style={{width: activeSlideDimensions.width, height: activeSlideDimensions.height}}
                                        >
                                            <div className="text-details flex-1 flex justify-center pad-left-50 pad-right-50">
                                                <div>
                                                    <h1 className="txt-oversized">
                                                        {lastActiveSlide.title}
                                                    </h1>
                                                    <h2
                                                        className="txt-medium txt-regular"
                                                        dangerouslySetInnerHTML={{__html: lastActiveSlide.content}}
                                                    />
                                                    <button
                                                        className="btn btn-white btn-sqr txt-bold txt-large btn-pad-xlarge margin-top-30"
                                                    >
                                                        {lastActiveSlide.button_text}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex-1 image-details">
                                                {
                                                    lastActiveSlide.video_embed ? (
                                                        <div
                                                            className="embed_video"
                                                        />
                                                    ) : (
                                                        <img
                                                            src={extractAssetObj(lastActiveSlide, ['image', 'path'])}
                                                            alt={lastActiveSlide.title}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                        </div>
                    </div>
                )}
            </div>
        );
    }

    handleSearch = () => {
        const { keyword } = this.state,
            { location } = this.props;

        if (!keyword) {
            return;
        }

        if (location.pathname === "/products") {
            let params = queryString.parse(location.search);
            params = Object.assign(params, {
                keyword: keyword
            });
            return History.push({
                pathname: "/products",
                search: `?${queryString.stringify(params)}`
            });
        }
        return History.push(`/products?keyword=${keyword}`);
    };

    onVideoStop = () => {
        if (this.sliderTimeout) {
            clearTimeout(this.sliderTimeout);
        }

        this.sliderTimeout = setTimeout(() => {
            this.handleSetActiveSlide(
                this.state.activeSlideIndex + 1 < this.props.banners.length
                    ? this.state.activeSlideIndex + 1
                    : 0,
                "next"
            );
        }, 1500);
    };
}

Banner.propTypes = {
    banners: PropTypes.array,
    loading: PropTypes.bool
};

export default Banner;
