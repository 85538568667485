import React, { Fragment, useEffect, useState } from "react";
import { extractAssetObj } from "../../../helpers/caboodleHelper";
import { JournalsUpperLeaves } from "../../Journals/FloatingLeaves";
import Skeleton from "../../_global/Elements/Skeleton";

const compileAssets = parentProduct => {
    let images = [];
    let product = JSON.parse(JSON.stringify(parentProduct));

    if (product.asset) {
        images.push(product.asset);
    }

    if (
        product.asset_group_api &&
        product.asset_group_api.assets &&
        product.asset_group_api.assets.length > 0
    ) {
        let orderedAsset = product.asset_group_api.assets.sort(
            (a, b) => a.order - b.order
        );

        orderedAsset.map(asset => images.push(asset.asset));
    }

    return images;
};

const ProductImages = ({ product }) => {
    const [assets, setAssets] = useState([]);
    const [activeAsset, setActiveAsset] = useState(false);

    useEffect(() => {
        if (product && product.asset) {
            setAssets([...compileAssets(product)]);
            setActiveAsset(compileAssets(product)[0]);
        }
    }, [product]);

    const handleNavArrow = params => {
        let navAsset = JSON.parse(JSON.stringify(assets));
        let removedItem;

        if (params) {
            removedItem = navAsset[navAsset.length - 1];
            navAsset.pop();
            navAsset.unshift(removedItem);
            setActiveAsset(navAsset[0]);
        } else {
            removedItem = navAsset[0];
            navAsset.shift();
            navAsset.push(removedItem);
            setActiveAsset(navAsset[3]);
        }

        setAssets(navAsset);
    };

    return (
        <div className="image-container">
            <JournalsUpperLeaves />

            {!activeAsset && (
                <div>
                    <div className={`main-asset mb-3 pt-5`}>
                        <Skeleton height="100%" width="100%" />
                    </div>
                </div>
            )}

            {product && activeAsset && (
                <div>
                    <div className={`main-asset mb-3 pt-5`}>
                        <img
                            src={extractAssetObj(activeAsset, false)}
                            alt={activeAsset.name}
                        />
                    </div>

                    <div className={`nav-assets`}>
                        {assets.length > 0 && (
                            <>
                                {assets.length > 3 && (
                                    <button onClick={() => handleNavArrow(1)}>
                                        <i className="fas fa-chevron-left"></i>
                                    </button>
                                )}

                                {assets.map((asset, key) => (
                                    <Fragment key={key}>
                                        {key < 4 && (
                                            <div
                                                className={`clickable`}
                                                onClick={() =>
                                                    setActiveAsset(asset)
                                                }
                                            >
                                                <img
                                                    src={extractAssetObj(
                                                        asset,
                                                        false
                                                    )}
                                                    alt={asset.name}
                                                />
                                            </div>
                                        )}
                                    </Fragment>
                                ))}

                                {assets.length > 3 && (
                                    <button onClick={() => handleNavArrow(0)}>
                                        <i className="fas fa-chevron-right"></i>
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ProductImages;
