import React, { useEffect, useRef, useState, useReducer } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import { matchPath } from "react-router";
import { findProduct } from "../../actions/products.actions";
import axios from "axios";
import {
    extractAssetObj,
    toggleWholePageLoader,
    setModalContent,
    toggleModal as toggleModalAdd,
} from "../../helpers/caboodleHelper";
import ProductFactory from "../../factories/ProductFactory";
import { reviewProduct } from "../../actions/product-review.actions";
import { isLoggedIn } from "../../helpers/sessionHelper";
import { JournalsUpperLeaves } from "../Journals/FloatingLeaves";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ReviewItems from "./components/ReviewItems";
import ProductImages from "./components/ProductImages";
import Skeleton from "../_global/Elements/Skeleton";
import leafTopLeft from "../../assets/img/leaf-review-left.png";
import leafTopRight from "../../assets/img/leaf-review-right.png";

import SuccessMessage from "../_global/Modal/SuccessMessage";
import CheckoutFactory from "../../factories/CheckoutFactory";
import { useSelector } from "react-redux";

const ProductView = ({ location, cart, user }) => {
    const cancelToken = useRef(null);
    const match = matchPath(location.pathname, {
        path: "/product/:slug/view",
        exact: true,
        strict: false
    });

    const uriSlug = match.params.slug;

    const [product, setProduct] = useState({});
    const [parentProduct, setParentProduct] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [reviewSubmit, setReviewSubmit] = useState(false);
    const MySwal = withReactContent(Swal);

    // REVIEW INGREDIENT TOGGLE
    const [activeContent, setActiveContent] = useState("ingredients");

    const [ingredients, setIngredients] = useState([]);

    const [reviews, setReviews] = useState([]);
    const [reviewRating, setReviewRating] = useState(5);
    const [reviewHoverRating, setReviewHoverRating] = useState(false);
    const [reviewContent, setReviewContent] = useState("");

    // HANDLE MODAL
    const [modalData, setModalData] = useState({});
    const [toggleModal, setToggleModal] = useState(false);

    // HANDLE VARIANT
    const [variantGroups, setVariantGroups] = useState([]);
    const [selectedVariants, setSelectedVariants] = useState({});
    const [activeVariant, setActiveVariant] = useState(undefined);

    const productFactory = new ProductFactory({ product, parentProduct });

    useEffect(() => {
        cancelToken.current = axios.CancelToken.source();

        findProduct(uriSlug, cancelToken.current).then(res => {
            if (
                res.message == "Successfully retrieved data." &&
                res.data.product &&
                res.data.product.hasOwnProperty("id")
            ) {
                let parent = res.data.parent;
                let selectedProduct = res.data.product;

                setParentProduct(parent);
                setProduct(selectedProduct);
                setIngredients(parent.ingredients);
                setReviews(parent.reviews);
                setVariantGroups(parent.variant_groups);
            }
        });

        return () => {
            if (cancelToken.current) cancelToken.current.cancel();
            cancelToken.current = null;
        };
    }, [location.pathname]);

    useEffect(() => {
        if (parentProduct.hasOwnProperty("id")) {
            let selectedV = Object.values(selectedVariants);
            let activeV = parentProduct.variants.find(
                values =>
                    JSON.stringify(values.variant_options) ==
                    JSON.stringify(selectedV)
            );

            setActiveVariant(activeV);

            if (activeV != undefined) {
                setProduct(activeV);
            }
        }
    }, [selectedVariants]);

    const handleQuantityChange = e => {
        if (e.target.value == "") {
            setQuantity(e.target.value);
        }

        let inputValue = parseInt(e.target.value);

        if (productFactory.getInventory() >= inputValue) {
            setQuantity(inputValue);
        }
    };

    const handleQuantityBlur = e => {
        if (e.target.value == "") {
            setQuantity(1);
        }
    };

    const order = useSelector(state => state.order);

    const checkoutFactory = new CheckoutFactory({
        order,
        cart,
        user,
    });

    const handleAddToCart = async () => {
        toggleWholePageLoader(true);

        await productFactory.addToCart({
            cart,
            user,
            quantity
        });
        
        await checkoutFactory.updateOrder();

        const confimartionEl = (
            <SuccessMessage
                title="Added to Cart"
                buttonText="CONTINUE TO SHOPPING"
                message=""
            />
        );

        setModalContent(false, false, confimartionEl);
        toggleModalAdd();
    };

    const checkoutCart = params => {
        // CHECK IF NO VARIANT SELECTED
        if (variantGroups.length > 0 && activeVariant == undefined) {
            return;
        }

        toggleWholePageLoader(true);

        productFactory.addToCart({
            cart,
            user,
            quantity,
            proceedToCheckout: false
        });
    };

    const handleProductReview = () => {
        setReviewSubmit(true);
        let params = {
            user_id: user.id,
            customer_id: user.customer.id,
            product_id: product.id,
            rating: reviewRating,
            content: reviewContent,
            author: user.name
        };

        reviewProduct(params)
            .then(res => {
                setReviewRating(5);
                setReviewContent("");
                setToggleModal(false);

                MySwal.fire({
                    title: <p>Thank you for the review.</p>,
                    icon: "success",
                    timer: 2000,
                    timerProgressBar: true
                });
            })
            .catch(err => {
                setReviewRating(5);
                setReviewContent("");
                setToggleModal(false);

                MySwal.fire({
                    title: <p>Sorry for the inconvenience</p>,
                    text: "Server Error. Please try again later.",
                    icon: "error",
                    timer: 2000,
                    timerProgressBar: true
                });
            })
            .finally(() => {
                setReviewSubmit(false);
            });
    };

    const handleStarEnter = num => {
        setReviewHoverRating(num);
    };

    const handleStarLeave = params => {
        setReviewHoverRating(false);
    };

    const modalContent = () => {
        let content = "";
        if (modalData.type == "ingredient") {
            const data = modalData.data;

            content = (
                <div className="modal-ingredient">
                    <button
                        className="modal-exit"
                        onClick={() => setToggleModal(false)}
                    >
                        <i className="far fa-times" />
                    </button>
                    <div className="product-information">
                        <h4 className="text-uppercase txt-medium txt-green txt-cerapro-medium">
                            INGREDIENT OF {parentProduct.title}
                        </h4>
                        <img
                            src={extractAssetObj(data, ["asset", "path"])}
                            alt={data.name}
                        />
                        <h2 className="title txt-header-2 txt-cerapro-bold">
                            {data.name}
                        </h2>
                        <div
                            className="txt-medium line-height-21"
                            dangerouslySetInnerHTML={{
                                __html: data.description
                            }}
                        />
                    </div>
                </div>
            );
        } else if (modalData.type == "review") {
            content = (
                <div className="modal-review">
                    <div className="images-container">
                        <div className="image">
                            <img
                                className="floating-leaf right right-upper faded-3"
                                src={leafTopRight}
                            />
                            <img
                                className="floating-leaf left left-upper faded-3"
                                src={leafTopLeft}
                            />
                        </div>
                    </div>
                    <button
                        className="modal-exit"
                        onClick={() => setToggleModal(false)}
                    >
                        <i className="far fa-times" />
                    </button>
                    <div className="review-information">
                        <div className="txt-medium text-uppercase margin-bottom-35 txt-cerapro-bold">
                            write a review
                        </div>
                        <h2 className="txt-cerapro-bold">
                            How would you rate our product?
                        </h2>

                        <div className="star-container txt-xxlarge margin-top-20">
                            {[...Array(5)].map((e, i) => (
                                <button
                                    key={i}
                                    onClick={() => setReviewRating(i + 1)}
                                    onMouseEnter={() => handleStarEnter(i + 1)}
                                    onMouseLeave={() => handleStarLeave()}
                                >
                                    {reviewHoverRating ? (
                                        <>
                                            {i < reviewHoverRating ? (
                                                <i className="fas fa-star" />
                                            ) : (
                                                <i className="far fa-star" />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {i < reviewRating ? (
                                                <i className="fas fa-star" />
                                            ) : (
                                                <i className="far fa-star" />
                                            )}
                                        </>
                                    )}
                                </button>
                            ))}
                        </div>
                        <textarea
                            className="form-control margin-top-20"
                            cols="30"
                            rows="8"
                            placeholder="Leave a comment...."
                            onChange={e => setReviewContent(e.target.value)}
                            value={reviewContent}
                        />

                        <div className="row margin-top-30 justify-content-center">
                            <div>
                                <button
                                    onClick={() => handleProductReview()}
                                    className="btn btn-primary btn-block"
                                    disabled={reviewSubmit}
                                >
                                    Submit Review
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return content;
    };

    const [imageNav, setImageNav] = useState({ nav1: null, nav2: null });
    const sliderMain = useRef();
    const sliderNav = useRef();

    useEffect(() => {
        setImageNav({
            nav1: sliderMain.current,
            nav2: sliderNav.current
        });
    }, []);

    const { nav1, nav2 } = imageNav;

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    const navSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false
    };

    return (
        <div className="product-view">
            {/* INGREDIENTS/REVIEWS MODAL */}
            <div className={`modal-caboodle ${toggleModal ? "open" : ""}`}>
                <div className="modal-container">
                    {toggleModal ? modalContent() : null}
                </div>
            </div>
            {/* INGREDIENTS/REVIEWS MODAL END */}

            <div className="grid grid-2">
                <ProductImages product={parentProduct} />

                <div className="content-container">
                    <div className="details-container">
                        <div className="details">
                            {parentProduct.title ? (
                                <h1 className={`txt-cerapro-bold txt-header-2`}>
                                    {parentProduct.title}
                                </h1>
                            ) : (
                                <>
                                    <Skeleton height="20px" />
                                    <br />
                                </>
                            )}
                            {parentProduct.description ? (
                                <div
                                    className={`txt-gray-2 txt-large line-height-30`}
                                    dangerouslySetInnerHTML={{
                                        __html: parentProduct.description
                                    }}
                                />
                            ) : (
                                <>
                                    <Skeleton />
                                    <div className="mb-1" />
                                    <Skeleton width="90%" />
                                    <div className="mb-1" />
                                    <Skeleton width="80%" />
                                    <div className="mb-1" />
                                    <Skeleton width="70%" />
                                    <div className="mb-3" />
                                </>
                            )}

                            {parentProduct.specifications ? (
                                <div
                                    className="product-specification"
                                    dangerouslySetInnerHTML={{
                                        __html: parentProduct.specifications
                                    }}
                                />
                            ) : (
                                <>
                                    <Skeleton />
                                    <div className="mb-1" />
                                    <Skeleton width="90%" />
                                    <div className="mb-1" />
                                    <Skeleton width="80%" />
                                    <div className="mb-3" />
                                </>
                            )}

                            {/* VARIANTS */}
                            <div className="variants-container">
                                {productFactory.getInventory() &&
                                variantGroups.length > 0 ? (
                                    <>
                                        {variantGroups.map((group, key) => (
                                            <div
                                                key={key}
                                                className="margin-bottom-10"
                                            >
                                                <h5>{group.name}</h5>
                                                <div>
                                                    {group.options.map(
                                                        (variant, idx) => (
                                                            <button
                                                                key={idx}
                                                                className={`txt-cerapro-medium ${
                                                                    Object.values(
                                                                        selectedVariants
                                                                    ).find(
                                                                        value => {
                                                                            return (
                                                                                JSON.stringify(
                                                                                    value
                                                                                ) ==
                                                                                JSON.stringify(
                                                                                    {
                                                                                        id:
                                                                                            variant.id,
                                                                                        name:
                                                                                            variant.name,
                                                                                        option:
                                                                                            group.name,
                                                                                        group_id:
                                                                                            variant.group,
                                                                                        prices: []
                                                                                    }
                                                                                )
                                                                            );
                                                                        }
                                                                    )
                                                                        ? "selected"
                                                                        : ""
                                                                }`}
                                                                onClick={e =>
                                                                    setSelectedVariants(
                                                                        {
                                                                            ...selectedVariants,
                                                                            [group.id]: {
                                                                                id:
                                                                                    variant.id,
                                                                                name:
                                                                                    variant.name,
                                                                                option:
                                                                                    group.name,
                                                                                group_id:
                                                                                    variant.group,
                                                                                prices: []
                                                                            }
                                                                        }
                                                                    )
                                                                }
                                                            >
                                                                {variant.name}
                                                            </button>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                ) : null}
                            </div>
                            {/* VARIANTS END */}

                            <div
                                className={`price-container flex align-center flex-wrap ${
                                    productFactory.getDiscount()
                                        ? ""
                                        : "justify-between"
                                }`}
                            >
                                {/* PRICE */}

                                <div className="price txt-cerapro-bold txt-header-2">
                                    {productFactory.getDiscount() ? (
                                        <>
                                            {product.formatted_price}{" "}
                                            <del
                                                className="mr-1"
                                                style={{ fontSize: "20px" }}
                                            >
                                                {product.formatted_raw_price}
                                            </del>
                                            <span
                                                className="mr-3"
                                                style={{ fontSize: "20px" }}
                                            >
                                                -
                                                {productFactory.getDiscountPercentage()}
                                                %
                                            </span>
                                        </>
                                    ) : (
                                        <>{product.formatted_price}</>
                                    )}
                                </div>
                                {/* PRICE END */}

                                {productFactory.getInventory() ? (
                                    <>
                                        {/* QUANTITY */}
                                        <div className="quantity-container mb-1">
                                            <button
                                                onClick={() => {
                                                    if (quantity == 1) {
                                                        return;
                                                    }
                                                    setQuantity(
                                                        prevQuantity =>
                                                            prevQuantity - 1
                                                    );
                                                }}
                                            >
                                                -
                                            </button>
                                            <input
                                                type="text"
                                                value={quantity}
                                                onChange={handleQuantityChange}
                                                onBlur={handleQuantityBlur}
                                            />
                                            <button
                                                onClick={() => {
                                                    if (
                                                        productFactory.getInventory() <=
                                                        quantity
                                                    ) {
                                                        return;
                                                    }
                                                    setQuantity(
                                                        prevQuantity =>
                                                            prevQuantity + 1
                                                    );
                                                }}
                                            >
                                                +
                                            </button>
                                        </div>
                                        {/* QUANTITY END */}

                                        {/* CHECKOUT BUTTON */}
                                        <button
                                            className="btn btn-primary checkout-btn"
                                            onClick={() =>
                                                    handleAddToCart()
                                                }
                                            disabled={
                                                variantGroups.length > 0 &&
                                                activeVariant == undefined
                                            }
                                        >
                                            {/* Checkout Now */}
                                            Add to Cart
                                        </button>
                                        {/* CHECKOUT BUTTON END */}
                                    </>
                                ) : (
                                    <div>Out of stock</div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="reviews-ingredients-container">
                        {/* INGREDIENTS/REVIEWS NAV */}
                        <div className="r-i-navigation">
                            <button
                                className={`margin-right-30 ${
                                    activeContent == "reviews" ? "active" : ""
                                }`}
                                onClick={() => setActiveContent("reviews")}
                            >
                                REVIEWS
                            </button>
                            <button
                                className={`${
                                    activeContent == "ingredients"
                                        ? "active"
                                        : ""
                                }`}
                                onClick={() => setActiveContent("ingredients")}
                            >
                                INGREDIENTS
                            </button>
                        </div>
                        {/* INGREDIENTS/REVIEWS NAV END */}

                        <div className="reviews-ingredients">
                            {/* REVIEWS */}
                            <div
                                className={`reviews-container ${
                                    activeContent == "reviews" ? "active" : ""
                                }`}
                            >
                                {isLoggedIn() && (
                                    <div className="write-review flex justify-between margin-bottom-10">
                                        <div className="content">
                                            Tell us about your experience on our
                                            product!
                                        </div>
                                        <button
                                            className="btn btn-clear"
                                            onClick={() => {
                                                setModalData({
                                                    type: "review"
                                                });
                                                setToggleModal(true);
                                            }}
                                        >
                                            Write a Review
                                        </button>
                                    </div>
                                )}

                                {reviews.length > 0 ? (
                                    <ul className="txt-medium">
                                        {reviews.map((review, idx) => (
                                            <li
                                                key={idx}
                                                className="review-item py-2"
                                            >
                                                <ReviewItems review={review} />
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <div>No Review yet</div>
                                )}
                            </div>
                            {/* REVIEWS END */}

                            {/* INGREDIENTS */}
                            <div
                                className={`ingredients-container ${
                                    activeContent == "ingredients"
                                        ? "active"
                                        : ""
                                }`}
                            >
                                {parentProduct.shipping ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: parentProduct.shipping
                                        }}
                                    />
                                ) : null}
                                {/* <table>
                                    <tbody>
                                        {ingredients.length > 0 &&
                                            ingredients.map(
                                                (ingredient, idx) => (
                                                    <tr
                                                        key={idx}
                                                        onClick={() => {
                                                            setModalData({
                                                                type:
                                                                    "ingredient",
                                                                data: ingredient
                                                            });
                                                            setToggleModal(
                                                                true
                                                            );
                                                        }}
                                                        className="clickable"
                                                    >
                                                        <td>
                                                            <i className="fas fa-circle" />
                                                        </td>
                                                        <td>
                                                            <h4 className="txt-medium txt-cerapro-bold">
                                                                {
                                                                    ingredient.name
                                                                }
                                                            </h4>
                                                        </td>
                                                        <td>
                                                            <div
                                                                className="txt-medium ingredient-description"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        ingredient.description
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <button>
                                                                <i className="far fa-angle-right" />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </table> */}
                            </div>
                            {/* INGREDIENTS END */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        order: state.order,
        products: state.products,
        user: state.user,
        page: state.page,
        cart: state.cart
    };
}

export default connect(mapStateToProps, null)(ProductView);
