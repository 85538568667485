import React, { Component } from 'react';

class ColorPicker extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        open: false
    }
    
    render() {
        return (
            <div className={`relative-container color-picker ${this.state.open ? "open" : ""} clickable`}>
                <div
                    className="flex align-center"
                    onClick={() => this.setState({open: !this.state.open})}
                >
                    <div
                        className="color-pallete"
                        style={{background: this.props.selectedColor}}
                        title="Click to select"
                    />
                    <div>
                        <i className="fas fa-caret-down txt-gray-light txt-large margin-left" />
                    </div>
                </div>
                <div className="selection">
                    {
                        this.props.selection.map(option => {
                            return (
                                <div
                                    className="color-pallete margin-top"
                                    style={{background: option.name}}
                                    title={option.name}
                                    onClick={() => this.handleColorSelect(option)}
                                />
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    handleColorSelect = (option) => {
        this.setState({
            open: !this.state.open
        }, () => {
            this.props.onColorSelect(option);
        });
    }
}

export default ColorPicker;