import axios from "axios";
import toastr from "toastr";
import * as config from "../constants/config";
import * as sessionHelper from "./sessionHelper";

export function connect(showLog = true, additionalHeaders = {}) {
    let instance;
    let headers = Object.assign(
        { "Access-Control-Allow-Origin": "*" },
        additionalHeaders
    );

    toastr.options = {
        positionClass: "toast-bottom-right",
        preventDuplicates: true
    };

    if (
        sessionHelper.getUser() &&
        sessionHelper.getUser().hasOwnProperty("name")
    ) {
        headers = Object.assign({}, headers, {
            Authorization:
                "Bearer " +
                (sessionHelper.getUser().token
                    ? sessionHelper.getUser().token
                    : "")
        });
        headers["ucr"] =
            sessionHelper.getUser().customer &&
            sessionHelper.getUser().customer.primary_address &&
            sessionHelper
                .getUser()
                .customer.primary_address.hasOwnProperty("id")
                ? sessionHelper.getUser().customer.primary_address.country_id
                : 0;

        if (config.STORE_KEY) {
            headers["stkn"] = config.STORE_KEY;
        }

        instance = axios.create({
            baseURL: config.API_URL,
            headers
        });
    } else {
        if (config.STORE_KEY) {
            headers["stkn"] = config.STORE_KEY;
        }

        headers["ucr"] = 0;

        instance = axios.create({
            headers,
            baseURL: config.API_URL
        });
    }

    instance.interceptors.response.use(
        function(response) {
            // Do something with response data
            if (response.data.message && showLog) {
                toastr.success(response.data.message);
            }
            //only return the data
            return response.data;
        },
        function(error) {
            // Do something with response error
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message !== Array &&
                error.response.data.message === "Token is Expired"
            ) {
                sessionHelper.logout();
                sessionHelper.removeCart();
                toastr.error.response("Token is expired! You have been logout");

                setInterval(function() {
                    window.location.reload();
                }, 1000);
            }
            if (
                error &&
                error.response &&
                error.response.data &&
                error.response.data.message === Array &&
                showLog
            ) {
                error.response.data.message.map((message, index) =>
                    toastr.error(message)
                );
            } else if (showLog) {
                if (
                    error &&
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                ) {
                    toastr.error(error.response.data.message);
                }
            }

            return Promise.reject(error.response);
        }
    );
    return instance;
}
