import React from 'react';
import PropTypes from 'prop-types';
import {
    NavLink,
    Link
} from 'react-router-dom';
// import ReactStars from 'react-stars';
import {
        extractAssetObj,
        computeDiscountPercentage,
        toggleWholePageLoader,
        addToCart,
        makeASquare,
        toggleModal,
        setModalContent
    } from '../../../helpers/caboodleHelper';
import history from '../../../History';
import FormattedTotal from '../Elements/FormattedTotal';

import logo from '../../../assets/img/logo.jpg';
import Modal from '../../_global/Modal/Modal';
import ColorPicker from '../../_global/Elements/ColorPicker';
import CustomSelect from '../../_global/Elements/CustomSelect';

import SuccessMessage from "../../_global/Modal/SuccessMessage";
import ProductFactory from "../../../factories/ProductFactory";
import CheckoutFactory from "../../../factories/CheckoutFactory";
import { useSelector, connect } from "react-redux";
class ProductCard extends React.PureComponent {
    
    state = {
        isOpen: false,
        tempVariantOptions: [],
        selectedVariant: {},
        showVariantHelp: false
    }

    componentDidMount() {
        makeASquare(document.getElementsByClassName(`product-container-image`));
    }

    handleSelectionVariantOption = (option) => {
        const parentProduct = Object.assign({}, this.props.product);
        let selectedOptions = this.state.tempVariantOptions.slice(),
            variantHitCtr = 0,
            variantFind = false;
            
        selectedOptions[option.group] = option;

        variantFind = parentProduct.variants.find(variant => {
            
            if (variant.variant_options.length === Object.keys(selectedOptions).length) {
                Object.keys(selectedOptions).map(groupId => {
                    const optionFind = variant.variant_options.find(optionSearch => {
                        return optionSearch.id === selectedOptions[groupId].id;
                    });

                    if (optionFind) {
                        variantHitCtr += 1;
                    }
                });
            }

            if (variantHitCtr === variant.variant_options.length) {
                return variant;
            }
            
            variantHitCtr = 0;
            return false
        });

        if (variantFind) {
            // this.setState({
            //     tempVariantOptions: {}
            // }, () => {
            //     History.push(`/product/${variantFind.slug}/view`);
            // });
            this.setState({
                tempVariantOptions: selectedOptions,
                selectedVariant: variantFind
            });

            console.log(selectedOptions);
        } else {
            this.setState({
                tempVariantOptions: selectedOptions
            });
        }
    }

    handleAddToCart = async (item, order, cart, user) => {
        toggleWholePageLoader(true);
        
        const productFactory = new ProductFactory({ product: item });
        const checkoutFactory = new CheckoutFactory({
            order,
            cart,
            user,
        });

        let quantity = 1;

        await productFactory.addToCart({
            cart,
            user,
            quantity
        });
        
        await checkoutFactory.updateOrder();
        
        const confimartionEl = (
            <SuccessMessage
                title="Added to Cart"
                buttonText="CHECKOUT NOW"
                buttonLink="/checkout/information"
                secondButtonText="CONTINUE SHOPPING"
                message=""
            />
        );

        setModalContent(false, false, confimartionEl);
        toggleModal();
    };

    addToCartVariant() {
        const  { selectedVariant } = this.state;
        this.setState({ isOpen: false});
        addToCart(selectedVariant, 1, true);
    }


    render() {
        const { product, loading, order, cart, user } = this.props;

        if (loading) {
            return (
                <div className="product-card-js product-card ph-item">
                    <div className="image-container">
                        <img src="" alt="loading" className="product-container-image" />
                        <div className="loading-image-overlay" />
                    </div>
                    <div className="info ph-item">
                        <div className="ph-col-12">
                            <div className="ph-row">
                                <div className="ph-col-8 big" />
                                <div className="ph-col-6" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        
        const active_sale = product.active_sale.length > 0 ? product.active_sale[0] : false;
        const active_flash_sale = product.active_flash_sale.length > 0 ? product.active_flash_sale[0] : false;

        return (
            <div 
                className="product-card-js product-card flex direction-column pad-bottom-30"
            >
                <div className={`image-container relative-container`}>
                    <NavLink
                        to={`/product/${product.slug}/view`}
                    >
                        <img 
                            src={extractAssetObj(product, ['image_paths', 'path'], "path", logo)}
                            alt={product.title}
                            className={`product-container-image fade-in`}
                            onError={e => {
                                e.target.src = logo;
                            }}
                        />
                        <div className="image-overlays pad-10">
                            {
                                (active_flash_sale || active_sale) && (
                                    <div className="bg-red-pastel txt-white pad-right-15 pad-left-15 pad-top pad-bottom txt-small">
                                        <i className="fas fa-tag margin-right" /> SALE
                                    </div>
                                )
                            }
                        </div>
                    </NavLink>
                </div>
                <div className="flex-1">
                    <div className="info">
                        {
                            product && product.brand ? (
                                <NavLink
                                    to={`/products?brands=${product.brand.slug}`}
                                >
                                    <h1 className="txt-large reg-header margin-0 txt-primary">
                                        {product.brand.title}
                                    </h1>
                                </NavLink>
                            ) : null
                        }
                        <NavLink
                            to={`/product/${product.slug}/view`}
                        >
                            <h1 className="product-title reg-header txt-large txt-gray-dark">
                                {product && product.title}
                            </h1>
                            <div className="product-price txt-large flex align-center">
                                {
                                    product && (
                                        <div className="txt-bold-qs-bold">
                                            <FormattedTotal
                                                formattedPrice={product.formatted_price}
                                            />
                                        </div>
                                    )
                                }
                                {
                                    active_flash_sale || active_sale ? (
                                        product && (
                                            <div className="txt-gray-light txt-strike-element pad-left pad-right margin-left-10 inline-block">
                                                <FormattedTotal formattedPrice={product && product.formatted_raw_price} />
                                            </div>
                                        )
                                    ) : false
                                }
                            </div>
                            {/* <div className="flex align-center product-star">
                                {
                                    [...Array(5)].map((star, index) => {
                                        if ((index + 1) <= product.average_review) {
                                            return (
                                                <i
                                                    className="fas fa-star txt-xlarge"
                                                    key={index}
                                                />
                                            );
                                        } else {
                                            return (
                                                <i
                                                    className="far fa-star txt-xlarge"
                                                    key={index}
                                                />
                                            );
                                        }
                                    })
                                }
                            </div> */}
                        </NavLink>
                    </div>
                </div>
                {
                    (parseInt(product.enable_inventory) === 1 && parseInt(product.inventory) === 0) ? (
                        <div className="btn btn-gray btn-pad-xlarge btn-block txt-large txt-bold margin-top-50 no-pointer">
                            OUT OF STOCK
                        </div>
                    ) : (
                        <button
                            className="btn btn-clear-green btn-pad-xlarge btn-block txt-large txt-bold margin-top-50"
                            onClick={() => product.variants.length > 0 ? this.setState({isOpen: true}) : this.handleAddToCart(product, order, cart, user)}
                        >
                            <i className="far fa-plus margin-right-10" />
                            ADD TO CART
                        </button>
                    )
                }
                {product.variant_groups && product.variant_groups.length > 0 && (

                    <Modal
                        zIndex={999999}
                        isOpen={this.state.isOpen}
                        toggle={() => this.setState({isOpen:false}) }
                    >
                        <div className="product-modal-container">
                            <div className="product-image">
                                <div className="image-container">
                                    <img 
                                        src={extractAssetObj(product, ['image_paths', 'path'])}
                                        alt={product.title}
                                    />
                                </div>
                            </div>
                            <div className="product-info">
                                <div className="item-details">
                                    {
                                        product && product.brand ? (
                                            <NavLink
                                                to={`/products?brands=${product.brand.slug}`}
                                            >
                                                <h3 className="txt-large reg-header margin-0 txt-primary">
                                                    {product.brand.title}
                                                </h3>
                                            </NavLink>
                                        ) : null
                                    }
                                    <h1 className="product-title reg-header txt-header txt-gray-dark">
                                        <Link
                                            to={`/product/${product.slug}/view`}
                                        >
                                            {product.title}
                                        </Link>
                                    </h1>
                                    <div className="relative-container">
                                        <div
                                            className={`clickable product-variant-helper text-center txt-large pad-10 ${this.state.showVariantHelp && 'show'}`}
                                            onClick={() => this.setState({showVariantHelp: false})}
                                        >
                                            Please select your product's variant
                                        </div>
                                        <div className="margin-top-10">
                                            {
                                                product.variant_groups.map(group => {
                                                    return (
                                                        <div
                                                            className="relative-container margin-top-20"
                                                            key={`variantGroup${group.id}`}
                                                        >
                                                            <div
                                                                className="flex align-center"
                                                            >
                                                                <div className="txt-bold txt-medium no-whitespace margin-right">
                                                                    Choose {group.name}:
                                                                </div>
                                                                {
                                                                    group.name.toLowerCase() === "color" ? (
                                                                        <div className={`margin-left-10`}>
                                                                            <ColorPicker
                                                                                selectedColor={
                                                                                    this.state.tempVariantOptions.length > 0 ? (this.state.tempVariantOptions[group.id] ?
                                                                                    this.state.tempVariantOptions[group.id].name :
                                                                                    product.parent.id === product.id ? "transparent" : product.variant_options.find(attr => {return attr.group_id === group.id}).name) : false
                                                                                }
                                                                                selection={group.options ? group.options : []}
                                                                                onColorSelect={this.handleSelectionVariantOption}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        <CustomSelect
                                                                            value={
                                                                                this.state.tempVariantOptions.length>0 ? (this.state.tempVariantOptions[group.id] ?
                                                                                this.state.tempVariantOptions[group.id].name :
                                                                                product.parent.id === product.id ? false : (product.variant_options.length > 0 ? product.variant_options.find(attr => {return attr.group_id === group.id}).name: false)):false
                                                                            }
                                                                            selection={group.options ? group.options : []}
                                                                            onSelect={this.handleSelectionVariantOption}
                                                                        />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="product-price txt-large flex align-center">
                                        {
                                            product && (
                                                <div className="txt-bold-qs-bold">
                                                    <FormattedTotal
                                                        formattedPrice={product.formatted_price}
                                                    />
                                                </div>
                                            )
                                        }
                                        {
                                            active_flash_sale || active_sale ? (
                                                product && (
                                                    <div className="txt-gray-light txt-strike-element pad-left pad-right margin-left-10 inline-block">
                                                        <FormattedTotal formattedPrice={product && product.formatted_raw_price} />
                                                    </div>
                                                )
                                            ) : false
                                        }
                                    </div>
                                    <div style={{}}>
                                        <button
                                            className="btn btn-clear-green btn-pad-xlarge btn-block txt-large txt-bold margin-top-50"
                                            onClick={() => (this.state.selectedVariant && this.state.selectedVariant.id) ? this.addToCartVariant():this.setState({showVariantHelp: true})}
                                            >
                                            <i className="far fa-plus margin-right-10" />
                                            ADD TO CART
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        order: state.order,
        cart: state.cart,
        user: state.user
    };
}

ProductCard.propTypes = {
    product: PropTypes.object,
    user: PropTypes.object,
    order: PropTypes.object,
    cart: PropTypes.object,
    loading: PropTypes.bool
};

export default connect(mapStateToProps, null)(ProductCard);

