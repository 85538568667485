import React from 'react';
import {NavLink} from 'react-router-dom';

import { toggleModal } from '../../../helpers/caboodleHelper';

const SuccessMessage = ({title, message, buttonText, buttonLink, secondButtonText, secondButtonLink, status="success", onConfirm, renderButton}) => {
    return(
        <div className="text-center txt-dark-link pad-50 logout-confirmation relative-container">
            <div>
                <i className={`fas fa-${status === "success" ? "check-circle txt-success" : (status === "warning" ? "exclamation-circle txt-warning" : "times-circle txt-error")} txt-giantsize`} />
            </div>
            <div className="txt-header-2 txt-bold margin-top-30">
                {title}
            </div>
            <div className="txt-large">
                {message}
            </div>
            <div
                className="margin-top-50 txt-success txt-large clickable"
                onClick={() => onConfirm ? onConfirm() : toggleModal(false)}
            >
                {
                    renderButton ? 
                    renderButton() : (
                        buttonLink ? (
                            <NavLink
                                to={buttonLink}
                            >
                                {buttonText ? buttonText : "Continue"}
                            </NavLink>
                        ) : (
                            buttonText ? buttonText : "Continue"
                        )
                    )
                }
                {
                    renderButton ? 
                    renderButton() : (
                        secondButtonLink ? (
                            <NavLink
                                to={secondButtonLink}
                            >
                                {secondButtonText ? secondButtonText : "Continue"}
                            </NavLink>
                        ) : (
                            <span style={{marginLeft:'30px'}}>
                            {secondButtonText ? secondButtonText : "Continue"}
                            </span>
                        )
                    )
                }
            </div>
            <div
                className="dismiss-btn clickable"
                onClick={() => toggleModal(false)}
            >
                <i className="far fa-times-circle txt-xxlarge" />
            </div>
        </div>
    );
}

export default SuccessMessage;