import React from "react";
import leafLeft from "../../assets/img/leaf-left.png";
import leafRight from "../../assets/img/leaf-right.png";

export const ContactBottomLeaves = () => {
    return (
        <>
            <img
                className="floating-leaf right right-bottom faded-75"
                src={leafRight}
            />
            <img
                className="floating-leaf left left-bottom faded-75"
                src={leafLeft}
            />
        </>
    );
};