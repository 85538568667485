// import * as config from '../constants/config';
// import * as apiHelper from './apiHelper';
import moment from "moment";
import { store } from "../index";

const APP_KEY = "CBDL";
const USR_KEY = `${APP_KEY}_USR`;
const CART_KEY = `${APP_KEY}_CART`;
const CART_DATE = `${APP_KEY}_CART_DATE`;
const CHECKOUT = `${APP_KEY}_CHECKOUT`;

export function getUser() {
    const user = Object.assign({}, store.getState().user);
    return user;
}

export function setUser(user) {
    //dummy permissions
    user.permissions = ["adb0a36965f46601161ea60a7ee81a5e"];
    localStorage.setItem(USR_KEY, JSON.stringify(user));
}

export function logout() {
    localStorage.removeItem(USR_KEY);
}

export function isLoggedIn() {
    const user = Object.assign({}, store.getState().user);

    if (user && user.id) return true;

    return false;
}

export function getCart() {
    let cart = Object.assign({}, store.getState().cart);
    if (cart) {
        if (cart && cart.items && cart.items.length > 0) {
            if (!localStorage.getItem(CART_DATE)) {
                localStorage.setItem(
                    CART_DATE,
                    JSON.stringify({
                        expiry: moment().toDate()
                    })
                );
            }

            let start_date = moment(
                JSON.parse(localStorage.getItem(CART_DATE)).expiry
            );
            let dateExpired = start_date.clone();
            let now = moment();
            dateExpired = dateExpired.add(1, "days");
            if (moment(now).isBetween(start_date, dateExpired)) {
                return cart;
            } else {
                return { items: [], source: "", last_device: "" };
            }
        }
        return cart;
    }
    return { items: [], source: "", last_device: "" };
}

export function setCheckoutCart(cart) {
    localStorage.setItem(CHECKOUT, JSON.stringify(cart));
}
export function getCheckoutCart() {
    if (localStorage.getItem(CHECKOUT)) {
        return JSON.parse(localStorage.getItem(CHECKOUT));
    }
    return {};
}
export function clearCheckoutCart() {
    localStorage.removeItem(CHECKOUT);
}
export function setCartDate() {
    localStorage.setItem(
        CART_DATE,
        JSON.stringify({
            expiry: moment().toDate()
        })
    );
}

export function removeCart() {
    localStorage.removeItem(CART_KEY);
}

export function hasCart() {
    if (localStorage.getItem(CART_KEY)) {
        return true;
    } else {
        return false;
    }
}
