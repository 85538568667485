import React from "react";
import Skeleton from "../Elements/Skeleton";
import TitleLeaf from "../Elements/TitleLeaf";

const TestimonialSectionSkeleton = () => {
    return (
        <div className="testimonial-section container">
            <TitleLeaf className="txt-header txt-cerapro-bold" loading={true} />

            <div className="items-container grid grid-3 gap-20">
                <Skeleton height="324px" />
                <Skeleton height="324px" />
                <Skeleton height="324px" />
            </div>
        </div>
    );
};
export default TestimonialSectionSkeleton;
