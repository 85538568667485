import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import goBack from "../../assets/img/go-back.png";
import {
    extractAssetObj,
    getPage,
    getPageItem,
    getPageItemsSeeMore,
    slugify
} from "../../helpers/caboodleHelper";
import history from "../../History";
import { JournalsUpperLeaves } from "./FloatingLeaves";
import { JournalCard } from "./JournalCard";

const JournalView = ({ page, site_options, location }) => {
    const cancelToken = useRef(null);
    const banners = page.banners ? page.banners : undefined;

    let urlSlug = location.pathname.split("/")[2];
    let journalId = urlSlug.split("-");
    journalId = journalId[journalId.length - 1];

    const [journal, setJournal] = useState();
    const [postsToShow, setPostsToShow] = useState([]);

    useEffect(() => {
        cancelToken.current = axios.CancelToken.source();

        getPage("banners", cancelToken.current);

        getPageItem({ id: journalId }).then(res => {
            if (res.items.length > 0) {
                let items = res.items;
                setJournal(items[0]);
            }
        });
        return () => {
            if (cancelToken.current) cancelToken.current.cancel();
            cancelToken.current = null;
        };
    }, [journalId]);

    useEffect(() => {
        getPageItemsSeeMore("journals", { page: 1, view: 1 }).then(res => {
            if (res.value && res.value.items.length > 0) {
                let items = res.value.items;
                setPostsToShow(prevPostsToShow => [
                    ...prevPostsToShow,
                    ...items
                ]);
            }
        });
    }, []);

    const goToPreviousPath = () => {
        history.goBack();
    };

    return (
        <div className="journals page-body-container">
            <div className="container pad-bottom-20">
                <div className="journals-section">
                    <div className="latest-news journal-view pad-top-50 margin-bottom-50">
                        <JournalsUpperLeaves />
                    </div>
                </div>
                <button className="btn-no-style" onClick={goToPreviousPath}>
                    <div className="go-back">
                        <img className="" src={goBack} />
                        <h5 className="txt-cerapro-bold txt-medium back">
                            {" "}
                            Back
                        </h5>
                    </div>
                </button>
            </div>

            {journal && (
                <div className="container with-border-bottom">
                    <div className="journal-content padding-top-50">
                        <h2 className="txt-cerapro-bold txt-header pad-bottom-20">
                            {journal.title}
                        </h2>
                        <h3 className="txt-cerapro-bold txt-gray-lighter all-caps pad-bottom-20">
                            {journal.date}
                        </h3>
                        <img
                            className="image"
                            src={extractAssetObj(journal, ["image", "path"])}
                            alt={journal.title}
                        />
                        <div
                            className="content txt-cerapro-regular txt-medium pad-bottom-50 pad-top-50"
                            dangerouslySetInnerHTML={{
                                __html: journal.content
                            }}
                        />
                    </div>
                </div>
            )}

            <div className="container pad-bottom-50">
                <h5 className="txt-cerapro-bold green text-center pad-top-50 pad-bottom-50">
                    Read More
                </h5>
                <div className="journals-section">
                    <div className="journals-grid-view margin-bottom-50">
                        {postsToShow &&
                            postsToShow.length > 0 &&
                            postsToShow.map((item, index) => (
                                <NavLink
                                    strict
                                    to={`/journals/${slugify(
                                        item.title + " " + item.id,
                                        "forward"
                                    )}/view`}
                                    className="journal"
                                    key={index}
                                >
                                    <JournalCard journalPost={item} />
                                </NavLink>
                            ))}
                    </div>
                    <NavLink exact to="/journals">
                        <button className="txt-cerapro-bold txt-white btn-load">
                            See All
                        </button>
                    </NavLink>
                </div>
            </div>

            {banners && banners.endBanner && (
                <div
                    className="banner end-section pad-top-50 pad-bottom-50 flex align-center"
                    style={{
                        backgroundImage: `url(${extractAssetObj(
                            banners.endBanner,
                            ["image", "path"]
                        )})`
                    }}
                >
                    <div className="container txt-cerapro-bold txt-header">
                        <div className="content margin-bottom-50">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: banners.endBanner.content
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        page: state.page,
        site_options: state.site_options
    };
}

export default connect(mapStateToProps, null)(JournalView);
