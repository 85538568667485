import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { userRegister } from "../../../helpers/caboodleHelper";
import { isLoggedIn } from "../../../helpers/sessionHelper";
import { validate } from "../../../helpers/validationHelper";
import History from "../../../History";
import { JournalsUpperLeaves } from "../../Journals/FloatingLeaves";
import TitleLeaf from "../Elements/TitleLeaf";
import TextInput from "../Form/TextInput";

const Registration = () => {
    const initialForm = {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirm_password: ""
    };

    const [socialMediaOpen, setSocialMediaOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(initialForm);
    const [formErrors, setFormErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);

    const MySwal = withReactContent(Swal);
    const schema = {
        properties: {
            firstname: {
                type: "string",
                required: true,
                allowEmpty: false
            },
            lastname: {
                type: "string",
                required: true,
                allowEmpty: false
            },
            email: {
                type: "string",
                required: true,
                allowEmpty: false,
                format: "email"
            },
            password: {
                type: "string",
                required: true,
                allowEmpty: false
            },
            confirm_password: {
                type: "string",
                required: true,
                allowEmpty: false
            }
        }
    };

    if (isLoggedIn()) History.push("/");

    const handleFormChange = e => {
        let name = e.target.name,
            value = e.target.value;

        setForm({ ...form, [name]: value });
    };

    const handleRegistration = () => {
        if (!isFormValid()) return;
        if (form.password !== form.confirm_password)
            return setErrorMessage("Password doesn't match.");

        setIsLoading(true);

        userRegister(form)
            .then(res => {
                if (
                    res.message.trim() ==
                    "Thank you for your registration. Please check your email for activation details."
                ) {
                    MySwal.fire({
                        title: <p>Thank you for your registration</p>,
                        text: "Please check your email for activation details.",
                        icon: "success"
                    });
                } else {
                    MySwal.fire({
                        title: <p>Registration Failed!</p>,
                        text: "Server Error. Please try again later.",
                        icon: "error"
                    });
                }
                setForm(initialForm);
                setErrorMessage("");
            })
            .catch(err => {
                setErrorMessage("Email address is already registered.");
            })
            .finally(() => setIsLoading(false));
    };

    const isFormValid = () => {
        let validation = validate(form, schema);
        let errors = validation.errors,
            isValid = validation.valid;

        if (form.password !== form.confirm_password) {
            errors = Object.assign({}, errors, {
                confirm_password: `"Password" doesn't match.`
            });
            isValid = false;
        }
        setFormErrors(errors);

        return isValid;
    };

    return (
        <div className="login-container pad-bottom-50">
            <JournalsUpperLeaves />
            <div className="login pad-top-100">
                <TitleLeaf
                    className="txt-header txt-cerapro-bold"
                    title="Sign Up"
                />

                <div className="login-form">
                    <TextInput
                        placeholder="First Name"
                        name="firstname"
                        value={form.firstname}
                        onChange={handleFormChange}
                        onKeyEnter={handleRegistration}
                        error={formErrors.firstname}
                    />
                    <TextInput
                        placeholder="Last Name"
                        name="lastname"
                        value={form.lastname}
                        onChange={handleFormChange}
                        onKeyEnter={handleRegistration}
                        error={formErrors.lastname}
                    />
                    <TextInput
                        placeholder="Email Address"
                        name="email"
                        value={form.email}
                        onChange={handleFormChange}
                        onKeyEnter={handleRegistration}
                        error={formErrors.email}
                    />
                    <TextInput
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={form.password}
                        onChange={handleFormChange}
                        onKeyEnter={handleRegistration}
                        autoComplete="new-password"
                        error={formErrors.password}
                    />
                    <TextInput
                        placeholder="Confirm Password"
                        type="password"
                        name="confirm_password"
                        value={form.confirm_password}
                        onChange={handleFormChange}
                        onKeyEnter={handleRegistration}
                        autoComplete="new-password"
                        error={formErrors.confirm_password}
                    />
                    <div className="text-center txt-error txt-small">
                        {errorMessage}
                    </div>
                    <div className="text-center">
                        <button
                            className="btn btn-green btn-block btn-block-center submit-btn"
                            onClick={handleRegistration}
                            disabled={isLoading}
                        >
                            Create Account
                        </button>
                    </div>
                    <div className="registration-link txt-small flex justify-content-between">
                        <span>Already have an account? </span>

                        <NavLink
                            to="/login"
                            className="arti-link clickable txt-light-green"
                        >
                            <span>Log in</span>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};

Registration.propTypes = {};

export default Registration;
