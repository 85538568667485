import React from "react";
import leafTopLeft from "../../assets/img/leaf-top-left.png";
import leafTopRight from "../../assets/img/leaf-top-right.png";
import leafWaveLeft from "../../assets/img/pngwave-1.png";
import leafWaveRight from "../../assets/img/pngwave.png";

export const JournalsUpperLeaves = () => {
    return (
        <>
            <img
                className="floating-leaf left left-upper faded-3 journal-leaf"
                src={leafTopLeft}
            />
            <img
                className="floating-leaf right right-upper faded-3 journal-leaf"
                src={leafTopRight}
            />
        </>
    );
};

export const Section5Images = () => {
    return (
        <>
            <img
                className="floating-leaf left left-wave2"
                src={leafWaveRight}
            />
            <img
                className="floating-leaf right right-wave2"
                src={leafWaveLeft}
            />
        </>
    );
};
