import * as types from "../constants/actionTypes";
import initialState from "./initialState";

export default function(state = initialState.shippingMethods, action) {
    switch (action.type) {
        case types.GET_SHIPPING_METHODS + types.FULFILLED: {
            return Object.assign({}, state, {
                list: action.payload.data
            });
        }

        case types.SET_SHIPPING_METHOD: {
            return Object.assign({}, state, {
                selected: action.payload
            });
        }

        default:
            return state;
    }
}
