import * as ApiHelper from "../helpers/apiHelper";
import * as types from "../constants/actionTypes";
import { store } from "../index";

export function changeProductObject(product) {
    return {
        type: types.CHANGE_PRODUCT_OBJECT,
        payload: product
    };
}

export function unSelectProduct() {
    return {
        type: types.UNSELECT_PRODUCT,
        payload: {}
    };
}

export function findProduct(slug) {
    const request = ApiHelper.connect(false).get("/products/find", {
        params: {
            slug: slug
        }
    });

    return request;
    // return {
    //     type: types.FIND_PRODUCT, payload: request
    // };
}

export function findByTag(slug) {
    const request = ApiHelper.connect(false).get("/products/find_by_tags", {
        params: {
            slug: slug,
            skip: 0,
            take: 4
        }
    });

    return store.dispatch({
        type: types.FIND_BY_TAG + "---" + slug.replace(/ /g, "_") + "---",
        payload: request
    });
}

export function getProductBreadcrumbs(slug) {
    const request = ApiHelper.connect(false).get(
        "/products/get-product-breadcrumbs",
        {
            params: {
                slug: slug
            }
        }
    );

    return {
        type: types.GET_PRODUCT_BREADCRUMBS,
        payload: request
    };
}

export function getRelatedProducts(params = {}) {
    let baseParams = {
        parent: 0
    };
    if (!params.take) {
        baseParams = Object.assign(baseParams, {
            take: 12
        });
    }

    if (params.slug) {
        return {
            type: types.LOAD_RELATED_PRODUCTS,
            payload: ApiHelper.connect(false).post(
                `/products/related`,
                Object.assign({}, params, baseParams)
            )
        };
    } else {
        return {
            type: types.LOAD_RELATED_PRODUCTS + "_RESET"
        };
    }
}

export function loadCustomerWishlist(params) {
    return {
        type: types.LOAD_WISHLISTED_PRODUCTS,
        payload: ApiHelper.connect(false).post(
            `/customers/load_wish_list`,
            params
        )
    };
}

export function addToWishlist(params) {
    return {
        type: types.ADD_TO_WISHLIST,
        payload: ApiHelper.connect(false).post(
            `/customers/add_wish_list`,
            params
        )
    };
}

export function removeToWishlist(params) {
    return {
        type: types.REMOVE_TO_WISHLIST,
        payload: ApiHelper.connect(false).post(
            `/customers/remove-wish-list`,
            params
        )
    };
}

export function getProductReviews(params) {
    return {
        type: types.UPDATE_PRODUCT_REVIEW,
        payload: ApiHelper.connect(false).get("/product/get-product-review", {
            params
        })
    };
}

export function sendProductReview(params) {
    return {
        type: types.UPDATE_PRODUCT_REVIEW,
        payload: ApiHelper.connect(false).post(`/product_reviews/store`, params)
    };
}
