import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.select_options, action) {

    switch(action.type) {
        case types.COUNTRY_LIST + types.FULFILLED:
            return Object.assign({},
                state, 
                {
                    country_list: action.payload.data
                }
            );
        case types.AREA_LIST + types.FULFILLED:
            return Object.assign({},
                state, 
                {
                    area_list: action.payload.data
                }
            );
        case types.PROVINCE_LIST + types.FULFILLED:
            return Object.assign({},
                state, 
                {
                    province_list: action.payload.data
                }
            );
        case types.CITY_LIST + types.FULFILLED:
            return Object.assign({},
                state, 
                {
                    city_list: action.payload.data
                }
            );
        case types.GET_BRANCH_LIST + types.FULFILLED:
            return Object.assign({},
                state, 
                {
                    branch_list: action.payload.data && action.payload.data.branches
                }
            );
        default: 
            return state;
    }
}