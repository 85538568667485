import React from 'react';
import PropTypes from 'prop-types';
import ProductCard from '../ProductCard/ProductCard';

const ProductList = ({ products = [], loading = false, placeholderCardLength =  3, bigContainer = false, emptyListText = "", gridNumber = "3" }) => {
    
    if (loading) {
        return (
            <div className={`product-list ${bigContainer && 'big-container'} grid grid-${gridNumber} grid-lg-3 grid-md-3 grid-xs-2`}>
                {Array(placeholderCardLength).fill(0).map((product, index) => {
                    return (
                        <ProductCard key={index} loading />
                    );
                })}
            </div>
        );
    }

    if (products && products.length === 0) {
        return (
            <div className="text-center txt-header-2 txt-bold">
                No product found
            </div>
        );
    }

    return ( 
        <div className={`product-list ${bigContainer && 'big-container'} grid grid-${gridNumber} grid-lg-3 grid-md-3 grid-xs-2`}>
            {products.map((product, index) => {
                return (
                    <div
                        className="fade-in-up"
                        style={{animationDelay: `${index * 0.2}s`}}
                        key={index}
                    >
                        <ProductCard key={index} product={product} />
                    </div>
                );
            })}
        </div>
    );
};

ProductList.propTypes = {
    products: PropTypes.array,
    loading: PropTypes.bool,
    placeholderCardLength: PropTypes.number,
    bigContainer: PropTypes.bool,
    emptyListText: PropTypes.object,
    animation: PropTypes.bool
};

export default ProductList;