import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
    extractAssetObj,
    getPageItemsSeeMore,
    logoutUser
} from "../../../../helpers/caboodleHelper";
import { isLoggedIn } from "../../../../helpers/sessionHelper";

const SideBar = ({ open, toggle, user, links, productCount, products }) => {
    const [journalOn, setJournalOn] = useState(false);

    useEffect(() => {
        getPageItemsSeeMore("journals").then(res => {
            if (res.value && res.value.items.length > 0) {
                setJournalOn(true);
            }
        });
    }, []);
    return (
        <div
            className={`nav-sidebar-container ${
                open ? "open" : ""
            } flex align-center`}
        >
            <div className="link-container flex direction-column">
                <div className="action-container relative-container">
                    {user.hasOwnProperty("id") && (
                        <div className="customer-details pad-30">
                            {/* <div>
                                <img
                                    src={extractAssetObj(user, [
                                        "asset",
                                        "path"
                                    ])}
                                />
                            </div> */}
                            <div className="txt-bold-qs-bold">{`${user.customer.firstname} ${user.customer.lastname}`}</div>
                        </div>
                    )}
                    <div className="sidenav-toggle-btn">
                        <i
                            className="far fa-long-arrow-left clickable"
                            onClick={toggle}
                        />
                    </div>
                </div>
                <div className="menu-list margin-top-50 flex-1">
                    <ul>
                        <li>
                            <NavLink
                                to="/"
                                exact
                                className="pad-bottom txt-bold"
                                text="Home"
                            >
                                Home
                            </NavLink>
                        </li>

                        {productCount ? (
                            <li>
                                {productCount > 1 ? (
                                    <NavLink
                                        to="/products"
                                        exact
                                        className="pad-bottom clickable"
                                        text="Shop"
                                    >
                                        Shop
                                    </NavLink>
                                ) : (
                                    <NavLink
                                        to={`/product/${products.list[0].slug}/view`}
                                        exact
                                        className="pad-bottom clickable"
                                        text="About the Product"
                                    >
                                        About the Product
                                    </NavLink>
                                )}
                            </li>
                        ) : (
                            <></>
                        )}

                        <li>
                            <NavLink
                                to="/about"
                                exact
                                className="pad-bottom clickable"
                                text="About us"
                            >
                                About us
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/contact"
                                exact
                                className="pad-bottom clickable"
                                text="Contact Us"
                            >
                                Contact Us
                            </NavLink>
                        </li>
                        {journalOn && (
                            <li>
                                <NavLink
                                    to="/journals"
                                    exact
                                    className="pad-bottom clickable"
                                    text="Journal"
                                >
                                    Journal
                                </NavLink>
                            </li>
                        )}
                        <li>
                            <NavLink
                                to="/faqs"
                                exact
                                className="pad-bottom clickable"
                                text="FAQs"
                            >
                                FAQs
                            </NavLink>
                        </li>
                        {isLoggedIn() ? (
                            <Fragment>
                                <li>
                                    <NavLink
                                        to="/manage-account/personal-data"
                                        className="pad-bottom clickable"
                                        text="Account"
                                    >
                                        <span>Account</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to="/manage-account/order-history"
                                        className="pad-bottom clickable"
                                        text="Orders"
                                    >
                                        <span>Orders</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <a
                                        className="clickable"
                                        onClick={() => logoutUser()}
                                        text="Sign Out"
                                    >
                                        <span>Sign Out</span>
                                    </a>
                                </li>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <li>
                                    <NavLink
                                        className="pad-bottom clickable"
                                        to="/login"
                                        text="Login"
                                    >
                                        <span>Log In</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        className="pad-bottom clickable"
                                        to="registration"
                                        text="Registration"
                                    >
                                        <span>Registration</span>
                                    </NavLink>
                                </li>
                            </Fragment>
                        )}
                    </ul>
                </div>
                {/* <ul className="quick-links margin-top-100 pad-top-30">
                    <li>
                        <a
                            href="https://bigskynation.com/form/privacy-policy"
                            target="_blank"
                        >
                            Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://bigskynation.com/form/privacy-policy"
                            target="_blank"
                        >
                            Terms and Conditions
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://bigskynation.com/#freq-questions"
                            target="_blank"
                        >
                            FAQs
                        </a>
                    </li>
                </ul> */}
            </div>
            <div className="click-out" onClick={toggle} />
        </div>
    );
};

SideBar.propTypes = {
    open: PropTypes.bool,
    toggle: PropTypes.func,
    productCount: PropTypes.number,
    products: PropTypes.object
};

export default SideBar;
