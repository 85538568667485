import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import human_nature from "../../assets/img/human-nature.png";
import lazadaIcon from "../../assets/img/lazada_icon2.png";
import shopeeIcon from "../../assets/img/shopee_icon2.png";
import sliderLowerRight from "../../assets/img/slider-lower-right.png";
import {
    extractAssetObj,
    getPage,
    getPageItemsSeeMore,
    getSiteOption
} from "../../helpers/caboodleHelper";
import Banner from "../_global/Banner/Banner";
import Skeleton from "../_global/Elements/Skeleton";
import TitleLeaf from "../_global/Elements/TitleLeaf";
import FloatingSlider from "../_global/FloatingSlider/FloatingSlider";
import TestimonialSection from "../_global/TestimonialSection/TestimonialSection";
import {
    HomeTopLeaves,
    OurMissionImages,
    Section5Images
} from "./FloatingLeaves";
import axios from "axios";

const Home = ({ page, site_options, location }) => {
    const cancelToken = useRef(null);

    const home = page.home ? page.home : undefined;
    const banners = page.banners ? page.banners : undefined;
    const testimonials = page.testimonials ? page.testimonials : undefined;
    const siteOptions = site_options ? site_options : undefined;

    const [journals, setJournals] = useState([]);

    useEffect(() => {
        cancelToken.current = axios.CancelToken.source();

        getPage("home", cancelToken.current);
        getPage("testimonials", cancelToken.current);
        getPage("banners", cancelToken.current);
        getPageItemsSeeMore("journals", { page_size: 3 }).then(res => {
            if (res.value && res.value.items.length > 0) {
                let items = res.value.items;
                setJournals(items);
            }
        });

        const slug = ["human-nature", "lazada", "shopee"];
        getSiteOption({ slug }, cancelToken.current);

        return () => {
            if (cancelToken.current) cancelToken.current.cancel();
            cancelToken.current = null;
        };
    }, []);

    return (
        <div className="home page-body-container">
            <HomeTopLeaves />

            <Banner
                loading={home == undefined}
                banners={home && home.banners && home.banners.items}
                location={location}
            />

            {home == undefined ? (
                <div className="whats-inside pad-bottom-100 margin-top-100">
                    <div className="container">
                        <TitleLeaf
                            className="txt-header txt-cerapro-bold"
                            loading={true}
                        />
                        <div className="grid grid-2 grid-gap-50-20 pad-bottom-50 position-relative">
                            {[...Array(6)].map((d, idx) => (
                                <div
                                    key={idx}
                                    className="grid grid-2-auto-1fr grid-gap-20"
                                >
                                    <div className="image">
                                        <Skeleton height="150px" clear={true} />
                                    </div>
                                    <div className="flex direction-column justify-center">
                                        <h5 className="txt-medium txt-cerapro-bold txt-primary">
                                            <Skeleton width="100px" />
                                        </h5>

                                        <div>
                                            <Skeleton
                                                className="margin-bottom-10"
                                                width="95%"
                                            />
                                            <Skeleton
                                                className="margin-bottom-10"
                                                width="90%"
                                            />
                                            <Skeleton
                                                className="margin-bottom-10"
                                                width="95%"
                                            />
                                            <Skeleton
                                                className="margin-bottom-10"
                                                width="85%"
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {home.whatsInside && (
                        <div className="whats-inside pad-bottom-100 margin-top-100">
                            <div className="container">
                                <TitleLeaf
                                    className="txt-header txt-cerapro-bold"
                                    title={home.whatsInside.title}
                                />
                                <div className="grid grid-2 grid-gap-50-20 pad-bottom-50 position-relative">
                                    {home.whatsInside.items.map((item, idx) => (
                                        <div
                                            key={idx}
                                            className="grid grid-2-auto-1fr grid-gap-20"
                                        >
                                            <img
                                                src={extractAssetObj(item, [
                                                    "image",
                                                    "path"
                                                ])}
                                                alt={item.title}
                                            />
                                            <div className="flex direction-column justify-center">
                                                <h5 className="txt-medium txt-cerapro-bold txt-primary">
                                                    {item.title}
                                                </h5>

                                                <div
                                                    className="txt-14-px txt-cerapro-regular"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.description
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            {home == undefined ? (
                <div className="our-mission margin-top-50 pad-top-100 bg-green txt-white position-relative">
                    <OurMissionImages />

                    <div className="container z-index-2 position-relative">
                        <h5 className="text-center txt-large txt-light-green txt-cerapro-bold">
                            <Skeleton
                                className="margin-bottom-10"
                                width="100px"
                                center={true}
                            />
                        </h5>
                        <TitleLeaf
                            className="txt-header-2 margin-top-20 txt-cerapro-medium"
                            loading={true}
                        />

                        <div className="description txt-medium text-center txt-cerapro-regular">
                            <Skeleton
                                className="margin-bottom-10"
                                width="90%"
                                center={true}
                            />
                            <Skeleton
                                className="margin-bottom-10"
                                width="80%"
                                center={true}
                            />
                            <Skeleton
                                className="margin-bottom-10"
                                width="90%"
                                center={true}
                            />
                            <Skeleton
                                className="margin-bottom-10"
                                width="75%"
                                center={true}
                            />
                        </div>

                        <div className="flex justify-center margin-top-20 margin-bottom-20">
                            <Skeleton
                                className="margin-bottom-10"
                                width="100px"
                                height="40px"
                                center={true}
                            />
                        </div>
                        <hr />
                    </div>
                </div>
            ) : (
                <>
                    {home.ourMission && (
                        <div className="our-mission margin-top-50 pad-top-100 bg-green txt-white position-relative">
                            <OurMissionImages />

                            <div className="container z-index-2 position-relative">
                                <h5 className="text-center txt-large txt-light-green txt-cerapro-medium">
                                    {home.ourMission.title}
                                </h5>
                                <TitleLeaf
                                    className="txt-header-2 margin-top-20 txt-cerapro-bold"
                                    title={home.ourMission.subTitle}
                                    isLight={true}
                                />

                                <div
                                    className="description txt-medium text-center txt-cerapro-regular"
                                    dangerouslySetInnerHTML={{
                                        __html: home.ourMission.description
                                    }}
                                />
                                <div className="flex justify-center margin-top-20 margin-bottom-20">
                                    <Link
                                        to="/about"
                                        className="btn btn-primary"
                                    >
                                        ABOUT US
                                    </Link>
                                </div>
                                <hr />
                            </div>
                        </div>
                    )}
                </>
            )}

            <FloatingSlider
                data={home && home.ourMission ? home.ourMission : undefined}
                hasBgColor={true}
            />

            <TestimonialSection data={testimonials} />

            {home == undefined ? (
                <div className="section-5 container pad-bottom-50 pad-top-150">
                    <div className="position-relative">
                        <Section5Images />
                        <div className="position-relative">
                            <div className="image-container position-relative">
                                <Skeleton
                                    height="200px"
                                    width="60%"
                                    center={true}
                                />
                            </div>
                            <div className="content-container flex justify-center direction-column">
                                <h2 className="txt-cerapro-bold txt-header-2">
                                    <Skeleton height="25px" />
                                    <Skeleton
                                        height="25px"
                                        className="margin-top-10"
                                    />
                                </h2>
                                <div className="flex align-center justify-around margin-top-20 txt-cerapro-medium">
                                    Also available at
                                    <div className="flex align-center justify-between gap-20">
                                        <Skeleton
                                            className="width-50"
                                            width="50px"
                                            height="30px"
                                        />
                                        <Skeleton
                                            className="width-50"
                                            width="50px"
                                            height="30px"
                                        />
                                        <Skeleton
                                            className="width-50"
                                            width="50px"
                                            height="30px"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    {home.otherShops && (
                        <div className="section-5 container pad-bottom-50 pad-top-150">
                            {/* {console.log(home.otherShops)} */}
                            <div className="position-relative">
                                <Section5Images />
                                <div className="position-relative">
                                    <div className="image-container position-relative">
                                        <img
                                            src={extractAssetObj(
                                                home.otherShops,
                                                ["image", "path"]
                                            )}
                                        />
                                    </div>
                                    <div className="content-container flex justify-center direction-column">
                                        <h2 className="txt-cerapro-bold txt-header-2">
                                            {home.otherShops.title}
                                        </h2>
                                        {(siteOptions.human_nature ||
                                            siteOptions.lazada ||
                                            siteOptions.shopee) && (
                                            <div className="flex align-center justify-around margin-top-20 txt-cerapro-medium available">
                                                <span className="txt-medium no-whitespace margin-right-5">
                                                    Also available at
                                                </span>
                                                <div className="flex align-center justify-between flex-wrap justify-content-center">
                                                    {siteOptions.human_nature && (
                                                        <a
                                                            target="_blank"
                                                            href={
                                                                siteOptions.human_nature
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    human_nature
                                                                }
                                                                style={{height:"16px", margin:"5px 10px"}}
                                                                alt="Human Nature"
                                                            />
                                                        </a>
                                                    )}

                                                    {siteOptions.lazada && (
                                                        <a
                                                            target="_blank"
                                                            href={
                                                                siteOptions.lazada
                                                            }
                                                        >
                                                            <img
                                                                src={lazadaIcon}
                                                                style={{height:"30px", margin:"0 10px"}}
                                                                alt="Lazada"
                                                            />
                                                        </a>
                                                    )}

                                                    {siteOptions.shopee && (
                                                        <a
                                                            target="_blank"
                                                            href={
                                                                siteOptions.shopee
                                                            }
                                                        >
                                                            <img
                                                                src={shopeeIcon}
                                                                style={{height:"30px", margin:"0 10px"}}
                                                                alt="Shopee"
                                                            />
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            {journals.length > 0 && (
                <div className="our-journal container pad-top-50 pad-bottom-100">
                    <TitleLeaf
                        className="txt-header txt-cerapro-bold"
                        title="From Our Journal"
                    />
                    <div className="items pad-top-30">
                        {journals.map((item, index) => (
                            <div key={index} className="item">
                                <img
                                    src={extractAssetObj(item, [
                                        "image",
                                        "path"
                                    ])}
                                />
                                <div>
                                    <h4 className="txt-cerapro-bold txt-large">
                                        {item.title}
                                    </h4>
                                    <div
                                        className="txt-medium txt-cerapro-regular"
                                        dangerouslySetInnerHTML={{
                                            __html: item.blurb
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-center margin-top-20 margin-bottom-20">
                        <Link to="/journals" className="btn btn-primary">
                            SEE ALL
                        </Link>
                    </div>
                </div>
            )}

            {banners && banners.endBanner && (
                <div
                    className="section-7 pad-top-50 pad-bottom-100"
                    style={{
                        backgroundImage: `url(${extractAssetObj(
                            banners.endBanner,
                            ["image", "path"]
                        )})`
                    }}
                >
                    <div className="container flex flex-end direction-column">
                        <div
                            className="txt-upsized txt-cerapro-bold txt-shadow"
                            dangerouslySetInnerHTML={{
                                __html: banners.endBanner.content
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        page: state.page,
        site_options: state.site_options
    };
}

export default connect(mapStateToProps, null)(Home);
