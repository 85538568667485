import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { extractAssetObj, getPage, getSiteOption, getPageItem, getPageItemsSeeMore,  slugify } from "../../helpers/caboodleHelper";
import { NavLink, Link, useHistory } from "react-router-dom";
import Banner from "../_global/Banner/Banner";
import Skeleton from "../_global/Elements/Skeleton";
import TitleLeaf from "../_global/Elements/TitleLeaf";
import { Accordion } from "./Accordion";
import { FaqsUpperLeaves, Section5Images } from "./FloatingLeaves";
import sliderLowerRight from "../../assets/img/slider-lower-right.png";


const Faqs = ({ page, site_options, location, products }) => {
    const cancelToken = useRef(null);
    const faqs = page.faqs ? page.faqs : undefined;
    const banners = page.banners ? page.banners : undefined;
    const siteOptions = site_options ? site_options : undefined;
    const home = page.home ? page.home : undefined;
    
    useEffect(() => {
        cancelToken.current = axios.CancelToken.source();
        
        getPage("banners", cancelToken.current);
        getPage("home", cancelToken.current);
        getPage("faqs", cancelToken.current);
        
        return () => {
            if (cancelToken.current) cancelToken.current.cancel();
            cancelToken.current = null;
        };
    }, []);

    return(
        <div className="faqs page-body-container">
            {banners && banners.faqsBanner && (
                <div
                    className="banner start-section pad-top-50 pad-bottom-50 flex align-center"
                    style={{
                        backgroundImage: `url(${extractAssetObj(
                            banners.faqsBanner,
                            ["image", "path"]
                        )})`
                    }}
                >
                    <div className="container text-center txt-cerapro-bold txt-upsized txt-shadow">
                        {banners.faqsBanner.title}
                    </div>
                </div>
            )}

            {faqs && (
                <div className="container">
                    <FaqsUpperLeaves />
                    <div className="faq-content pad-top-50 pad-bottom-50">
                        {/* {console.log(faqs)} */}
                        {faqs.contents && faqs.contents.length > 0 && 
                            faqs.contents.map((content, index) => (
                            <div 
                                key={index}
                                className="faq-group pad-top-20"
                            >
                                <div className="txt-cerapro-bold txt-xxlarge pad-top-30 pad-bottom-30 with-border-bottom">
                                    {content.title}
                                </div>
                                {content.items && content.items.length > 0 &&
                                    content.items.map((item, index) => (
                                    <Accordion 
                                        key={index}
                                        title={item.title}
                                        content={item.content}
                                    />
                                        
                                ))}
                                
                            </div>

                        ))}
                    </div>
                </div>

            )}
                    
            {home && home.otherShops && (
                
                <div className="section-5 container pad-bottom-100 pad-top-100">
                    <div className="position-relative">
                        <Section5Images />
                        <div className="position-relative">
                            <div className="image-container position-relative">
                                <img
                                    src={extractAssetObj(
                                        home.otherShops,
                                        ["image", "path"]
                                    )}
                                />
                            </div>
                            <div className="content-container flex justify-center direction-column">
                                <h2 className="txt-cerapro-bold margin-bottom-20 txt-header-2">
                                    {home.otherShops.title}
                                </h2>
                                {products &&
                                    products.list &&
                                    products.list.length > 0 && (
                                        <Link
                                            className="txt-cerapro-bold btn-shop text-center"
                                            to={`/product/${products.list[0].slug}/view`}
                                        >
                                            Shop Now
                                        </Link>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {banners && banners.endBanner && (
                <div 
                    className="banner end-section pad-top-50 pad-bottom-50 flex align-center"
                    style={{
                            backgroundImage: `url(${extractAssetObj(
                                banners.endBanner,
                                ["image", "path"]
                            )})`
                        }}
                >
                    <div className="container txt-cerapro-bold txt-upsized">
                        <div className="content margin-bottom-50">
                            <div className="txt-shadow"
                                dangerouslySetInnerHTML={{
                                    __html: banners.endBanner.content
                                }}
                            >
                            </div>
                        </div>
                    </div>
                </div>
            )}
        
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        page: state.page,
        site_options: state.site_options,
        products: state.products
    };
}

export default connect(
    mapStateToProps,
    null
)(Faqs);