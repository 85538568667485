import * as types from "../constants/actionTypes";
import initialState from "./initialState";

export default function(state = initialState.reviews, action) {
    switch (action.type) {
        case types.GET_PRODUCT_REVIEW + types.PENDING:
            return Object.assign({}, state, {
                loading: true
            });
        case types.GET_PRODUCT_REVIEW + types.REJECTED:
            return Object.assign({}, state, {
                loading: false
            });
        case types.UNSELECT_PRODUCT_REVIEW:
            return Object.assign(
                {},
                state,
                {
                    list: []
                },
                {
                    loading: false
                }
            );
        case types.GET_PRODUCT_REVIEW + types.FULFILLED:
            return Object.assign(
                {},
                state,
                {
                    list: [...state.list, ...action.payload.data.reviews.data],
                    next_page_url: action.payload.data.next_page_url,
                    totalCount: action.payload.total_count
                },
                {
                    loading: false
                }
            );
        case types.UPDATE_REVIEW_LIST:
            return Object.assign(
                {},
                state,
                {
                    list: action.payload.list,
                    totalCount: action.payload.totalCount
                },
                {
                    loading: false
                }
            );
        default:
            return state;
    }
}
