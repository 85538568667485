import React from "react";
import axios from "axios";
import {
    extractAssetObj
} from "../../helpers/caboodleHelper";

export const JournalCard = ({ journalPost }) => {
    return (
        <>
            <div className="img-container">
                <img
                    className="image"
                    src={extractAssetObj(journalPost, [
                        "image",
                        "path"
                    ])}
                    alt={journalPost.title}
                />
                <div className="background-overlay" />
                <h5 className="txt-white all-caps on-hover">
                    Read More
                </h5>
            </div>
            <h4 className="title margin-bottom-20 txt-cerapro-bold no-wrap">
                {journalPost.title}
            </h4>
            <h5 className="date txt-gray-med-light all-caps">
                {journalPost.date}
            </h5>
        </>
    );
};
