import * as types from "../constants/actionTypes";
import initialState from "./initialState";

export default function(state = initialState.user, action) {
    let wishlisted;
    let customer = Object.assign({}, state.customer);
    let addresses = Object.assign({}, state.addresses);

    switch (action.type) {
        case types.LOGIN_USER + types.PENDING:
        case types.CONFIRM_USER + types.PENDING:
            return Object.assign(
                {},
                {
                    loading: true
                }
            );
        case types.LOGIN_USER + types.REJECTED:
        case types.CONFIRM_USER + types.REJECTED:
            return {};
        case types.LOGIN_USER + types.FULFILLED:
        case types.CONFIRM_USER + types.FULFILLED:
        case types.CONNECT_SOCIAL_MEDIA + types.FULFILLED:
            // action.payload.data.customer_active_type = action.payload.data.customer.type;
            return Object.assign({}, action.payload.data, {
                loading: false
            });
        case types.UPDATE_USER:
            return Object.assign({}, action.payload, {
                loading: false
            });
        case types.LOAD_WISHLISTED_PRODUCTS + types.PENDING:
            wishlisted =
                state.wishlisted === undefined
                    ? { list: [], loading: true }
                    : Object.assign(state.wishlisted, { loading: true });
            return Object.assign({}, state, {
                wishlisted
            });
        case types.LOAD_WISHLISTED_PRODUCTS + types.REJECTED:
            return Object.assign({}, state, {
                wishlisted: {
                    list: [],
                    loading: false
                }
            });
        case types.LOAD_WISHLISTED_PRODUCTS + types.FULFILLED:
            return Object.assign({}, state, {
                wishlisted: {
                    list: action.payload.data,
                    loading: false
                }
            });
        case types.ADD_TO_WISHLIST + types.FULFILLED:
            wishlisted =
                state.wishlisted === undefined
                    ? { list: [], loading: false }
                    : state.wishlisted;
            wishlisted.list.push(action.payload.data);
            return Object.assign({}, state, {
                wishlisted
            });
        case types.REMOVE_TO_WISHLIST + types.FULFILLED:
            // eslint-disable-next-line no-case-declarations
            let updatedWishlist =
                state.wishlisted === undefined
                    ? []
                    : state.wishlisted.list.splice(0);
            updatedWishlist = updatedWishlist.filter(item => {
                return item.product_id != action.payload.data.product_id;
            });

            return Object.assign({}, state, {
                wishlisted: Object.assign({}, state.wishlisted, {
                    list: updatedWishlist
                })
            });
        case types.GET_CUSTOMER_ADDRESS + types.REJECTED:
            return Object.assign({}, state, {
                addresses: {
                    list: [],
                    loading: false
                }
            });
        case types.GET_CUSTOMER_ADDRESS + types.FULFILLED:
            return Object.assign({}, state, {
                addresses: {
                    list: action.payload.data,
                    loading: false
                }
            });
        case types.SET_CUSTOMER_PRIMARY_ADDRESS + types.FULFILLED:
            customer = assignDefaultAddress(customer, action.payload.data);

            return Object.assign({}, state, {
                customer
            });
        // case types.CREATE_CUSTOMER_ADDRESS + types.FULFILLED:
        //     if (state.addresses && state.addresses.list) {
        //         addresses.list.push(action.payload.data);
        //     } else {
        //         addresses.list = [action.payload.data];
        //     }

        //     customer = assignDefaultAddress(customer, action.payload.data);

        //     return Object.assign({}, state, {
        //         // addresses,
        //         customer
        //     });
        // case types.UPDATE_CUSTOMER_ADDRESS + types.FULFILLED:
        //     if (state.addresses && state.addresses.list) {
        //         addresses.list.find((searchAddress, index) => {
        //             if (searchAddress.id === action.payload.data.id) {
        //                 addresses.list[index] = action.payload.data;
        //                 return true;
        //             }
        //         });
        //     } else {
        //         addresses.list = [action.payload.data];
        //     }

        //     customer = assignDefaultAddress(customer, action.payload.data);

        //     return Object.assign({}, state, {
        //         // addresses,
        //         customer
        //     });
        case types.DELETE_CUSTOMER_ADDRESS + types.FULFILLED:
            addresses.list = addresses.list.filter(address => {
                return address.id != action.payload.id;
            });

            if (action.payload.new_primary_address) {
                customer = assignDefaultAddress(
                    customer,
                    action.payload.new_primary_address
                );
            }

            return Object.assign({}, state, {
                // addresses,
                customer
            });
        case types.GET_USER_CREDITS + types.FULFILLED:
            customer.credits = action.payload.user_total_credits;

            return Object.assign({}, state, {
                customer,
                credits_history: action.payload.credits
            });
        case types.LOGOUT_USER:
            return {};
        default:
            return state;
    }
}

function assignDefaultAddress(customer, data) {
    if (data.address_type === "shipping" || !data.address_type) {
        customer.shipping_address_id = data.hasOwnProperty("id") ? data.id : 0;
        customer.primary_address = data.hasOwnProperty("id") ? data : null;
    }

    if (data.address_type === "billing") {
        customer.billing_address_id = data.hasOwnProperty("id") ? data.id : 0;
        customer.billing_address = data.hasOwnProperty("id") ? data : null;
    }

    return customer;
}
