import React from "react";
import { connect } from "react-redux";

import History from "../../History";

import SuccessMessage from "./Modal/SuccessMessage";
import EllipsisLoader from "./Loaders/EllipsisLoader";

import { toggleModal, setModalContent } from "../../helpers/caboodleHelper";
import { activateAccount } from "../../actions/customer.actions";

class UserCartActivation extends React.Component {
    state = {
        loading: true
    };

    componentWillMount() {}

    componentDidMount() {
        const confirmationToken = this.props.location.search.split("=")[1];

        if (this.props.location.search.split("=")[0].indexOf("ut") > -1) {
            activateAccount(confirmationToken)
                .then(res => {
                    const confimartionEl = (
                        <SuccessMessage
                            title="Welcome to Apicuria!"
                            message="Your cart is waiting you."
                        />
                    );

                    setModalContent(false, false, confimartionEl);
                    toggleModal();
                    History.push("/");
                })
                .catch(err => {
                    const confimartionEl = (
                        <SuccessMessage
                            title="Oops! Something went wrong"
                            message={err.data.message}
                        />
                    );

                    setModalContent(false, false, confimartionEl);
                    toggleModal();
                    History.push("/");
                });
        } else {
            History.push("/");
        }
    }

    componentDidUpdate(oldProps) {}

    render() {
        return (
            <div className="change-password-form">
                <div className="text-center">
                    <div className="txt-header">Account Activation</div>
                    <div className="margin-top-10 txt-large margin-bottom-30">
                        Please wait a moment while we activate your account.
                    </div>
                    <EllipsisLoader dot="black" />
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        user: state.user
    };
}

export default connect(
    mapStateToProps,
    null
)(UserCartActivation);
