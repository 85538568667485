import { checkoutCart, validateCoupon } from "../actions/checkout.actions";
import * as types from "../constants/actionTypes";
import { toggleWholePageLoader } from "../helpers/caboodleHelper";
import { store } from "../index";
export default class CheckoutFactory {
    constructor({
        order = {},
        cart = {},
        user = {},
        shipping_zone = {},
        shippingMethods = {},
        address_form = {},
        coupon = {}
    }) {
        this.order = order;
        this.cart = cart;
        this.user = user;
        this.shipping_zone = shipping_zone;
        this.shippingMethods = shippingMethods;
        this.address_form = address_form;
        this.coupon = coupon;
    }

    getSubtotal() {
        const order = this.order;
        let subtotal = parseFloat(order.subtotal);

        return +parseFloat(subtotal).toFixed(2);
    }

    getTotal(withShipping = true) {
        const order = this.order;
        let total = parseFloat(order.subtotal),
            shippingFee = this.getShippingFee();

        if (withShipping && shippingFee && !this.getCouponWaveShipping()) {
            total += parseFloat(shippingFee);
        }

        total -= this.getCouponDiscount();

        return +parseFloat(total).toFixed(2);
    }

    isCouponValid() {
        const coupon = this.coupon;
        let isValid = false;

        if (
            Object.keys(coupon).length > 0 &&
            !coupon.claimed &&
            coupon.valid &&
            coupon.exist
        ) {
            isValid = true;
        }

        return isValid;
    }

    getCouponDiscount() {
        const coupon = this.coupon;
        let discount = 0;

        if (!coupon || !coupon.hasOwnProperty("data")) return discount;

        if (!coupon.claimed && coupon.valid) {
            let value = parseFloat(coupon.data.value);
            if (coupon.data.value_type === "fixed") discount = value;
            else {
                discount = this.getSubtotal() * (value / 100);
            }
            discount = parseFloat(discount).toFixed(2);
        }

        return discount;
    }

    getCouponWaveShipping() {
        const coupon = this.coupon;
        const address_form = this.address_form;
        let waveShipping = false;

        if (this.isCouponValid()) {
            let province_id =
                address_form &&
                address_form.shipping &&
                address_form.shipping.province_id;

            if (
                province_id &&
                province_id >= 48 &&
                province_id <= 64 &&
                coupon.data.coupon_group.wave_manila
            ) {
                waveShipping = true;
            } else if (
                province_id &&
                (province_id < 48 || province_id > 64) &&
                coupon.data.coupon_group.wave_non_manila
            ) {
                waveShipping = true;
            }

            if (this.getShippingFee() <= 0) {
                waveShipping = false;
            }
        }

        return waveShipping;
    }

    getItemSubtotal() {
        const order = this.order;
        let itemSubtotal = 0;

        order.items.map(item => {
            itemSubtotal += parseFloat(item.subtotal);
        });

        return itemSubtotal;
    }

    getItemsTotalWeight() {
        const order = this.order;
        let totalWeight = 0;
        order.items.map(item => {
            totalWeight +=
                parseFloat(item.product.weight) * parseInt(item.quantity);
        });

        return totalWeight;
    }

    getShippingRate() {
        const order = this.order;
        const shipping_zone = this.shipping_zone;
        const address_form = this.address_form;

        if (
            !order ||
            !order.items ||
            order.items.length <= 0 ||
            !address_form ||
            !address_form.shipping ||
            address_form.shipping.id === 0
        ) {
            return;
        }

        let province_id = address_form.shipping.province_id;

        const selected_shipping_zone =
            shipping_zone.list &&
            shipping_zone.list.find(zone => {
                return zone.shipping_zone_country.shipping_zone_province.find(
                    zone_province => {
                        //eslint-disable-next-line
                        return zone_province.province_id == province_id;
                    }
                );
            });

        let total_price = this.getItemSubtotal();
        let total_weight = this.getItemsTotalWeight();

        let shipping_rate = {
            weight: undefined,
            price: undefined
        };

        selected_shipping_zone &&
            selected_shipping_zone.shipping_rates.map(rate => {
                if (rate.type === "Weight") {
                    if (
                        parseFloat(total_weight) >= parseFloat(rate.minimum) &&
                        (parseFloat(total_weight) <= parseFloat(rate.maximum) ||
                            parseFloat(rate.maximum) === 0)
                    ) {
                        shipping_rate = Object.assign({}, rate, {
                            price: rate.amount,
                            zone: selected_shipping_zone.shipping_zone_country
                        });
                    }
                } else {
                    if (
                        parseFloat(total_price) >= parseFloat(rate.minimum) &&
                        (parseFloat(total_price) <= parseFloat(rate.maximum) ||
                            parseFloat(rate.maximum) === 0)
                    ) {
                        shipping_rate = Object.assign({}, rate, {
                            price: rate.amount,
                            zone: selected_shipping_zone.shipping_zone_country
                        });
                    }
                }
            });

        return shipping_rate;
    }

    getOnTopShippingMethod(shippingMethod) {
        let methodPrice = false;
        let shippingRate = this.getShippingRate();

        if (shippingRate && shippingMethod) {
            if (shippingMethod.type == "on top") {
                methodPrice = shippingRate.price + shippingMethod.price;
            } else {
                methodPrice = shippingMethod.price;
            }
            methodPrice = parseFloat(methodPrice).toFixed(2);
        }

        return methodPrice;
    }

    getShippingFee() {
        let shippingMethods = this.shippingMethods;
        let shippingFee = false;

        if (shippingMethods && shippingMethods.selected) {
            shippingFee = this.getOnTopShippingMethod(shippingMethods.selected);
            shippingFee = parseFloat(shippingFee).toFixed(2);
        }

        return shippingFee;
    }

    updateOrder() {
        const order = this.order;
        const cart = this.cart;
        const user = this.user;

        const items = cart.items;

        let params = {
            items
        };

        if (user.hasOwnProperty("name")) {
            params = Object.assign({}, params, {
                token: user.token
            });
        }

        if (order && order.reference_number) {
            params = Object.assign({}, params, {
                reference_number: order.reference_number,
                delivery_option: order.delivery_option,
                pickup_location: order.pickup_location
            });
        }

        let formData = new FormData();
        params.items = JSON.stringify(params.items);

        Object.keys(params).map(key => {
            formData.append(key, params[key]);
        });

        checkoutCart(formData).then(res => {
            toggleWholePageLoader(false);
        });
    }

    syncOrderToCart() {
        const order = this.order;
        let orderItems = order.items;

        let cartItems = [];
        orderItems.map(item => {
            let newItem;
            if (item.type === "giftcard") {
                newItem = {
                    gift_card: {
                        denomination: item.price,
                        price: item.price,
                        quantity: item.quantity,
                        send_to: item.notes,
                        currency: item.order.currency
                    },
                    giftcard: 1,
                    quantity: item.quantity
                };
            } else if (item.type === "bundle") {
                newItem = {
                    bundleObj: item.bundle,
                    bundle: 1,
                    included_products: item.included_products,
                    quantity: item.quantity
                };
            } else {
                newItem = {
                    product: item.product,
                    product_id: item.product.id,
                    quantity: item.quantity,
                    replacement: {
                        type: item.replacement_type,
                        customer_reference: item.customer_reference_id
                    }
                };
            }

            cartItems.push(newItem);
        });

        return store.dispatch({
            type: types.SYNC_ORDER_TO_CART,
            payload: { items: cartItems }
        });
    }
}
