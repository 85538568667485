import React from "react";
import PropTypes from "prop-types";
import textLeafLight from "../../../assets/img/text-leaf-light.png";
import textLeaf from "../../../assets/img/text-leaf.png";
import Skeleton from "./Skeleton";

const TitleLeaf = ({
    className = "",
    title,
    isLight = false,
    loading = false
}) => {
    return (
        <div className="pad-bottom-50">
            <h2 className={`text-center with-leaf ${className}`}>
                {loading ? (
                    <Skeleton center={true} height="30px" width="300px" />
                ) : (
                    title
                )}
                <img src={isLight ? textLeafLight : textLeaf} alt="leaf" />
            </h2>
        </div>
    );
};

TitleLeaf.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    isLight: PropTypes.bool,
    loading: PropTypes.bool
};

export default TitleLeaf;
