import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { Link } from "react-router-dom";
import { placeOrder, validateCoupon } from "../../actions/checkout.actions";
import { getShippingMethods } from "../../actions/shippingMethods.actions";
import CheckoutFactory from "../../factories/CheckoutFactory";
import UserFactory from "../../factories/UserFactory";
import {
    encryptCheckout,
    getBanks,
    getShippingZonesList,
    toggleWholePageLoader
} from "../../helpers/caboodleHelper";
import History from "../../History";
import AddressForm from "../_global/AddressForm/AddressForm";
import CheckoutSkeleton from "./components/CheckoutSkeleton";
import OrderSummary from "./components/OrderSummary";
import Payment from "./components/Payment";
import Shipping from "./components/Shipping";

const Checkout = ({
    location,
    order,
    cart,
    user,
    shipping_zone,
    shippingMethods,
    address_form
}) => {
    const cancelToken = useRef(null);
    const shippingForm = useRef(null);

    const match = matchPath(location.pathname, {
        path: "/checkout/:slug",
        exact: true,
        strict: false
    });

    const uriSlug = match.params.slug;

    const validSlug = ["information", "shipping", "payment"];

    const [couponCode, setCouponCode] = useState();
    const [coupon, setCoupon] = useState({});
    const [hasGcash, setHasGcash] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
        "bank-deposit"
    );

    const checkoutFactory = new CheckoutFactory({
        order,
        cart,
        user,
        shipping_zone,
        shippingMethods,
        address_form,
        coupon
    });

    const userFactory = new UserFactory({
        user,
        shipping_zone,
        address_form
    });

    useEffect(() => {
        cancelToken.current = axios.CancelToken.source();
        getShippingZonesList(cancelToken);
        getBanks().then(res => {
            if (res.data && res.data.length > 0) {
                let gcash = res.data.find(
                    bank => bank.bank_name.toLowerCase().indexOf("gcash") > -1
                );
                if (gcash) {
                    setHasGcash(true);
                }
            }
        });

        if (cart.items.length > 0) {
            // toggleWholePageLoader(true);
            checkoutFactory.updateOrder();
        }

        return () => {
            if (cancelToken.current) cancelToken.current.cancel();
            cancelToken.current = null;
        };
    }, []);

    useEffect(() => {
        if (uriSlug == "shipping") {
            toggleWholePageLoader(true);
            getShippingMethods().then(res => {
                toggleWholePageLoader(false);
            });
        }
    }, [location]);

    useEffect(() => {
        if (order.hasOwnProperty("id")) {
            checkoutFactory.syncOrderToCart();
        }
    }, [order]);

    if (!order.hasOwnProperty("id")) {
        return <CheckoutSkeleton />;
    }

    const proceedToNextStep = (payment) => {
        if (uriSlug == "information") {
            // SUBMIT FROM SHIPPINGFORM AS REF
            toggleWholePageLoader(true);
            shippingForm.current.submitForm().then(res => {
                if (!res.status) {
                    toggleWholePageLoader(false);
                    return;
                }

                res.form.type = "shipping";
                res.form.address_type = "shipping";
                userFactory.updateAddress(res.form).then(res => {
                    History.push("/checkout/shipping");
                });
            });
        }

        if (uriSlug == "shipping") {
            History.push("/checkout/payment");
        }

        if (uriSlug == "payment") {
            handleOrderPlace(payment);
        }
    };

    const handleOrderPlace = (paymentStatus) => {
        let shipping_rate = checkoutFactory.getShippingRate(),
            payment_type,
            customer_id = user && user.customer ? user.customer.id : 0,
            coupon_id =
                coupon && coupon.data && coupon.data.id ? coupon.data.id : 0;

        let params = {
            id: order.id,
            billing_address: address_form.billing.id,
            shipping_address: address_form.shipping.id,
            shipping_rate_id: shipping_rate.id ? shipping_rate.id : 0,
            delivery_option: order.delivery_option,
            pickup_location: order.pickup_location,
            delivery_type_id: shippingMethods.selected.id,
            payment_type: selectedPaymentMethod,
            customer_id,
            coupon_id,
            currency_id: order.currency.id,
            paymentStatus: paymentStatus,
            email_address: address_form.shipping.email_address,
            customer_active_type: user.hasOwnProperty("name")
                ? user.customer_active_type
                : "regular"
        };
        console.log(params);

        params = { d: encryptCheckout(params) };

        placeOrder(params)
            .then(res => {
                if (res.value.message === "Successfully placed order.")
                    History.push("/order/success");
            })
            .catch(err => {})
            .finally(() => toggleWholePageLoader(false));
    };

    const handleCouponApply = () => {
        if (!couponCode) return setCoupon({});
        toggleWholePageLoader(true);

        validateCoupon({ code: couponCode, checkout_token: order.token })
            .then(res => {
                setCoupon(res);
            })
            .catch(err => {
                setCoupon(err.data);
            })
            .finally(() => toggleWholePageLoader(false));
    };

    const cancelCoupon = () => {
        setCouponCode("");
        setCoupon({});
    };

    const breadcrumbs = ["product page", "information", "shipping", "payment"];

    return (
        <div className="checkout-page pad-bottom-100">
            <div className="container">
                <div className="breadcrumbs margin-top-30 margin-bottom-30 txt-medium">
                    {breadcrumbs.map((item, idx) => (
                        <Fragment key={idx}>
                            <span
                                className={`text-capitalize mr-2 ${
                                    uriSlug == item ? "active" : ""
                                }`}
                            >
                                {item}
                            </span>
                            {breadcrumbs.length - 1 > idx && (
                                <i className="far fa-angle-right mr-2" />
                            )}
                        </Fragment>
                    ))}
                </div>
                <h1 className="txt-header-2 txt-cerapro-bold text-capitalize txt-header">
                    {uriSlug}
                </h1>
                <div className="row payment-row">
                    <div className="col-md-8">
                        {uriSlug === "information" ? (
                            <div className="information margin-top-30">
                                <h5 className="txt-14-px txt-cerapro-medium">
                                    Shipping address
                                </h5>
                                <AddressForm
                                    type="shipping"
                                    ref={shippingForm}
                                />
                                <div className="margin-top-30 pb-3 d-flex justify-content-end">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => proceedToNextStep()}
                                    >
                                        Continue to Shipping
                                    </button>
                                </div>
                            </div>
                        ) : null}

                        {uriSlug === "shipping" ? (
                            <Shipping
                                proceedToNextStep={proceedToNextStep}
                                checkoutFactory={checkoutFactory}
                                order={order}
                            />
                        ) : null}

                        {uriSlug === "payment" ? (
                            <Payment
                                proceedToNextStep={proceedToNextStep}
                                userFactory={userFactory}
                                checkoutFactory={checkoutFactory}
                                selectedPaymentMethod={selectedPaymentMethod}
                                setSelectedPaymentMethod={
                                    setSelectedPaymentMethod
                                }
                                hasGcash={hasGcash}
                            />
                        ) : null}
                    </div>
                    <div className="col-md-4">
                        <OrderSummary
                            checkoutFactory={checkoutFactory}
                            uriSlug={uriSlug}
                            couponCode={couponCode}
                            coupon={coupon}
                            setCouponCode={setCouponCode}
                            handleCouponApply={handleCouponApply}
                            cancelCoupon={cancelCoupon}
                            handleCouponApply={handleCouponApply}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        cart: state.cart,
        order: state.order,
        shippingMethods: state.shippingMethods,
        address_form: state.address_form,
        shipping_zone: state.shipping_zone
    };
}

export default connect(mapStateToProps, null)(Checkout);
