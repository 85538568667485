import { isNumber } from "lodash";
import React, { useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
    requestUpdate,
    updateCustomerDetails,
    updatePassword
} from "../../actions/customer.actions";
import AddressForm from "../_global/AddressForm/AddressForm";
import TextInput from "../_global/Form/TextInput";

const PersonalData = ({ user }) => {
    const [isChangeOn, setIsChangeOn] = useState(false);
    const MySwal = withReactContent(Swal);
    const customer = user.customer;
    const initialForm = {
        firstname: customer.firstname,
        lastname: customer.lastname,
        phone: customer.phone,
        gender: customer.gender ? customer.gender : "male",
        birthday: customer.birthday !== "0000-00-00" ? customer.birthday : "",
        email: customer.email
    };

    const initialPasswordForm = {
        current_password: "",
        new_password: "",
        confirm_password: ""
    };

    const formSchema = {
        firstname: "required",
        lastname: "required",
        phone: "required",
        gender: "required",
        birthday: "required"
    };

    const [form, setForm] = useState(initialForm);
    const [formErrors, setFormErrors] = useState({});
    const [isFormChanged, setIsFormChanged] = useState(false);
    const [passwordForm, setPasswordForm] = useState(initialPasswordForm);
    const [isPasswordFormChanged, setIsPasswordFormChanged] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);

    const handleFormChange = e => {
        let newForm = { ...form, [e.target.name]: e.target.value };
        setForm(newForm);
        if (JSON.stringify(initialForm) !== JSON.stringify(newForm)) {
            setIsFormChanged(true);
        } else {
            setIsFormChanged(false);
        }
    };

    const handlePasswordFormChange = e => {
        let newPasswordForm = {
            ...passwordForm,
            [e.target.name]: e.target.value
        };
        setPasswordForm(newPasswordForm);

        if (
            JSON.stringify(initialPasswordForm) !==
            JSON.stringify(newPasswordForm)
        ) {
            setIsPasswordFormChanged(true);
        } else {
            setIsPasswordFormChanged(false);
        }
    };

    const handleFormUpdate = () => {
        if (!isFormValid()) return;
        setIsUpdating(true);

        requestUpdate(form)
            .then(res => {
                setIsChangeOn(false);
                updateCustomerDetails({
                    ...user,
                    name: `${form.firstname} ${form.lastname}`,
                    customer: {
                        ...customer,
                        ...form,
                        fullname: `${form.firstname} ${form.lastname}`
                    }
                });

                MySwal.fire({
                    title: <p>Update Successful</p>,
                    icon: "success",
                    timer: 2000,
                    timerProgressBar: true
                });

                setIsFormChanged(false);
            })
            .finally(() => setIsUpdating(false));
    };

    const handlePasswordFormUpdate = () => {
        if (!isPasswordFormValid()) return;
        setIsUpdating(true);

        updatePassword(passwordForm)
            .then(res => {
                MySwal.fire({
                    title: <p>Update Successful</p>,
                    icon: "success",
                    timer: 2000,
                    timerProgressBar: true
                });
                setIsPasswordFormChanged(false);
                setPasswordForm(initialPasswordForm);
            })
            .catch(res => {
                if (
                    res.data.message ==
                    "Failed to update password current password is not correct."
                ) {
                    setFormErrors(prevError => ({
                        ...prevError,
                        current_password: "Current password is incorrect."
                    }));
                }
            })
            .finally(() => setIsUpdating(false));
    };

    const isFormValid = () => {
        let isValid = true;
        if (isFormChanged) {
            Object.keys(form).map(key => {
                if (formSchema[key] === "required" && form[key] === "") {
                    setFormErrors(prevError => ({
                        ...prevError,
                        [key]: "This field is required."
                    }));
                    isValid = false;
                } else if (
                    (key === "phone" || key === "mobile") &&
                    (isNaN(parseInt(form[key])) || form[key].length !== 11)
                ) {
                    setFormErrors(prevError => ({
                        ...prevError,
                        [key]: "Invalid mobile number. Ex. 09123456789"
                    }));
                    isValid = false;
                } else {
                    setFormErrors(prevError => ({
                        ...prevError,
                        [key]: ""
                    }));
                }
            });
        }

        return isValid;
    };

    const isPasswordFormValid = () => {
        let isValid = true;
        if (isPasswordFormChanged) {
            Object.keys(passwordForm).map(key => {
                if (
                    JSON.stringify(initialPasswordForm) !==
                    JSON.stringify(passwordForm)
                ) {
                    if (
                        key === "confirm_password" &&
                        passwordForm["confirm_password"] !==
                            passwordForm["new_password"]
                    ) {
                        setFormErrors(prevError => ({
                            ...prevError,
                            [key]: "New password doesn't match."
                        }));
                        isValid = false;
                    } else if (
                        key === "new_password" &&
                        passwordForm[key] === ""
                    ) {
                        setFormErrors(prevError => ({
                            ...prevError,
                            [key]: "New password is required."
                        }));
                        isValid = false;
                    } else if (
                        key === "current_password" &&
                        passwordForm[key] === ""
                    ) {
                        setFormErrors(prevError => ({
                            ...prevError,
                            [key]: "Current password is required."
                        }));
                        isValid = false;
                    } else if (
                        key === "new_password" &&
                        passwordForm["current_password"] ===
                            passwordForm["new_password"]
                    ) {
                        setFormErrors(prevError => ({
                            ...prevError,
                            [key]: "Current and new password must be different."
                        }));
                        isValid = false;
                    } else {
                        setFormErrors(prevError => ({
                            ...prevError,
                            [key]: ""
                        }));
                    }
                } else {
                    setFormErrors(prevError => ({
                        ...prevError,
                        [key]: ""
                    }));
                }
            });
        }

        return isValid;
    };

    return (
        <div className="p-5 personal-data-page">
            <h1 className="mb-4">Edit Account</h1>
            <div className="change-toggle">
                <TextInput
                    disabled={!isChangeOn}
                    className="margin-bottom-10"
                    name="firstname"
                    placeholder="First Name"
                    onChange={handleFormChange}
                    value={form.firstname}
                    error={formErrors.firstname}
                />
                {!isChangeOn && (
                    <button onClick={() => setIsChangeOn(true)}>
                        Change <i className="far fa-pen" />
                    </button>
                )}
            </div>
            <TextInput
                disabled={!isChangeOn}
                className="margin-bottom-10"
                name="lastname"
                placeholder="Last Name"
                onChange={handleFormChange}
                value={form.lastname}
                error={formErrors.lastname}
            />
            <TextInput
                disabled={!isChangeOn}
                className="margin-bottom-10"
                name="phone"
                placeholder="Mobile"
                onChange={handleFormChange}
                value={form.phone}
                error={formErrors.phone}
            />
            <TextInput
                disabled={!isChangeOn}
                type="select"
                className="margin-bottom-10"
                name="gender"
                onChange={handleFormChange}
                value={form.gender}
            >
                <option value="male">Male</option>
                <option value="female">Female</option>
            </TextInput>
            <TextInput
                disabled={!isChangeOn}
                type="date"
                name="birthday"
                onChange={handleFormChange}
                value={form.birthday}
                error={formErrors.birthday}
            />
            <div className="row mb-3">
                <div className="col-4">
                    <button
                        onClick={() => handleFormUpdate()}
                        className="btn btn-primary btn-block margin-top-20"
                        disabled={!isFormChanged || isUpdating}
                    >
                        Update
                    </button>
                </div>
            </div>

            <h4 className="txt-17-px txt-cerapro-bold mb-3 mt-5">
                Change Password
            </h4>
            <TextInput
                type="password"
                className="margin-bottom-10"
                name="current_password"
                placeholder="Current Password"
                onChange={handlePasswordFormChange}
                value={passwordForm.current_password}
                error={formErrors.current_password}
            />
            <TextInput
                type="password"
                className="margin-bottom-10"
                name="new_password"
                placeholder="New Password"
                onChange={handlePasswordFormChange}
                value={passwordForm.new_password}
                error={formErrors.new_password}
            />
            <TextInput
                type="password"
                name="confirm_password"
                placeholder="Confirm New Password"
                onChange={handlePasswordFormChange}
                value={passwordForm.confirm_password}
                error={formErrors.confirm_password}
            />

            <div className="row">
                <div className="col-4">
                    <button
                        onClick={() => handlePasswordFormUpdate()}
                        className="btn btn-primary btn-block margin-top-20"
                        disabled={!isPasswordFormChanged || isUpdating}
                    >
                        Update
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(PersonalData);
