import React from 'react';
import {connect} from 'react-redux';
import ScrollMagic from "scrollmagic";
import { isEqual } from "lodash";

import {
    getCategoryTree,
    extractAssetObj
} from '../../../helpers/caboodleHelper';

import {ASSET_URL} from '../../../constants/config';
import sampleIcon from '../../../assets/img/sampleicon.png';

class CategoryFilter extends React.Component {
    state = {
        selectedParent: 0,
        selectedCategory: 0,
        selectedSubCategory: 0,
        collapseHeight: 0,
        showMobileSelection: false,
        mSelected: false
    }

    smController = new ScrollMagic.Controller();

    componentDidMount() {
        getCategoryTree();

        if (this.props.categories.selected) {
            this.handleSelectCategory(this.props.categories.selected);
        }
    }

    componentDidUpdate(oldProps) {
        const oldCat = oldProps.categories && oldProps.categories.selected;
        const newCat = this.props.categories && this.props.categories.selected;
        
        if ((!oldCat && newCat) || ((oldCat && newCat) && !isEqual(newCat, oldCat))) {
            this.handleSelectCategory(newCat);
        }

        if (!isEqual(newCat, oldCat) && !newCat) {
            this.handleSelectCategory(false);
        }
    }

    componentWillUnmount() {
    }

    render() {
        const {
                categories
            } = this.props,
            selected = this.state.mSelected;

        return (
            <div className="category-filter-container">
                {
                    categories && categories.list && categories.list.length > 0 && categories.list.map(category => {
                        return (
                            <div className={`category-filter gocery-filter margin-right-50 margin-bottom-30 ${!category.color && category.title.toLowerCase().split(" ")[0]}`}>
                                <div
                                    className="bg-color-overlay"
                                    style={
                                        category.color ? {background: category.color} : {}
                                    }
                                />
                                <div className="relative-container">
                                    <button
                                        className="category-header flex align-center pad-30 txt-gray-dark"
                                        onClick={() => document.body.clientWidth > 991 ? (this.props.onSelect ? this.props.onSelect(category) : this.handleSelectCategory(category)) : this.setState({showMobileSelection: true, mSelected: category})}
                                    >
                                        <img
                                            src={category.icon_path ? extractAssetObj(category, ["icon_path"]) : sampleIcon}
                                            className="margin-right-10"    
                                        />
                                        <h1 className="txt-xxlarge txt-bold-qs-bold margin-0 flex-1">
                                            {category.title}
                                        </h1>
                                        {
                                            category.children.length > 0 ? <i className="far fa-chevron-down margin-left-10"/> : null
                                        }
                                    </button>
                                    <div
                                        className="children-container"
                                        style={{
                                            maxHeight: this.state.selectedParent === category.id ? ((this.state.collapseHeight + 50) + "px") : 0,
                                            paddingBottom: (this.state.selectedParent === category.id && category.children.length > 0) ? "50px" : 0
                                        }}
                                    >
                                        <div id={`collapsableTarget${category.id}`}>
                                            {
                                                category.children.length > 0 && category.children.map(catChild => {
                                                    return (
                                                        <div className={`pad-left-30 pad-right-30 margin-bottom-20`}>
                                                            <button
                                                                className={`btn btn-nostyles no-transition`}
                                                                onClick={() => this.props.onSelect ? this.props.onSelect(category, catChild) : this.handleSelectCategory(category, catChild)}
                                                            >
                                                                <div
                                                                    className={`subcat-header ${(categories.selected && (categories.selected.id === catChild.id)) || this.state.selectedSubCategory === catChild.id ? 'active-subcat' : ''}`}
                                                                >
                                                                    <div className="indicator" style={{"--tooltip-color": category.color ? category.color : "#009EE2"}}/>
                                                                    <h1
                                                                        className={`txt-large reg-header txt-gray-dark pad-left-30 margin-0`}
                                                                    >
                                                                        {catChild.title}
                                                                    </h1>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {
                    selected && (
                        <div className={`category-m-selector flex direction-column ${this.state.showMobileSelection ? 'show' : ''}`}>
                            <div className="flex justify-center">
                                <div className="flex align-center header pad-20">
                                    <img src={selected.icon_path ? (ASSET_URL + "/" + selected.icon_path) : sampleIcon} />
                                    <div className="txt-xxlarge txt-bold">
                                        {selected.title}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="clickable txt-xlarge txt-white cat-m-option text-center"
                                style={{
                                    backgroundImage: selected.asset ? `url(${extractAssetObj(selected, ["asset", "path"])})` : 'none'
                                }}
                                onClick={() => this.props.onSelect ? this.props.onSelect(selected) : this.handleSelectCategory(selected)}
                            >
                                <div className="pad-20">
                                    All
                                </div>
                            </div>
                            <div
                                className="overflow-options flex-1"
                                style={{background: selected.color ? selected.color: "#fff"}}
                            >
                                <div className="grid grid-1">
                                    {
                                        selected.children.length > 0 && selected.children.map(catChild => {
                                            return (
                                                <div
                                                    className="clickable txt-xlarge txt-white cat-m-option text-center"
                                                    style={{
                                                        backgroundImage: catChild.asset ? `url(${extractAssetObj(catChild, ["asset", "path"])})` : 'none'
                                                    }}
                                                    onClick={() => this.props.onSelect ? this.props.onSelect(selected, catChild) : this.handleSelectCategory(selected, catChild)}
                                                >
                                                    <div className="pad-20">
                                                        {catChild.title}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div
                                className="clickable txt-xlarge cat-m-option text-center pad-20"
                                style={{
                                    background: "#fff"
                                }}
                                onClick={() => this.setState({showMobileSelection: false})}
                            >
                                Cancel
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

    handleSelectCategory = (category, subcat) => {
        const collapseTarget = document.getElementById(`collapsableTarget${category.parent === 0 ? category.id : category.parent}`);

        this.setState({
            selectedParent: category ? (category.parent === 0 ? category.id : category.parent) : 0,
            selectedCategory: category ? category.id : 0,
            selectedSubCategory: subcat ? subcat.id : 0,
            collapseHeight: category && collapseTarget ? collapseTarget.clientHeight : 0,
            showMobileSelection: false
        }, () => {
            if (this.props.callback && category) {
                this.props.callback(subcat ? subcat : category);
            }
        });
    }


}

function mapStateToProps(state, ownProps) {
	return {
        categories: state.categories
	};
}

export default connect(mapStateToProps, null)(CategoryFilter);
