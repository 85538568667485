// import * as apiHelper from '../helpers/apiHelper';
import { store } from "..";
import * as types from "../constants/actionTypes";
import * as apiHelper from "../helpers/apiHelper";
import { getUser } from "../helpers/sessionHelper";

export function createCustomerAddress(params) {
    const result = apiHelper
        .connect(false)
        .post(`/customers/create-address`, params);

    return store.dispatch({
        type: types.GET_ADDRESS,
        payload: result
    });
}

export function updateCustomerAddress(params) {
    const result = apiHelper
        .connect(false)
        .post(`/customers/update-address`, params);

    return store.dispatch({
        type: types.GET_ADDRESS,
        payload: result
    });
}

export function setSelectedBilling(type) {
    return store.dispatch({
        type: types.SET_SELECTED_BILLING,
        payload: { type }
    });
}

export function setCustomerAddressToAddressForm() {
    let data = getUser();

    return store.dispatch({
        type: types.SET_CUSTOMER_ADDRESS_TO_ADDRESS_FORM,
        payload: data
    });
}
