import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.block_off_dates, action) {
    switch(action.type) {
        case types.GET_BLOCK_OFF_DATES + types.PENDING: {
            return Object.assign({}, state, {
                loading: true
            });
        }
        case types.GET_BLOCK_OFF_DATES + types.REJECTED: {
            return Object.assign({}, state, {
                loading: false
            });
        }
        case types.GET_BLOCK_OFF_DATES + types.FULFILLED: {
            return Object.assign({},
                state,
                {
                    list: action.payload.data
                },
            );
            // return Object.assign({}, state, {
            //     ...action.payload.data
            // });
        }
        default: 
            return state;
    }
}