import axios from "axios";
import { isEqual } from "lodash";
import PropTypes from "prop-types";
import queryString from "query-string";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getRelatedProducts } from "../../actions/products.actions";
import sampleIcon from "../../assets/img/sampleicon.png";
import { ASSET_URL } from "../../constants/config";
import {
    extractAssetObj,
    getCategoryTree,
    loadProducts,
    makeASquare,
    paginationLinks,
    setSelectedCategory,
    setSelectedCategoryAndParents
} from "../../helpers/caboodleHelper";
import History from "../../History";
import BrandsList from "../_global/BrandsList/BrandsList";
import TextInput from "../_global/Form/TextInput";
import CategoryFilter from "../_global/Navigation/CategoryFilter";
import ProductList from "../_global/ProductList/ProductList";
import ProductFactory from "../../factories/ProductFactory";

class Products extends React.Component {
    state = {
        sortBy: "",
        loading: false,
        lastParentCategory: false,
        bannerDimensions: { width: 0, height: 0 }
    };

    CancelToken = axios.CancelToken;
    source = false;

    banner;
    bannerTimeout = false;

    componentDidMount() {
        const params = queryString.parse(this.props.location.search),
            bannerHeader = document.getElementsByClassName(
                "category-banner-header"
            );

        // if (Object.keys(params).length === 0) {
        //     // this.filterByCategory(0, false, true);
        //     console.log(123);
        // } else {
        if (params.categories) {
            const catArray = params.categories.split("--");
            let findCat = this.props.categories.list.find(cat => {
                return cat.slug === catArray[0];
            });

            if (catArray.length > 1) {
                findCat = findCat.children.find(cat => {
                    return cat.slug === catArray[1];
                });
            }

            if (
                this.props.categories &&
                this.props.categories.list.length > 0
            ) {
                setSelectedCategory(Object.assign({}, findCat));
            } else {
                setSelectedCategory(params.categories);
            }
        } else {
            setSelectedCategory(false);
        }
        // this.props.getRelatedProducts({slug: ""});
        this.source = this.CancelToken.source();
        loadProducts(this.appendOrderByParams(params), this.source).then(
            res => {
                // if (this.props.products.list.length > 0) {
                //     this.props.getRelatedProducts({slug: this.props.products.list[0].slug});
                // }
            }
        );
        // }

        getCategoryTree();
        this.initMakeASquare();
        window.addEventListener("resize", this.initMakeASquare);

        if (bannerHeader.length > 0) {
            this.setState({
                bannerDimensions: {
                    width: bannerHeader[0].offsetWidth,
                    height: bannerHeader[0].offsetHeight
                }
            });
        }
    }

    componentDidUpdate(oldProps) {
        let newParams = queryString.parse(this.props.location.search);
        let oldParams = queryString.parse(oldProps.location.search);
        const propsChanged = !isEqual(newParams, oldParams);
        let lastParentCategory = false;

        if (
            oldProps.categories.selected &&
            oldProps.categories.selected.id !== this.state.lastParentCategory.id
        ) {
            if (oldProps.categories.selected.parent > 0) {
                lastParentCategory = this.props.categories.list.find(
                    category => {
                        return (
                            category.id === oldProps.categories.selected.parent
                        );
                    }
                );
            } else {
                lastParentCategory = Object.assign(
                    {},
                    oldProps.categories.selected
                );
            }
        }

        if (
            newParams.categories !== oldParams.categories ||
            newParams.brands !== oldParams.brands
        ) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            this.initMakeASquare();
            delete newParams.page;
        }

        if (propsChanged) {
            if (this.source) {
                this.source.cancel();
            }

            this.setState(
                {
                    loading: true,
                    lastParentCategory
                },
                () => {
                    if (newParams.categories) {
                        const catArray = newParams.categories.split("--");
                        let findCat = this.props.categories.list.find(cat => {
                            return cat.slug === catArray[0];
                        });

                        if (catArray.length > 1) {
                            findCat = findCat.children.find(cat => {
                                return cat.slug === catArray[1];
                            });
                        }

                        if (findCat) {
                            setSelectedCategory(Object.assign({}, findCat));
                        }
                    } else {
                        setSelectedCategory(false);
                    }

                    this.source = this.CancelToken.source();
                    loadProducts(
                        this.appendOrderByParams(newParams),
                        this.source
                    ).then(res => {
                        this.setState({
                            loading: false
                        });
                    });
                }
            );
        }
    }

    componentWillUnmount() {
        setSelectedCategory(false);
        window.removeEventListener("resize", this.initMakeASquare);
    }

    initMakeASquare = () => {
        const bannerHeader = document.getElementsByClassName(
            "category-banner-header"
        );

        setTimeout(() => {
            makeASquare(
                document.getElementsByClassName(`product-container-image`)
            );
            // makeASquare(document.getElementsByClassName(`header-icon`), "height");
        });

        if (bannerHeader.length > 0) {
            this.setState({
                bannerDimensions: {
                    width: bannerHeader[0].offsetWidth,
                    height: bannerHeader[0].offsetHeight
                }
            });
        }
    };

    appendOrderByParams(params) {
        // params.sortBy = "created_at-DESC";

        return params;
    }

    onPaginate = newPage => {
        const { location } = this.props;
        let params = queryString.parse(location.search);

        params = Object.assign(params, {
            page: newPage //newPage['page'],
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
        History.push({
            pathname: "/products",
            search: `?${queryString.stringify(params)}`
        });
    };

    filterByCategory = (
        category,
        newParents = false,
        sale = false,
        bestSellers = false
    ) => {
        let search = queryString.parse(this.props.location.search);

        if (sale) {
            setSelectedCategory(category.slug);
            delete search.categories;
            delete search.best_seller;
            search.sale = 1;
        } else if (bestSellers) {
            setSelectedCategory(category.slug);
            delete search.categories;
            delete search.sale;
            search.best_seller = 1;
        } else {
            delete search.sale;
            delete search.best_seller;

            if (newParents) {
                setSelectedCategoryAndParents(category.slug);
            } else {
                setSelectedCategory(category.slug);
            }

            if (category.slug !== search["categories"]) {
                search = Object.assign(search, {
                    categories: category.slug
                });
            }

            if (search.keyword) {
                this.setState({
                    keyword: search.keyword
                });
            }
        }

        delete search.page;
        delete search.keyword;

        History.push({
            pathname: "/products",
            search: `?${queryString.stringify(search)}`
        });
    };

    handleSort = e => {
        let params = queryString.parse(this.props.location.search);
        params = Object.assign(params, {
            sortBy: e.target.value
        });

        History.push({
            pathname: "/products",
            search: `?${queryString.stringify(params)}`
        });
    };

    getPage = () => {
        const { products } = this.props;

        return paginationLinks(products, this.onPaginate);
    };

    render() {
        const { categories, products } = this.props;

        // const newestProduct = products.list.length > 0 ? products.list[0] : false;
        let queryParams = queryString.parse(this.props.location.search);
        let catBanner = false;
        const pagination = this.getPage();

        if (categories.selected && categories.selected) {
            if (categories.selected.parent > 0) {
                catBanner = categories.list.find(category => {
                    return category.id === categories.selected.parent;
                });
            } else {
                catBanner = Object.assign({}, categories.selected);
            }
        }

        return (
            <div className="products-page page-body-container">
                {catBanner && (
                    <div
                        ref={this.banner}
                        className="category-banner-header relative-container"
                        key={"categoriesHeader" + catBanner.id}
                        style={{
                            backgroundImage: catBanner.asset
                                ? `url(${extractAssetObj(catBanner, [
                                      "asset",
                                      "path"
                                  ])})`
                                : "none"
                        }}
                    >
                        <div className="text-details pad-100 flex align-center">
                            <div>
                                <div className="grid grid-2-auto-1fr grid-xs-1 align-center">
                                    <img
                                        src={
                                            catBanner.icon_path
                                                ? ASSET_URL +
                                                  "/" +
                                                  catBanner.icon_path
                                                : sampleIcon
                                        }
                                        className="header-icon margin-right-40"
                                    />
                                    <div>
                                        <h1 className="txt-giantsize txt-white txt-bold-pn-semi txt-uppercase margin-0">
                                            {catBanner.title}
                                        </h1>
                                    </div>
                                    <div />
                                    {catBanner.description && (
                                        <div
                                            className="category-tags txt-white txt-xlarge pad-top-10 pad-bottom-10 pad-left-30 pad-right-30"
                                            dangerouslySetInnerHTML={{
                                                __html: catBanner.description
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {this.state.lastParentCategory &&
                            this.state.lastParentCategory.id !==
                                catBanner.id && (
                                <div
                                    key={`transitionoverlay${this.state.lastParentCategory.id}`}
                                    className="transition-overlay"
                                >
                                    <div
                                        style={{
                                            width: this.state.bannerDimensions
                                                .width,
                                            height: this.state.bannerDimensions
                                                .height,
                                            backgroundImage: this.state
                                                .lastParentCategory.asset
                                                ? `url(${extractAssetObj(
                                                      this.state
                                                          .lastParentCategory,
                                                      ["asset", "path"]
                                                  )})`
                                                : "none"
                                        }}
                                    >
                                        <div className="text-details pad-100 flex align-center">
                                            <div>
                                                <div className="grid grid-2-auto-1fr align-center">
                                                    <img
                                                        src={
                                                            this.state
                                                                .lastParentCategory
                                                                .icon_path
                                                                ? ASSET_URL +
                                                                  "/" +
                                                                  this.state
                                                                      .lastParentCategory
                                                                      .icon_path
                                                                : sampleIcon
                                                        }
                                                        className="header-icon margin-right-40"
                                                    />
                                                    <div>
                                                        <h1 className="txt-giantsize txt-white txt-bold-pn-semi txt-uppercase margin-0">
                                                            {
                                                                this.state
                                                                    .lastParentCategory
                                                                    .title
                                                            }
                                                        </h1>
                                                    </div>
                                                    <div />
                                                    {this.state
                                                        .lastParentCategory
                                                        .description && (
                                                        <div
                                                            className="category-tags txt-white txt-xlarge pad-top-10 pad-bottom-10 pad-left-30 pad-right-30"
                                                            dangerouslySetInnerHTML={{
                                                                __html: this
                                                                    .state
                                                                    .lastParentCategory
                                                                    .description
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                )}
                <div className="page-container">
                    <div className="flex outer-container flex-rm-md">
                        {!queryParams.brands ? (
                            <div className="category-section">
                                <div className="txt-header-2 pad-left-30 pad-right-30 margin-bottom-20 txt-bold-qs-bold">
                                    Products
                                </div>
                                <CategoryFilter
                                    onSelect={(category, subcat) => {
                                        History.push({
                                            pathname: "/products",
                                            search: `?categories=${category.slug +
                                                (subcat
                                                    ? "--" + subcat.slug
                                                    : "")}`
                                        });
                                    }}
                                />
                            </div>
                        ) : null}
                        <div className="flex-1 main-products-content">
                            <div className="flex flex-rm-xs align-center margin-bottom-30">
                                <div className="flex-1">
                                    <h1 className="txt-header-2 flex-1 txt-bold-qs-bold margin-0">
                                        {categories &&
                                            categories.selected &&
                                            categories.selected.title}
                                        {queryParams.brands
                                            ? this.displayBrandFromSlug(
                                                  queryParams.brands
                                              )
                                            : ""}
                                    </h1>
                                    <div className="txt-large txt-bold">
                                        {queryParams.keyword ? (
                                            <>
                                                {" "}
                                                search result for{" "}
                                                <b>"{queryParams.keyword}"</b>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width:
                                            document.body.clientWidth > 767
                                                ? "200px"
                                                : "100%"
                                    }}
                                    className="relative-container"
                                >
                                    <TextInput
                                        type="select"
                                        value={
                                            queryParams.sortBy
                                                ? queryParams.sortBy
                                                : ""
                                        }
                                        onChange={this.handleSort}
                                        className="gray-control clickable"
                                    >
                                        <option disabled value="">
                                            Sort by
                                        </option>
                                        <option value="price-asc">
                                            Price (Lowest to Highest)
                                        </option>
                                        <option value="price-desc">
                                            Price (Highest to Lowest)
                                        </option>
                                        <option value="title-asc">
                                            Alphabetical (A-Z)
                                        </option>
                                        <option value="title-desc">
                                            Alphabetical (Z-A)
                                        </option>
                                    </TextInput>
                                    <i className="far fa-chevron-down txt-gray icon-overlay" />
                                </div>
                            </div>
                            <div>
                                {products &&
                                products.list &&
                                products.list.length === 0 &&
                                !products.loading ? (
                                    <h1 className="txt-error txt-bold-qs-bold">
                                        No products found
                                    </h1>
                                ) : (
                                    <ProductList
                                        loading={products.loading && products}
                                        products={products && products.list}
                                        placeholderCardLength={9}
                                        gridNumber={4}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {products &&
                        products.list &&
                        products.list.length !== 0 &&
                        !products.loading && (
                            <div className="flex justify-center margin-top-50 margin-bottom-50">
                                <div className="flex align-center pagination-links">
                                    {pagination &&
                                        pagination.map(page => {
                                            return page;
                                        })}
                                </div>
                            </div>
                        )}
                </div>
                <BrandsList />
            </div>
        );
    }

    displayBrandFromSlug = slug => {
        const { brands } = this.props,
            foundBrand =
                brands.list &&
                brands.list.find(brand => {
                    return brand.slug === slug;
                });

        return foundBrand ? foundBrand.title : "";
    };
}

function mapStateToProps(state) {
    return {
        products: state.products,
        categories: state.categories,
        brands: state.brands
    };
}

Products.propTypes = {
    products: PropTypes.object,
    categories: PropTypes.object,
    getRelatedProducts: PropTypes.func
};

function mapDispatchToProps(dispatch) {
    return {
        getRelatedProducts: bindActionCreators(getRelatedProducts, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);
