import * as apiHelper from "../helpers/apiHelper";
import * as types from "../constants/actionTypes";
import { store } from "../index";

export function checkoutCart(params) {
    return store.dispatch({
        type: types.UPDATE_ORDER_OBJECT,
        payload: apiHelper.connect(false).post(`/orders/checkout`, params)
    });
}

export function getShippingFee(params) {
    return {
        type: types.GET_SHIPPING_FEE,
        payload: apiHelper.connect(false).post(`/shipping/fee`, params)
    };
}

export function validateCoupon(params) {
    // return {
    //     type: types.GET_COUPON,
    //     payload: apiHelper.connect().post("/coupon/apply", params)
    // };
    return apiHelper.connect().post("/coupon/apply", params);
}

export function updateOrderStatus(order) {
    return apiHelper.connect().post("/order/update-status", order);
}

export function placeOrder(params) {
    return store.dispatch({
        type: types.UPDATE_ORDER_OBJECT,
        payload: apiHelper.connect().post("/orders/place", params)
    });
}

export function updateOrder(order) {
    return {
        type: types.UPDATE_ORDER_OBJECT + types.FULFILLED,
        payload: { data: order }
    };
}

export function resetOrder() {
    return {
        type: types.RESET_ORDER
    };
}
