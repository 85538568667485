import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

import {
        setSelectedCategory,
        makeASquare,
        extractAssetObj
    } from '../../helpers/caboodleHelper';

import {
    getBrandsList
} from '../../actions/brands.actions';

import logo from '../../assets/img/logo.jpg';

class Brands extends React.Component {

    componentDidMount () {
        setSelectedCategory(false);
        getBrandsList();
    }

    componentWillUnmount() {
        
        window.removeEventListener("resize", this.initMakeASquare);
    }
    
    initMakeASquare = () => {
        setTimeout(() => {
            makeASquare(document.getElementsByClassName(`product-container-image`));
        });
    }

    render() {
        const { brands } = this.props;

        return (
            <div className="page-body-container">
                <div className="container margin-bottom-50 margin-top-50">
                    <div className="txt-header-2 txt-bold-qs-bold text-center">
                        Brand Partners
                    </div>
                    <div className="partner-list grid grid-4 grid-md-4 grid-xs-2 grid-gap-50 margin-top-50">
                        {
                            brands && brands.list && brands.list.map((brand, index) => {
                                return (
                                    <div
                                        className="partner-card fade-in-up"
                                        key={index + "brands"}
                                        style={{
                                            animationDelay: `${(index + 1) * 0.3}s`
                                        }}
                                    >
                                        <NavLink
                                            to={`/products?brands=${brand.slug}`}
                                        >
                                            <img
                                                src={extractAssetObj(brand, ["asset", "path"])}
                                            />
                                        </NavLink>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
	return {
        brands: state.brands
	};
}

export default connect(mapStateToProps, null)(Brands);

