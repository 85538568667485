import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/rootReducer";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import ReduxPromise from "redux-promise-middleware";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage,
    whitelist: [
        "user",
        "cart",
        "order",
        "address_form",
        "shippingMethods",
        "site_options",
        "collections",
        "categories",
        "brands"
    ]
};

export default function configureStore(initialState) {
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    // Grab the state from a global variable injected into the server-generated HTML
    const preloadedState = window.__PRELOADED_STATE__;

    // Allow the passed state to be garbage-collected
    delete window.__PRELOADED_STATE__;
    const store = createStore(
        persistedReducer,
        preloadedState,
        composeEnhancers(applyMiddleware(thunk, ReduxPromise))
    );
    const persistor = persistStore(store);
    return { store, persistor };
}
