import { combineReducers } from "redux";
import page from "./page.reducer";
import products from "./products.reducer";
import reviews from "./reviews.reducer";
import cart from "./cart.reducer";
import modal from "./modal.reducer";
import order from "./order.reducer";
import select_options from "./select_options.reducer";
import address_form from "./address_form.reducer";
import user from "./user.reducer";
import categories from "./categories.reducer";
import site_options from "./site_options.reducer";
import shipping_zone from "./shipping_zone.reducer";
import collections from "./collections.reducer";
import selectedBlog from "./blogs.reducer";
import brands from "./brands.reducer";
import wholePageLoader from "./whole_page_loader.reducer";
import block_off_dates from "./block_off_dates.reducer";
import shippingMethods from "./shippingMethods.reducer";

const rootReducer = combineReducers({
    page,
    products,
    reviews,
    cart,
    modal,
    order,
    select_options,
    address_form,
    user,
    categories,
    site_options,
    shipping_zone,
    collections,
    selectedBlog,
    brands,
    wholePageLoader,
    block_off_dates,
    shippingMethods
});

export default rootReducer;
