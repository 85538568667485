import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import sliderLowerRight from "../../assets/img/slider-lower-right.png";
import {
    extractAssetObj,
    getPage,
    getPageItemsSeeMore,
    getSiteOption,
    slugify
} from "../../helpers/caboodleHelper";
import TitleLeaf from "../_global/Elements/TitleLeaf";
import { JournalsUpperLeaves, Section5Images } from "./FloatingLeaves";
import { JournalCard } from "./JournalCard";

const Journals = ({ page, products }) => {
    const cancelToken = useRef(null);
    const banners = page.banners ? page.banners : undefined;
    const home = page.home ? page.home : undefined;

    const [postsToShow, setPostsToShow] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [disableButton, setDisableButton] = useState(false);
    const [hasLoadMore, setHasLoadMore] = useState(false);

    useEffect(() => {
        cancelToken.current = axios.CancelToken.source();
        const slug = ["human-nature", "lazada", "shopee"];

        getPage("about", cancelToken.current);
        getPage("banners", cancelToken.current);
        getPage("home", cancelToken.current);
        getSiteOption({ slug }, cancelToken.current);

        return () => {
            if (cancelToken.current) cancelToken.current.cancel();
            cancelToken.current = null;
        };
    }, []);

    useEffect(() => {
        getPageItemsSeeMore("journals", { page: pageNumber })
            .then(res => {
                if (res.value && res.value.items.length > 0) {
                    let items = res.value.items;
                    setPostsToShow(prevPostsToShow => [
                        ...prevPostsToShow,
                        ...items
                    ]);
                    checkLastPage(res.value.data.last_page);
                }
            })
            .finally(() => setDisableButton(false));
    }, [pageNumber]);

    const handleLoadMore = () => {
        setPageNumber(prevPageNumber => prevPageNumber + 1);
        setDisableButton(true);
    };

    const checkLastPage = lastPage => {
        if (lastPage == pageNumber) {
            setHasLoadMore(false);
        } else {
            setHasLoadMore(true);
        }
    };

    return (
        <div className="journals page-body-container">
            {banners && banners.journalsBanner && (
                <div
                    className="banner start-section pad-top-50 pad-bottom-50 flex align-center"
                    style={{
                        backgroundImage: `url(${extractAssetObj(
                            banners.journalsBanner,
                            ["image", "path"]
                        )})`
                    }}
                >
                    <div className="container text-center txt-cerapro-bold txt-upsized txt-shadow">
                        {banners.journalsBanner.title}
                    </div>
                </div>
            )}
            <div className="container pad-top-50 pad-bottom-50">
                <div className="journals-section">
                    <div className="latest-news margin-bottom-50">
                        <JournalsUpperLeaves />
                        <TitleLeaf
                            className="txt-header txt-cerapro-bold"
                            title="Latest News"
                        />
                    </div>
                    <div className="journals-grid margin-bottom-50">
                        {/* {console.log(postsToShow)} */}
                        {postsToShow.length > 0 &&
                            postsToShow.map((item, index) => (
                                <NavLink
                                    to={`journals/${slugify(
                                        item.title + " " + item.id,
                                        "forward"
                                    )}/view`}
                                    className="journal"
                                    key={index}
                                >
                                    <JournalCard journalPost={item} />
                                    {/* <div className="img-container">
                                        <img
                                            className="image"
                                            src={extractAssetObj(item, [
                                                "image",
                                                "path"
                                            ])}
                                            alt={item.title}
                                        />
                                        <div className="background-overlay" />
                                        <h5 className="txt-white all-caps on-hover">
                                            Read More
                                        </h5>
                                    </div>
                                    <h4 className="margin-bottom-20 txt-cerapro-bold no-wrap">
                                        {item.title}
                                    </h4>
                                    <h5 className="txt-gray-med-light all-caps">
                                        {item.date}
                                    </h5> */}
                                </NavLink>
                            ))}
                    </div>
                    {hasLoadMore && (
                        <button
                            className="txt-cerapro-medium txt-medium txt-white btn-load"
                            onClick={() => handleLoadMore()}
                            disabled={disableButton}
                        >
                            LOAD MORE
                        </button>
                    )}
                </div>
            </div>

            {home && home.otherShops && (
                <div className="section-5 container pad-bottom-50 pad-top-150">
                    <div className="position-relative">
                        <Section5Images />
                        <div className="position-relative">
                            <div className="image-container position-relative">
                                <img
                                    src={extractAssetObj(home.otherShops, [
                                        "image",
                                        "path"
                                    ])}
                                />
                            </div>
                            <div className="content-container flex justify-center direction-column">
                                <h2 className="txt-cerapro-bold margin-bottom-20 txt-header-2">
                                    {home.otherShops.title}
                                </h2>
                                {products &&
                                    products.list &&
                                    products.list.length > 0 && (
                                        <Link
                                            className="txt-cerapro-bold btn-shop"
                                            to={`/product/${products.list[0].slug}/view`}
                                        >
                                            Shop Now
                                        </Link>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {banners && banners.endBanner && (
                <div
                    className="banner end-section pad-top-50 pad-bottom-50 flex align-center"
                    style={{
                        backgroundImage: `url(${extractAssetObj(
                            banners.endBanner,
                            ["image", "path"]
                        )})`
                    }}
                >
                    <div className="container txt-cerapro-bold txt-upsized">
                        <div className="content margin-bottom-50">
                            <div
                                className="txt-shadow"
                                dangerouslySetInnerHTML={{
                                    __html: banners.endBanner.content
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        page: state.page,
        site_options: state.site_options,
        products: state.products
    };
}

export default connect(mapStateToProps, null)(Journals);
