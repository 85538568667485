import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import AddressForm from "../../_global/AddressForm/AddressForm";
import { setSelectedBilling } from "../../../actions/address_form.actions";
import paypalImg from "../../../assets/img/paypal.png";
import bpiImg from "../../../assets/img/bpi.png";
import bdoImg from "../../../assets/img/bdo.png";
import gcashImg from "../../../assets/img/gcash.png";
import { toggleWholePageLoader } from "../../../helpers/caboodleHelper";
import { useSelector } from "react-redux";
import * as config from "../../../constants/config";
import PaypalExpressBtn from "react-paypal-express-checkout";

const Payment = ({
    proceedToNextStep,
    userFactory,
    selectedPaymentMethod,
    checkoutFactory,
    setSelectedPaymentMethod,
    hasGcash
}) => {
    const billingForm = useRef(null);
    const address_form = useSelector(state => state.address_form);
    const selectedBillingInfo = address_form.selectedBilling;

    const setSelectedBillingInfo = type => {
        setSelectedBilling(type);
    };

    const handleCompleteOrderClick = (payment) => {
        toggleWholePageLoader(true);
        if (selectedBillingInfo == "billing") {
            billingForm.current.submitForm().then(res => {
                if (!res.status) {
                    return;
                }
                res.form.type = "billing";
                res.form.address_type = "billing";
                userFactory.updateAddress(res.form).then(res => {
                    proceedToNextStep(payment);
                });
            });
        } else {
            let copyShipping = JSON.parse(
                JSON.stringify(address_form.shipping)
            );
            copyShipping.type = "billing";
            copyShipping.address_type = "billing";

            userFactory.updateAddress(copyShipping).then(res => {
                proceedToNextStep(payment);
            });
        }
    };

    const paypalCancel = () => {};

    const paypalSuccess = payment => {
        console.log(payment);
        handleCompleteOrderClick(payment);
    };
    
    const paypalError = () => {};
    
    return (
        <div className="payment">
            <div className="margin-top-30 margin-bottom-40">
                <h5 className="txt-14-px txt-cerapro-medium">Payment Method</h5>

                {/* bank deposit */}
                <div
                    className={`select-method margin-bottom clickable ${
                        "bank-deposit" == selectedPaymentMethod ? "active" : ""
                    }`}
                    onClick={() => setSelectedPaymentMethod("bank-deposit")}
                >
                    <div className="flex align-center">
                        <div className="icon-holder margin-right-20">
                            <i className="fas fa-check-circle" />
                        </div>
                        <div className="title">
                            Bank Deposit {hasGcash ? "/ Gcash" : ""}
                        </div>
                    </div>
                    <div className="img-right">
                        <img className="margin-right" src={bpiImg} alt="bpi" />
                        <img src={bdoImg} alt="bdo" />
                        {hasGcash ? <img src={gcashImg} alt="gcash" /> : ""}
                    </div>
                </div>

                {/* PayPal */}
                <div
                    className={`select-method margin-bottom clickable ${
                        "paypal" == selectedPaymentMethod ? "active" : ""
                    }`}
                    onClick={() => setSelectedPaymentMethod("paypal")}
                >
                    <div className="flex align-center">
                        <div className="icon-holder margin-right-20">
                            <i className="fas fa-check-circle" />
                        </div>
                        <div className="title">PayPal</div>
                    </div>
                    <div className="img-right">
                        <img src={paypalImg} alt="paypal" />
                    </div>
                </div>
            </div>

            <hr />

            <div className="margin-top-30">
                <h5 className="txt-14-px txt-cerapro-medium">
                    Billing Information
                </h5>

                <div
                    className={`select-method margin-bottom clickable ${
                        selectedBillingInfo == "shipping" ? "active" : ""
                    }`}
                    onClick={() => setSelectedBillingInfo("shipping")}
                >
                    <div className="flex align-center">
                        <div className="icon-holder margin-right-20">
                            <i className="fas fa-check-circle" />
                        </div>
                        <div className="title">Same as Shipping Address</div>
                    </div>
                </div>
                <div className="billing-container">
                    <div
                        className={`select-method clickable ${
                            selectedBillingInfo == "billing" ? "active" : ""
                        }`}
                        onClick={() => setSelectedBillingInfo("billing")}
                    >
                        <div className="flex align-center">
                            <div className="icon-holder margin-right-20">
                                <i className="fas fa-check-circle" />
                            </div>
                            <div className="title">New Billing Address</div>
                        </div>
                    </div>
                    <div
                        className={`billing-form ${
                            selectedBillingInfo == "billing" ? "active" : ""
                        }`}
                    >
                        <AddressForm type="billing" ref={billingForm} />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end pb-3">
                {selectedPaymentMethod == "paypal" ? (
                    <div className="margin-top-20">
                        <PaypalExpressBtn
                            env={config.PAYPAL_ENV}
                            client={config.PAYPAL_CLIENT}
                            currency={"PHP"}
                            total={checkoutFactory.getTotal()}
                            onError={() => paypalError()}
                            onSuccess={  paypalSuccess }
                            onCancel={() => paypalCancel()}
                            style={{
                                height: 40,
                                label: "pay",
                                width: "100%"
                            }}
                        />
                    </div>
                ) : (
                    <div className="margin-top-30">
                        <button
                            className="btn btn-primary"
                            onClick={() => handleCompleteOrderClick()}
                        >
                            Complete Order
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

Payment.propTypes = {
    proceedToNextStep: PropTypes.func,
    userFactory: PropTypes.object
};

export default Payment;
