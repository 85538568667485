export default {
    page: {},
    brands: {
        loading: false
    },
    user: {},
    products: {
        list: [],
        loading: true
    },
    collections: [],
    categories: {
        list: []
    },
    reviews: {
        loading: false,
        list: []
    },
    cart: {
        items: []
    },
    modal: {
        open: false,
        content: "",
        animateIn: "zoomIn",
        animateOut: "zoomOut"
    },
    order: {},
    select_options: {
        province_list: [],
        city_list: [],
        area_list: []
    },
    shipping_form: {
        errors: {},
        inputs: {
            id: 0,
            firstname: "",
            lastname: "",
            company: "",
            email: "",
            address_line_1: "",
            address_line_2: "",
            country_id: "1",
            province_id: "",
            city_id: "",
            area_id: "",
            mobile: ""
        },
        valid: false
    },
    address_form: {
        shipping: { type: "shipping", customer_id: 0 },
        billing: { type: "billing", customer_id: 0 },
        selectedBilling: "billing"
    },
    shippingMethods: {
        list: [],
        selected: {}
    },
    site_options: {
        loading: false
    },
    shipping_zone: {},
    selectedBlog: {},
    wholePageLoader: { show: false },
    block_off_dates: {
        loading: false
    }
};
