import * as types from "../constants/actionTypes";
import initialState from "./initialState";

export default function(state = initialState.address_form, action) {
    switch (action.type) {
        case types.GET_ADDRESS + types.FULFILLED: {
            let address = assignDefaultAddress(state, action.payload.data);
            console.log(address);
            return Object.assign({}, state, address);
        }

        case types.SET_SELECTED_BILLING: {
            const selectedBilling = action.payload.type;
            return Object.assign({}, state, { selectedBilling });
        }

        case types.SET_CUSTOMER_ADDRESS_TO_ADDRESS_FORM: {
            const customer = action.payload.customer;
            const customer_id = customer.id;

            const shipping = Object.assign(
                {},
                state.shipping,
                customer.primary_address ? customer.primary_address : {},
                { customer_id }
            );

            const billing = Object.assign(
                {},
                state.billing,
                customer.billing_address ? customer.billing_address : {},
                { customer_id }
            );

            return Object.assign({}, state, { shipping, billing });
        }

        case types.RESET_ADDRESS_FORM: {
            return {
                shipping: { type: "shipping", customer_id: 0 },
                billing: { type: "billing", customer_id: 0 },
                selectedBilling: "billing"
            };
        }

        default:
            return state;
    }
}

function assignDefaultAddress(state, data) {
    if (data.type === "shipping") {
        state.shipping = data.hasOwnProperty("id") ? data : state.shipping;
    }

    if (data.type === "billing") {
        state.billing = data.hasOwnProperty("id") ? data : state.billing;
    }

    return state;
}
