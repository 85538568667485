import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
    extractAssetObj,
    toggleWholePageLoader,
} from "../../../helpers/caboodleHelper";
import { useSelector } from "react-redux";
import ProductFactory from "../../../factories/ProductFactory";

const OrderSummary = ({
    checkoutFactory,
    uriSlug,
    couponCode,
    setCouponCode,
    handleCouponApply,
    cancelCoupon,
    coupon
}) => {
    const order = useSelector(state => state.order);
    const cart = useSelector(state => state.cart);
    const user = useSelector(state => state.user);

    const handleQuantityChange = async (item, type) => {
        toggleWholePageLoader(true);

        const productFactory = new ProductFactory({ product: item.product });
        let quantity = 1;

        if (type == "decrement") quantity = -1;

        await productFactory.addToCart({
            cart,
            user,
            quantity
        });
        
        await checkoutFactory.updateOrder();
    };

    useEffect(() => {
        if (couponCode) {
            handleCouponApply();
        }
    }, [order]);

    return (
        <>
            <div className="order-summary margin-bottom-10 margin-top-50">
                <div>
                    <h4 className="txt-cerapro-medium txt-medium margin-bottom-20">
                        Order Summary
                    </h4>

                    <div className="items">
                        {order.items.map((item, idx) => (
                            <div key={idx} className="item margin-bottom-20">
                                <img
                                    src={extractAssetObj(item.product, [
                                        "image_paths",
                                        "small_thumbnail"
                                    ])}
                                    alt={item.product.title}
                                />
                                <div>
                                    {uriSlug !== "payment" && (
                                        <div className="quantity-control">
                                            <button
                                                className="btn btn-primary"
                                                onClick={() =>
                                                    handleQuantityChange(
                                                        item,
                                                        "decrement"
                                                    )
                                                }
                                            >
                                                <i className="far fa-minus" />
                                            </button>
                                            <input
                                                type="text"
                                                value={item.quantity}
                                                className="txt-cerapro-bold"
                                                readOnly
                                            />
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    handleQuantityChange(
                                                        item,
                                                        "increment"
                                                    );
                                                }}
                                            >
                                                <i className="far fa-plus" />
                                            </button>
                                        </div>
                                    )}
                                    <h5 className="txt-small margin-top">
                                        {item.product.title}
                                    </h5>
                                    {uriSlug === "payment" && (
                                        <div className="txt-small">
                                            x {item.quantity}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* COUPON */}
                    {uriSlug != "payment" && (
                        <div className="margin-top-50">
                            <div className="voucher-container">
                                <input
                                    className="flex-auto"
                                    type="text"
                                    placeholder="Gift card or discount"
                                    name="coupon_code"
                                    value={couponCode}
                                    disabled={
                                        coupon &&
                                        coupon.data &&
                                        coupon.data.hasOwnProperty("id")
                                    }
                                    onChange={e =>
                                        setCouponCode(e.target.value)
                                    }
                                />
                                {checkoutFactory.getCouponDiscount() &&
                                checkoutFactory.getCouponDiscount() > 0 ? (
                                    <button
                                        className="btn btn-lgray flex-1"
                                        onClick={() => cancelCoupon()}
                                    >
                                        Cancel
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-lgray flex-1"
                                        onClick={() => handleCouponApply()}
                                    >
                                        Apply
                                    </button>
                                )}
                            </div>
                            {coupon &&
                            coupon.hasOwnProperty("data") &&
                            !checkoutFactory.isCouponValid() ? (
                                <small className="text-danger">
                                    Invalid coupon code.
                                </small>
                            ) : null}
                        </div>
                    )}

                    <hr />

                    {/* SUBTOTAL */}
                    <div className="flex justify-between txt-12-px">
                        <div>Subtotal</div>
                        <div>{`${
                            order.currency.symbol
                        }${checkoutFactory.getSubtotal()}`}</div>
                    </div>

                    {/* SHIPPING FEE */}
                    <div className="flex justify-between flex-wrap txt-12-px">
                        <div>Shipping</div>

                        {(uriSlug == "payment" || uriSlug == "shipping") &&
                        checkoutFactory.getShippingFee() ? (
                            <div>{`${
                                order.currency.symbol
                            }${checkoutFactory.getShippingFee()}`}</div>
                        ) : (
                            <div>Calculated next step</div>
                        )}
                    </div>

                    {/* DISCOUNTS */}
                    {checkoutFactory.isCouponValid() &&
                    checkoutFactory.getCouponDiscount() > 0 ? (
                        <div className="flex justify-between margin-top-10 txt-12-px">
                            <div>Discount</div>
                            <div className="text-danger">{`-${
                                order.currency.symbol
                            }${checkoutFactory.getCouponDiscount()}`}</div>
                        </div>
                    ) : null}

                    {(uriSlug == "payment" || uriSlug == "shipping") &&
                    checkoutFactory.isCouponValid() &&
                    checkoutFactory.getCouponWaveShipping() ? (
                        <div className="flex justify-between txt-12-px">
                            <div>Shipping Discount</div>
                            <div className="text-danger">{`-${
                                order.currency.symbol
                            }${checkoutFactory.getShippingFee()}`}</div>
                        </div>
                    ) : null}
                </div>
            </div>

            {/* TOTAL */}
            {uriSlug == "shipping" || uriSlug == "payment" ? (
                <div className="order-summary margin-bottom-10">
                    <div>
                        <div className="flex justify-between">
                            <div>Order Total</div>
                            <div>{`${
                                order.currency.symbol
                            }${checkoutFactory.getTotal()}`}</div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

OrderSummary.propTypes = {
    order: PropTypes.object,
    dispatch: PropTypes.func,
    checkoutFactory: PropTypes.object,
    uriSlug: PropTypes.string
};

export default OrderSummary;
