import moment from "moment";
import React, { useEffect, useState } from "react";
import { getCustomerOrders, voidOrder } from "../../actions/customer.actions";
import { extractAssetObj } from "../../helpers/caboodleHelper";
import History from "../../History";
import Skeleton from "../_global/Elements/Skeleton";
import OrderHistoryView from "./OrderHistoryView";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const OrderHistory = () => {
    const [userOrders, setUserOrders] = useState({});
    const search = History.location.search;
    const [selectedOrder, setSelectedOrder] = useState({});
    const [pagination, setPagination] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const MySwal = withReactContent(Swal);

    let initialStatus = "pending";
    let initialPage = 1;
    const searchArray = search.split("&");
    if (searchArray.length > 0)
        searchArray.map(searchIdx => {
            const innerSearchArr = searchIdx.split("=");
            if (innerSearchArr[0].indexOf("status") > -1) {
                if (
                    innerSearchArr[1] == "completed" ||
                    innerSearchArr[1] == "cancelled"
                ) {
                    initialStatus = innerSearchArr[1];
                }
            } else if (innerSearchArr[0].indexOf("page") > -1)
                initialPage = innerSearchArr[1];
        });

    const [status, setStatus] = useState(initialStatus);
    const [page, setPage] = useState(initialPage);
    const [toggleApi, setToggleApi] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        setUserOrders({});
        setPagination({});
        getCustomerOrders({ status, page }).then(res => {
            if (res.data.data) {
                setUserOrders(res.data.data);
                setPagination(res.data);
            }

            setIsLoading(false);
        });
    }, [search, toggleApi]);

    const onChangeStatus = status => {
        setStatus(status);
        setPage(1);
        History.push(`/manage-account/order-history?status=${status}`);
    };

    const onChangePage = page => {
        setPage(page);
        History.push(
            `/manage-account/order-history?status=${status}&page=${page}`
        );
    };

    const handleOrderCancel = refNumber => {
        MySwal.fire({
            title: <p>Cancel Order</p>,
            text: `Do you want to cancel order #${refNumber}?`,
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No"
        }).then(res => {
            if (res.isConfirmed) {
                setIsLoading(true);
                voidOrder({ reference: refNumber }).then(res => {
                    setToggleApi(!toggleApi);
                    MySwal.fire(
                        "Cancelled",
                        "Your order has been cancelled.",
                        "success"
                    );
                });
            }
        });
    };

    if (selectedOrder.hasOwnProperty("id")) {
        return (
            <OrderHistoryView
                selectedOrder={selectedOrder}
                setSelectedOrder={setSelectedOrder}
                handleOrderCancel={handleOrderCancel}
            />
        );
    }

    return (
        <div className="order-history p-5">
            <h1>Order History</h1>
            <ul className="flex flex-wrap">
                <li className="margin-right-25 txt-15-px">
                    <a
                        className={`clickable status-nav ${
                            status === "pending" ? "active" : ""
                        }`}
                        onClick={e => onChangeStatus("pending")}
                    >
                        Pending
                    </a>
                </li>
                <li className="margin-right-25 txt-15-px">
                    <a
                        className={`clickable status-nav ${
                            status === "completed" ? "active" : ""
                        }`}
                        onClick={e => onChangeStatus("completed")}
                    >
                        Completed
                    </a>
                </li>
                <li className="txt-15-px">
                    <a
                        className={`clickable status-nav ${
                            status === "cancelled" ? "active" : ""
                        }`}
                        onClick={e => onChangeStatus("cancelled")}
                    >
                        Cancelled
                    </a>
                </li>
            </ul>

            <div className="txt-medium">
                {isLoading ? (
                    <>
                        {[...Array(5)].map((count, idx) => (
                            <div
                                className="order-item pad-top-20 pad-bottom-20"
                                key={idx}
                            >
                                <div className="margin-bottom-10">
                                    <Skeleton width="150px" />
                                </div>
                                <div className="flex justify-content-between margin-bottom-10">
                                    <Skeleton width="90px" height="10px" />
                                    <Skeleton width="150px" />
                                </div>
                                <div className="margin-bottom-10">
                                    <Skeleton width="150px" height="10px" />
                                </div>
                                <div className="margin-bottom-10">
                                    <Skeleton width="70px" height="10px" />
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <>
                        {userOrders.length > 0 ? (
                            userOrders.map((order, idx) => (
                                <div
                                    className="order-item pad-top-20 pad-bottom-20"
                                    key={idx}
                                >
                                    <div className="margin-bottom-10">
                                        <span className="margin-right-10 d-inline-block txt-17-px txt-cerapro-bold">
                                            #{order.reference_number}
                                        </span>
                                        <span className="txt-12-px">
                                            {moment(order.date_placed, [
                                                "YYYY-MM-DD HH:mm:ss"
                                            ]).format("MMM D")}
                                        </span>
                                        {console.log(order)}
                                        <span
                                            className={`float-right txt-light-green txt-cerapro-medium ${
                                                order.status == "void"
                                                    ? "text-danger"
                                                    : ""
                                            }`}
                                        >
                                            {order.status == "void"
                                                ? "Cancelled"
                                                : order.fulfillment_date
                                                ? order.shipment_date
                                                    ? order.delivered_date
                                                        ? "Delivered"
                                                        : "To Receive"
                                                    : "To Ship"
                                                : "Processing"}
                                        </span>
                                    </div>
                                    <div className="flex justify-content-between">
                                        {order.items.map((item, key) => (
                                            <div
                                                key={key}
                                                className="flex item-product flex-wrap"
                                            >
                                                <img
                                                    src={extractAssetObj(
                                                        item.product,
                                                        ["image_paths", "path"]
                                                    )}
                                                    alt={item.product.title}
                                                />
                                                <div className="txt-12-px">
                                                    <div>
                                                        {item.product.title}
                                                    </div>
                                                    <div>x{item.quantity}</div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className="float-right">
                                            {order.status != "void" &&
                                                !order.shipment_date && (
                                                    <button
                                                        className="btn btn-lgray-white"
                                                        onClick={() =>
                                                            handleOrderCancel(
                                                                order.reference_number
                                                            )
                                                        }
                                                    >
                                                        Cancel
                                                    </button>
                                                )}
                                            <button
                                                onClick={() =>
                                                    setSelectedOrder(order)
                                                }
                                                className="btn btn-lyellow ml-1"
                                            >
                                                View
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <div className="margin-top-30 txt-cerapro-bold text-center">
                                No data available.
                            </div>
                        )}
                    </>
                )}
            </div>

            {pagination.last_page > 1 && (
                <ul className="pagination">
                    <li>
                        <a
                            className={page == 1 ? "" : "clickable"}
                            onClick={() => onChangePage(1)}
                            disabled={page == 1}
                        >
                            <i className="fas fa-angle-left" />
                        </a>
                    </li>
                    {[...Array(pagination.last_page)].map((count, idx) => (
                        <li key={idx}>
                            <a
                                className={
                                    page == idx + 1 ? "active" : "clickable"
                                }
                                onClick={() => onChangePage(idx + 1)}
                            >
                                {idx + 1}
                            </a>
                        </li>
                    ))}
                    <li>
                        <a
                            className={
                                page == pagination.last_page ? "" : "clickable"
                            }
                            onClick={() => onChangePage(pagination.last_page)}
                            disabled={page == pagination.last_page}
                        >
                            <i className="fas fa-angle-right" />
                        </a>
                    </li>
                </ul>
            )}
        </div>
    );
};

export default OrderHistory;
