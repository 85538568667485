import moment from "moment";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { reviewProduct } from "../../actions/product-review.actions";
import deliveredImg from "../../assets/img/delivered.png";
import placedImg from "../../assets/img/placed.png";
import shippedImg from "../../assets/img/shipped.png";
import { extractAssetObj } from "../../helpers/caboodleHelper";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import leafTopLeft from "../../assets/img/leaf-review-left.png";
import leafTopRight from "../../assets/img/leaf-review-right.png";

const OrderHistoryView = ({
    selectedOrder,
    setSelectedOrder,
    handleOrderCancel
}) => {
    const user = useSelector(state => state.user);
    const [toggleModal, setToggleModal] = useState(false);
    const [reviewRating, setReviewRating] = useState(5);
    const [reviewContent, setReviewContent] = useState("");
    const [productId, setProductId] = useState(null);
    const [reviewSubmit, setReviewSubmit] = useState(false);
    const [reviewHoverRating, setReviewHoverRating] = useState(false);
    const MySwal = withReactContent(Swal);

    const handleProductReview = () => {
        setReviewSubmit(true);
        let params = {
            user_id: user.id,
            customer_id: user.customer.id,
            product_id: productId,
            rating: reviewRating,
            content: reviewContent,
            author: user.name
        };

        reviewProduct(params)
            .then(res => {
                setReviewRating(5);
                setReviewContent("");
                setToggleModal(false);

                MySwal.fire({
                    title: <p>Thank you for the review.</p>,
                    icon: "success",
                    timer: 2000,
                    timerProgressBar: true
                });
            })
            .catch(err => {
                MySwal.fire({
                    title: <p>Sorry for the inconvenience</p>,
                    icon: "error",
                    timer: 2000,
                    timerProgressBar: true
                });
            })
            .finally(() => {
                setReviewSubmit(false);
            });
    };
    const handleStarEnter = num => {
        setReviewHoverRating(num);
    };

    const handleStarLeave = params => {
        setReviewHoverRating(false);
    };

    return (
        <>
            <div className={`modal-caboodle ${toggleModal ? "open" : ""}`}>
                <div className="modal-container">
                    <div className="modal-review">
                        <div className="images-container">
                            <div className="image">
                                <img
                                    className="floating-leaf right right-upper faded-3"
                                    src={leafTopRight}
                                />
                                <img
                                    className="floating-leaf left left-upper faded-3"
                                    src={leafTopLeft}
                                />
                            </div>
                        </div>
                        <button
                            className="modal-exit"
                            onClick={() => setToggleModal(false)}
                        >
                            <i className="far fa-times" />
                        </button>
                        <div className="review-information">
                            <div className="txt-medium text-uppercase margin-bottom-35 txt-cerapro-bold">
                                write a review
                            </div>
                            <h2 className="txt-cerapro-bold">
                                How would you rate our product?
                            </h2>

                            <div className="star-container txt-xxlarge margin-top-20">
                                {[...Array(5)].map((e, i) => (
                                    <button
                                        key={i}
                                        onClick={() => setReviewRating(i + 1)}
                                        onMouseEnter={() =>
                                            handleStarEnter(i + 1)
                                        }
                                        onMouseLeave={() => handleStarLeave()}
                                    >
                                        {reviewHoverRating ? (
                                            <>
                                                {i < reviewHoverRating ? (
                                                    <i className="fas fa-star" />
                                                ) : (
                                                    <i className="far fa-star" />
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {i < reviewRating ? (
                                                    <i className="fas fa-star" />
                                                ) : (
                                                    <i className="far fa-star" />
                                                )}
                                            </>
                                        )}
                                    </button>
                                ))}
                            </div>
                            <textarea
                                className="form-control margin-top-20"
                                cols="30"
                                rows="8"
                                placeholder="Leave a comment...."
                                onChange={e => setReviewContent(e.target.value)}
                                value={reviewContent}
                            />

                            <div className="row margin-top-30 justify-content-center">
                                <div>
                                    <button
                                        onClick={() => handleProductReview()}
                                        className="btn btn-primary btn-block"
                                        disabled={reviewSubmit}
                                    >
                                        Submit Review
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="order-history-view p-5">
                <div>
                    <button
                        className="back-btn"
                        onClick={() => setSelectedOrder({})}
                    >
                        <i className="fal fa-arrow-circle-left margin-right-10" />
                        Back
                    </button>
                </div>

                <div className="flex justify-content-between order-status margin-top-20 flex-wrap">
                    <div
                        className={`flex flex-column align-items-center txt-medium mobile-img ${
                            selectedOrder.delivered_date
                                ? ""
                                : selectedOrder.shipment_date
                                ? ""
                                : selectedOrder.status == "void"
                                ? ""
                                : "active"
                        }`}
                    >
                        <div className="img-container">
                            <img src={placedImg} alt="placed image" />
                        </div>
                        <div className="txt-small">Order Placed</div>
                        <div className="txt-small">
                            {moment(selectedOrder.date_placed, [
                                "YYYY-MM-DD HH:mm:ss"
                            ]).format("MMM D")}
                        </div>
                    </div>
                    {selectedOrder.status != "void" ? (
                        <>
                            <div
                                className={`connector-line ${
                                    selectedOrder.delivered_date
                                        ? ""
                                        : selectedOrder.shipment_date
                                        ? ""
                                        : "gray-line"
                                }`}
                            />
                            <div
                                className={`flex flex-column align-items-center txt-medium mobile-img ${
                                    selectedOrder.delivered_date
                                        ? ""
                                        : selectedOrder.shipment_date
                                        ? "active"
                                        : "pending"
                                }`}
                            >
                                <div className="img-container">
                                    <img src={shippedImg} alt="shipped image" />
                                </div>
                                {selectedOrder.shipment_date ? (
                                    <>
                                        <div className="txt-small">
                                            Order Shipped
                                        </div>
                                        <div className="txt-small">
                                            {moment(
                                                selectedOrder.shipment_date,
                                                ["YYYY-MM-DD HH:mm:ss"]
                                            ).format("MMM D")}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                            <div
                                className={`connector-line second ${
                                    selectedOrder.delivered_date
                                        ? ""
                                        : "gray-line"
                                }`}
                            />
                            <div
                                className={`flex flex-column align-items-center txt-medium deliver ${
                                    selectedOrder.delivered_date
                                        ? "active"
                                        : "pending"
                                }`}
                            >
                                <div className="img-container">
                                    <img
                                        src={deliveredImg}
                                        alt="delivered image"
                                    />
                                </div>
                                {selectedOrder.delivered_date ? (
                                    <>
                                        <div className="txt-small">
                                            Order Received
                                        </div>
                                        <div className="txt-small">
                                            {moment(
                                                selectedOrder.delivered_date,
                                                ["YYYY-MM-DD HH:mm:ss"]
                                            ).format("MMM D")}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={`connector-line`}
                                style={{ width: "100%" }}
                            />
                            <div
                                className={`flex flex-column align-items-center txt-medium deliver active`}
                            >
                                <div className="img-container">
                                    <img
                                        src={deliveredImg}
                                        alt="delivered image"
                                    />
                                </div>
                                <div className="txt-small">Cancelled</div>
                                <div className="txt-small">
                                    {moment(selectedOrder.updated_at, [
                                        "YYYY-MM-DD HH:mm:ss"
                                    ]).format("MMM D")}
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className="margin-top-20 txt-medium view-items pad-top-20">
                    <div className="margin-bottom-10">
                        <span className="margin-right-10 txt-cerapro-bold txt-17-px">
                            #{selectedOrder.reference_number}
                        </span>
                        <span className="txt-small">
                            {moment(selectedOrder.date_placed, [
                                "YYYY-MM-DD HH:mm:ss"
                            ]).format("MMM D")}
                        </span>

                        {selectedOrder.status != "void" ? (
                            <span className="float-right txt-light-green">
                                {selectedOrder.fulfillment_date
                                    ? selectedOrder.shipment_date
                                        ? selectedOrder.delivered_date
                                            ? "Delivered"
                                            : "To Receive"
                                        : "To Ship"
                                    : "Processing"}
                            </span>
                        ) : (
                            <span className="float-right txt-light-green">
                                Cancelled
                            </span>
                        )}
                    </div>
                    <div className="flex justify-content-between align-items-center">
                        {selectedOrder.items.map((item, key) => (
                            <Fragment key={key}>
                                <div className="flex flex-wrap">
                                    <img
                                        style={{
                                            width: "50px",
                                            height: "50px"
                                        }}
                                        src={extractAssetObj(item.product, [
                                            "image_paths",
                                            "path"
                                        ])}
                                        alt={item.product.title}
                                    />
                                    <div>
                                        <div className="txt-small">
                                            {item.product.title}
                                        </div>
                                        <div className="txt-small">
                                            x{item.quantity}
                                        </div>
                                    </div>
                                </div>
                                {selectedOrder.status != "void" && (
                                    <div className="float-right">
                                        {item.order.delivered_date ? (
                                            <button
                                                onClick={() => {
                                                    setProductId(
                                                        item.product.id
                                                    );
                                                    setToggleModal(true);
                                                }}
                                                className="btn btn-primary"
                                            >
                                                Rate
                                            </button>
                                        ) : (
                                            <>
                                                {!item.order.shipment_date && (
                                                    <button
                                                        className="btn btn-lgray-white ml-1"
                                                        onClick={() =>
                                                            handleOrderCancel(
                                                                item.order
                                                                    .reference_number
                                                            )
                                                        }
                                                    >
                                                        Cancel
                                                    </button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}
                            </Fragment>
                        ))}
                    </div>
                </div>

                <div className="flex justify-content-end margin-top-20 margin-bottom-15 pad-top-20 price-container txt-medium">
                    <div className="flex flex-column align-items-end">
                        <span>Subtotal</span>
                        <span>Shipping</span>
                        <span className="txt-cerapro-bold">Order Total</span>
                    </div>
                    <div className="flex flex-column align-items-end">
                        <span>
                            {selectedOrder.currency.symbol}
                            {selectedOrder.subtotal}
                        </span>
                        <span>
                            {selectedOrder.currency.symbol}
                            {selectedOrder.shipping_amount}
                        </span>
                        <span className="txt-cerapro-bold">
                            {selectedOrder.currency.symbol}
                            {selectedOrder.total}
                        </span>
                    </div>
                </div>

                <div className="customer-info pad-10 txt-12-px">
                    <div>
                        <h5>Contact information</h5>
                        <p>{selectedOrder.email_address}</p>
                    </div>
                    <div>
                        <h5>Payment Method</h5>
                        <p>{selectedOrder.payment_type.name}</p>
                    </div>
                    <div>
                        <h5>Shipping Address</h5>
                        <p>
                            {selectedOrder.shipping_address.firstname}{" "}
                            {selectedOrder.shipping_address.lastname}
                        </p>
                        <p>{selectedOrder.shipping_address.company}</p>
                        <p>{selectedOrder.shipping_address.address_line_1}</p>
                        <p>{selectedOrder.shipping_address.address_line_2}</p>
                        <p>{selectedOrder.shipping_address.area_name}</p>
                        <p>{selectedOrder.shipping_address.city_name}</p>
                        <p>{selectedOrder.shipping_address.province_name}</p>
                        <p>{selectedOrder.shipping_address.country_name}</p>
                        <p>{selectedOrder.shipping_address.phone}</p>
                    </div>
                    <div>
                        <h5>Billing Address</h5>
                        <p>
                            {selectedOrder.billing_address.firstname}{" "}
                            {selectedOrder.billing_address.lastname}
                        </p>
                        <p>{selectedOrder.billing_address.company}</p>
                        <p>{selectedOrder.billing_address.address_line_1}</p>
                        <p>{selectedOrder.billing_address.address_line_2}</p>
                        <p>{selectedOrder.billing_address.area_name}</p>
                        <p>{selectedOrder.billing_address.city_name}</p>
                        <p>{selectedOrder.billing_address.province_name}</p>
                        <p>{selectedOrder.billing_address.country_name}</p>
                        <p>{selectedOrder.billing_address.phone}</p>
                    </div>
                    <div>
                        <h5>Shipping Method</h5>
                        <p>{selectedOrder.delivery_type.name}</p>
                    </div>
                </div>
            </div>
            <div className="have-question py-4 pad-left-30 pad-right-30 flex justify-content-between align-items-center flex-wrap justify-content-center">
                <div>Have questions?</div>
                <div>
                    <Link to="/faqs" className="btn btn-lyellow">
                        View FAQ
                    </Link>
                    <Link to="/contact" className="btn btn-lyellow ml-3">
                        Contact Us
                    </Link>
                </div>
            </div>
        </>
    );
};

export default OrderHistoryView;
