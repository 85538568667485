import PropTypes from "prop-types";
import React from "react";
import landscapeYellow from "../../../assets/img/landscape_yellow.png";
import sliderLeafLeft from "../../../assets/img/slider-leaf-left.png";
import sliderLeafRight from "../../../assets/img/slider-leaf-right.png";
import sliderLowerLeft from "../../../assets/img/slider-lower-left.png";
import sliderLowerRight from "../../../assets/img/slider-lower-right.png";
import Skeleton from "../Elements/Skeleton";

const FloatingSliderSkeleton = ({ hasBgColor = false }) => {
    return (
        <div
            className={`floating-slider pad-bottom-100 ${hasBgColor &&
                "bg-green"}`}
        >
            <div className="white-bottom">
                <img className="landscape" src={landscapeYellow} />
            </div>

            <div className="container">
                <div className="items position-relative">
                    <img
                        className="floating-leaf left left-slider-leaf faded-9"
                        src={sliderLeafLeft}
                    />
                    <img
                        className="floating-leaf right right-slider-leaf faded-9"
                        src={sliderLeafRight}
                    />
                    <img
                        className="floating-leaf left left-slider-lower faded-light"
                        src={sliderLowerLeft}
                    />
                    <img
                        className="floating-leaf right right-slider-lower faded-light"
                        src={sliderLowerRight}
                    />

                    <Skeleton height="450px" />
                </div>
            </div>
        </div>
    );
};

FloatingSliderSkeleton.propTypes = {
    hasBgColor: PropTypes.bool
};

export default FloatingSliderSkeleton;
