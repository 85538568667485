import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
    extractAssetObj,
    getBanks,
    resetCart,
    resetOrder
} from "../../helpers/caboodleHelper";
import History from "../../History";

const OrderStatus = ({ order, location, address_form }) => {
    const [banks, setBanks] = useState({});
    useEffect(() => {
        if (
            !order.hasOwnProperty("reference_token") ||
            order.status === "checkout"
        ) {
            History.push("/");
        }

        if (order && order.payment_type_id == 1) {
            getBanks().then(res => {
                if (res.data) {
                    setBanks(res.data);
                }
            });
        }

        return () => {
            if (
                order &&
                order.status === "placed" &&
                location.pathname.indexOf("success") > -1
            ) {
                resetOrder();
                resetCart();
            }
        };
    }, []);

    if (!order.hasOwnProperty("reference_token")) return null;

    return (
        <div className="order-status-page pad-bottom-100">
            <div className="container">
                <h1 className="py-5 txt-cerapro-bold txt-header-2">
                    Payment Confirmation
                </h1>
                <div className="row">
                    <div className="col-sm-8">
                        <div className="bg-yellow pad-10 margin-bottom-30">
                            <h5 className="txt-cerapro-bold txt-14-px">
                                Thank you for ordering!
                            </h5>
                            <div className="txt-green txt-large">
                                Order #{order.reference_number}
                            </div>
                            <div className="txt-gray-med-light txt-14-px">
                                You'll receive a confirmation email with your
                                order number shortly
                            </div>
                        </div>

                        <hr />
                        {banks && banks.length > 0 && (
                            <div className="my-2 mb-3">
                                <h5 className="txt-cerapro-bold txt-14-px">
                                    Banks{" "}
                                    <span className="txt-cerapro-regular txt-14-px">
                                        ( Bank details are also included in the
                                        email confirmation. )
                                    </span>
                                </h5>

                                <div className="row">
                                    {banks.map((bank, idx) => (
                                        <div
                                            className="col-sm-4 txt-14-px"
                                            key={idx}
                                        >
                                            <div className="card p-2">
                                                <h5 className="txt-cerapro-bold">
                                                    {bank.bank_name}
                                                </h5>
                                                <p>
                                                    Account Name:{" "}
                                                    {bank.account_name}
                                                </p>
                                                <p>
                                                    Account Number:{" "}
                                                    {bank.account_number}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <h5 className="txt-cerapro-bold txt-14-px">
                            Information Summary
                        </h5>

                        <div className="customer-info pad-10">
                            <div>
                                <h5>Contact information</h5>
                                <p>{address_form.shipping.email}</p>
                            </div>
                            <div>
                                <h5>Payment Method</h5>
                                <p>{order.payment_type.name}</p>
                            </div>
                            <div>
                                <h5>Shipping Address</h5>
                                <p>
                                    {address_form.shipping.firstname}{" "}
                                    {address_form.shipping.lastname}
                                </p>
                                <p>{address_form.shipping.company}</p>
                                <p>{address_form.shipping.address_line_1}</p>
                                <p>{address_form.shipping.address_line_2}</p>
                                <p>{address_form.shipping.area_name}</p>
                                <p>{address_form.shipping.city_name}</p>
                                <p>{address_form.shipping.province_name}</p>
                                <p>{address_form.shipping.country_name}</p>
                                <p>{address_form.shipping.phone}</p>
                            </div>
                            <div>
                                <h5>Billing Address</h5>
                                <p>
                                    {address_form.billing.firstname}{" "}
                                    {address_form.billing.lastname}
                                </p>
                                <p>{address_form.billing.company}</p>
                                <p>{address_form.billing.address_line_1}</p>
                                <p>{address_form.billing.address_line_2}</p>
                                <p>{address_form.billing.area_name}</p>
                                <p>{address_form.billing.city_name}</p>
                                <p>{address_form.billing.province_name}</p>
                                <p>{address_form.billing.country_name}</p>
                                <p>{address_form.billing.phone}</p>
                            </div>
                            {order.delivery_type && order.delivery_type.name && (
                                <div>
                                    <h5>Shipping Method</h5>
                                    <p>{order.delivery_type.name}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="order-summary">
                            <div>
                                <h4 className="txt-cerapro-medium txt-medium margin-bottom-20">
                                    Order Summary
                                </h4>

                                <div className="items">
                                    {order.items.map((item, idx) => (
                                        <div
                                            key={idx}
                                            className="item margin-bottom-20"
                                        >
                                            <img
                                                src={extractAssetObj(
                                                    item.product,
                                                    [
                                                        "image_paths",
                                                        "small_thumbnail"
                                                    ]
                                                )}
                                                alt={item.product.title}
                                            />
                                            <div>
                                                <h5 className="txt-small margin-top">
                                                    {item.product.title}
                                                </h5>
                                                <div className="txt-small">
                                                    x {item.quantity}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <hr />

                                {/* SUBTOTAL */}
                                <div className="flex justify-between">
                                    <div>Subtotal</div>
                                    <div>{`${order.currency.symbol}${parseFloat(
                                        order.subtotal
                                    ) +
                                        parseFloat(
                                            order.discount_amount
                                        )}`}</div>
                                </div>

                                {/* SHIPPING FEE */}
                                <div className="flex justify-between">
                                    <div>Shipping</div>

                                    <div>{`${order.currency.symbol}${order.shipping_amount}`}</div>
                                </div>

                                {/* DISCOUNTS */}
                                {order.discount_amount > 0 && (
                                    <div className="flex justify-between margin-top-10">
                                        <div>Discount</div>
                                        <div className="text-danger">{`-${order.currency.symbol}${order.discount_amount}`}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="order-summary margin-top-10">
                            <div>
                                <div className="flex justify-between">
                                    <div>Order Total</div>
                                    <div>{`${order.currency.symbol}${order.total}`}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        order: state.order,
        address_form: state.address_form
    };
}

export default connect(mapStateToProps, null)(OrderStatus);
