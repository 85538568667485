import React from "react";
import Skeleton from "../../_global/Elements/Skeleton";

const CheckoutSkeleton = () => {
    return (
        <div className="checkout-page pad-bottom-100">
            <div className="container">
                <div className="breadcrumbs margin-top-30 margin-bottom-30 txt-medium">
                    <Skeleton width="50%" />
                </div>
                <h1 className="txt-cerapro-bold text-capitalize txt-header">
                    <Skeleton width="200px" height="48px" />
                </h1>
                <div className="row">
                    <div className="col-sm-8">
                        <Skeleton height="40px" className="mb-3" />
                        <Skeleton height="40px" className="mb-3" />
                        <Skeleton height="40px" className="mb-3" />
                        <Skeleton height="40px" className="mb-3" />
                        <Skeleton height="40px" className="mb-3" />
                        <Skeleton height="40px" className="mb-3" />
                        <Skeleton height="40px" className="mb-3" />
                        <Skeleton height="40px" className="mb-3" />
                    </div>
                    <div className="col-sm-4">
                        <div className="order-summary">
                            <div>
                                <h4 className="txt-cerapro-medium txt-medium margin-bottom-20">
                                    Order Summary
                                </h4>

                                <div className="items">
                                    <Skeleton className="mb-3" height="30px" />
                                </div>
                                <Skeleton className="mb-3" height="20px" />

                                <hr />
                                <Skeleton
                                    className="mb-3/>
                                <Skeleton className="
                                    mb-3
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckoutSkeleton;
