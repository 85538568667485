import React from "react";
import { withRouter, Router, Switch } from "react-router-dom";
import Home from "./modules/Home/Home";
import ProductView from "./modules/ProductView/ProductView";
import About from "./modules/About/About";
import Terms from "./modules/Terms/Terms";
import Faqs from "./modules/Faqs/Faqs";
import Journals from "./modules/Journals/Journals";
import JournalView from "./modules/Journals/JournalView";
import Blogs from "./modules/Blogs/Blogs";
import BlogView from "./modules/Blogs/BlogView";
import CollectionView from "./modules/CollectionView/CollectionView";
import ContactUs from "./modules/ContactUs/ContactUs";
import Account from "./modules/Account/Account";
import Products from "./modules/Products/Products";
import Checkout from "./modules/Checkout/Checkout";
import OrderStatus from "./modules/OrderStatus/OrderStatus";
import ChangePassword from "./modules/_global/Elements/ChangePassword";
import UserActivation from "./modules/_global/UserActivation";
import Brands from "./modules/Brands/Brands";
import Layout from "./modules/Layout";
import Login from "./modules/_global/Login/Login";
// import ComingSoon from './modules/_global/Extras/ComingSoon';
import history from "./History";
import Registration from "./modules/_global/Registration/Registration";

const Routes = () => (
    <Router history={history}>
        <Switch>
            <Layout exact path="/" component={Home} />
            <Layout path="/products" component={Products} />
            <Layout path="/about" component={About} />
            <Layout path="/faqs" component={Faqs} />
            <Layout exact path="/journals" component={Journals} />
            <Layout exact path="/journals/:slug/view" component={JournalView} />
            <Layout path="/terms-and-conditions" component={Terms} />
            <Layout path="/privacy-policy" component={Terms} />
            <Layout path="/contact" component={ContactUs} />
            <Layout path="/collection/:slug" component={CollectionView} />
            <Layout exact path="/blogs" component={Blogs} />
            <Layout exact path="/blogs/:slug/view" component={BlogView} />
            <Layout path="/product/:slug/view" component={ProductView} />
            <Layout path="/checkout/:slug?" component={Checkout} />
            <Layout exact path="/manage-account/:section" component={Account} />
            <Layout path="/order/:status?" component={OrderStatus} />
            <Layout path="/password/reset/:token?" component={ChangePassword} />
            <Layout path="/activate" component={UserActivation} />
            <Layout path="/brands" component={Brands} />
            <Layout path="/login" component={Login} />
            <Layout path="/registration" component={Registration} />
            {/* <ComingSoon path="/coming-soon" /> */}
        </Switch>
    </Router>
);
export default withRouter(Routes);
