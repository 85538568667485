import React from 'react';
// import {NavLink} from 'react-router-dom';
import TextInput from '../Form/TextInput';
import SuccessMessage from '../Modal/SuccessMessage';
import {
            validateFormObj,
            changePassword,
            toggleModal,
            setModalContent
        } from '../../../helpers/caboodleHelper';

import history from '../../../History';
class ChangePassword extends React.PureComponent {

    state = {
        resetForm: {
            password: ""
        },
        isLaoding: false,
        errorMessage: ''
    }

    onInputChange = (e) => {
        let resetForm = Object.assign({}, this.state.resetForm);
        resetForm[e.target.name] = e.target.value;

        this.setState({
            resetForm
        });
    }

    submitChangePassword = () => {
        // eslint-disable-next-line
        const resetForm = Object.assign({}, this.state.resetForm),
            uri = this.props.location.pathname.split('/');
        
        this.setState({
            isLoading: true,
            errorMessage: ''
        }, () => {
            changePassword({password: resetForm.password, token: uri[uri.length - 1]})
                .then(res => {
                    const successMessage = (
                        <SuccessMessage
                            title="Successful!"
                            message="You can now login with your new password."
                        />
                    );
                    this.setState({
                        resetForm: {
                            password: ""
                        },
                        isLoading: false,
                        errorMessage: ''
                    }, () => {
                        setModalContent(false, false, successMessage);
                        toggleModal(true);
                        history.replace('/');
                    });

                }).catch(err => {
                    this.setState({
                        isLoading: false,
                        errorMessage: "Something went wrong with the connection."
                    });
                });
        });
    }

    render() {
        return (
            <div className="change-password-form">
                <div>
                    <div className="txt-xlarge txt-dark-link pad-20 text-center">
                        Change Password
                    </div>
                    <div className="login-form">
                        <TextInput
                            placeholder="Enter your new password here..."
                            name="password"
                            type="password"
                            value={this.state.resetForm.password}
                            onChange={this.onInputChange}
                            onKeyEnter={validateFormObj(this.state.resetForm) && this.state.resetForm.password.length > 7 ? this.submitChangePassword : false}
                            autoComplete="new-password"
                        />
                        <div className="txt-medium text-center txt-error">
                            {this.state.errorMessage ? this.state.errorMessage : 'Your password must be atleast 8 character long.'}
                        </div>
                        <div className="text-center margin-top-30">
                            <button
                                className="btn btn-primary btn-block btn-block-center btn-pad-xlarge txt-uppercase submit-btn"
                                disabled={!validateFormObj(this.state.resetForm) || this.state.isLoading || this.state.resetForm.password.length < 8}
                                onClick={() => this.submitChangePassword()}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
       )
    }
};

export default ChangePassword;