import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import Slider from "react-slick";
import {
    extractAssetObj,
    getPage,
    getPageItemsSeeMore
} from "../../helpers/caboodleHelper";
import TitleLeaf from "../_global/Elements/TitleLeaf";
import FloatingSlider from "../_global/FloatingSlider/FloatingSlider";
import AboutSkeleton from "./AboutSkeleton";
import axios from "axios";

const About = ({ page }) => {
    const cancelToken = useRef(null);

    const about = page.about ? page.about : undefined;
    // const testimonials = page.testimonials ? page.testimonials : undefined;

    const [postsToShow, setPostsToShow] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [disableButton, setDisableButton] = useState(false);
    const [hasLoadMore, setHasLoadMore] = useState(false);

    useEffect(() => {
        cancelToken.current = axios.CancelToken.source();

        getPage("about", cancelToken.current);
        // getPage("testimonials", cancelToken.current);

        return () => {
            if (cancelToken.current) cancelToken.current.cancel();
            cancelToken.current = null;
        };
    }, []);

    useEffect(() => {
        getPageItemsSeeMore("testimonials", { page: pageNumber })
            .then(res => {
                if (res.value && res.value.items.length > 0) {
                    let items = res.value.items;
                    setPostsToShow(prevPostsToShow => [
                        ...prevPostsToShow,
                        ...items
                    ]);
                    checkLastPage(res.value.data.last_page);
                }
            })
            .finally(() => setDisableButton(false));
    }, [pageNumber]);

    const handleLoadMore = () => {
        setPageNumber(prevPageNumber => prevPageNumber + 1);
        setDisableButton(true);
    };

    const checkLastPage = lastPage => {
        if (lastPage == pageNumber) {
            setHasLoadMore(false);
        } else {
            setHasLoadMore(true);
        }
    };

    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };

    return (
        <div className="about-page bg-light-yellow">
            {about == undefined ? (
                <AboutSkeleton />
            ) : (
                <>
                    {about.banner &&
                        about.banner.items &&
                        about.banner.items.length > 0 && (
                            <div className="banners">
                                <Slider {...settings}>
                                    {about.banner.items.map((item, idx) => (
                                        <div key={idx}>
                                            <div
                                                key={idx}
                                                className="banner end-section pad-top-50 pad-bottom-50 flex align-center"
                                                style={{
                                                    backgroundImage: `url(${extractAssetObj(
                                                        item,
                                                        ["image", "path"]
                                                    )}`
                                                }}
                                            >
                                                <div className="container">
                                                    <div
                                                        className="txt-cerapro-bold txt-upsized txt-shadow"
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.content
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        )}

                    {about.aboutUs && (
                        <div className="about-us margin-top-100 margin-bottom-50">
                            <div className="container">
                                <TitleLeaf
                                    className="txt-header txt-cerapro-bold"
                                    title={about.aboutUs.title}
                                />

                                <div className="content margin-x-auto text-center margin-top-30">
                                    <h3 className="txt-cerapro-bold txt-xxlarge">
                                        {about.aboutUs.subtitle}
                                    </h3>
                                    <div
                                        className="description margin-top-20 txt-medium"
                                        dangerouslySetInnerHTML={{
                                            __html: about.aboutUs.description
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}

            <FloatingSlider
                data={about && about.subBanner ? about.subBanner : undefined}
            />
            {postsToShow.length > 0 && (
                <div className="testimonials pad-bottom-100">
                    <div className="container pad-top-50 pad-bottom-50">
                        <div className="testimonials-section">
                            <div className="latest-news margin-bottom-50">
                                <TitleLeaf
                                    className="txt-header txt-cerapro-bold"
                                    title="Testimonials"
                                />
                            </div>
                            <div className="testimonials-grid margin-bottom-50">
                                {postsToShow.length > 0 &&
                                    postsToShow.map((item, index) => {
                                        let rating =
                                            parseInt(item.rating) > 5
                                                ? 5
                                                : parseInt(item.rating);
                                        return (
                                            <div
                                                key={index}
                                                className={`item flex direction-column align-center justify-between`}
                                            >
                                                <img
                                                    src={extractAssetObj(item, [
                                                        "image",
                                                        "path"
                                                    ])}
                                                />
                                                <div className="flex direction-column align-center">
                                                    <h5 className="txt-17-px txt-cerapro-bold">
                                                        {item.title}
                                                    </h5>
                                                    <div className="stars txt-light-red">
                                                        {[...Array(rating)].map(
                                                            (e, i) => (
                                                                <i
                                                                    key={i}
                                                                    className="fas fa-star"
                                                                />
                                                            )
                                                        )}
                                                        {[
                                                            ...Array(5 - rating)
                                                        ].map((e, i) => (
                                                            <i
                                                                key={i}
                                                                className="far fa-star"
                                                            />
                                                        ))}
                                                    </div>
                                                    <div
                                                        className="txt-14-px txt-cerapro-light text-center"
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.content
                                                        }}
                                                    />
                                                </div>
                                                <h5 className="txt-10-px txt-light-red text-center">
                                                    -{item.author}
                                                </h5>
                                            </div>
                                        );
                                    })}
                            </div>
                            {hasLoadMore && (
                                <button
                                    className="txt-cerapro-medium txt-medium txt-white btn-load"
                                    onClick={() => handleLoadMore()}
                                    disabled={disableButton}
                                >
                                    LOAD MORE
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    page: state.page
});

export default connect(mapStateToProps, null)(About);
