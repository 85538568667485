import React, { useState } from "react";
import { extractAssetObj } from "../../../helpers/caboodleHelper";

const ReviewItems = ({ review }) => {
    const [toggleTruncate, setToggleTruncate] = useState(true);
    let truncateSize = 150;

    const truncateString = str => {
        if (str.length > truncateSize && toggleTruncate)
            str = `${str.slice(0, truncateSize)}...`;

        return `${str} `;
    };

    return (
        <>
            <div className="flex justify-content-between">
                <div className="txt-light-red pb-1 d-inline-flex align-items-center">
                    <div className="customer-icon mr-2 text-uppercase txt-cerapro-bold">
                        {review.customer.firstname.charAt(0)}
                        {review.customer.lastname.charAt(0)}
                    </div>
                    {review.customer.firstname} {review.customer.lastname}
                </div>
                <div>
                    {[...Array(5)].map((e, i) => (
                        <span key={i} className="ml-2 txt-light-red">
                            {i < review.rating ? (
                                <i className="fas fa-star" />
                            ) : (
                                <i className="far fa-star" />
                            )}
                        </span>
                    ))}
                </div>
            </div>
            <div className={`review-content`}>
                {truncateString(review.content)}
                {review.content.length > truncateSize && (
                    <a
                        onClick={() => setToggleTruncate(!toggleTruncate)}
                        className="clickable txt-light-red"
                    >
                        {review.content.length > truncateSize && toggleTruncate
                            ? "read more"
                            : "read less"}
                    </a>
                )}
            </div>
        </>
    );
};

export default ReviewItems;
