import React from "react";
import leafLeft from "../../assets/img/leaf-left.png";
import leafRight from "../../assets/img/leaf-right.png";
import leafTopLeft from "../../assets/img/leaf-top-left.png";
import leafTopRight from "../../assets/img/leaf-top-right.png";
import leftVine from "../../assets/img/left-vine.png";
import rightVine from "../../assets/img/right-vine.png";
import waxLeft from "../../assets/img/wax-left.png";
import waxRight from "../../assets/img/wax-right.png";
import landscapeGreen3 from "../../assets/img/landscape_green3.png";
import leafWaveLeft from "../../assets/img/pngwave-1.png";
import leafWaveRight from "../../assets/img/pngwave.png";

export const HomeTopLeaves = () => {
    return (
        <>
            <img
                className="floating-leaf left left-top faded"
                src={leafTopLeft}
            />
            <img
                className="floating-leaf right right-top faded"
                src={leafTopRight}
            />
            <img
                className="floating-leaf left left-vine faded-light"
                src={leftVine}
            />
            <img
                className="floating-leaf right right-vine faded-light"
                src={rightVine}
            />
            <img
                className="floating-leaf left left-leaf faded-light"
                src={leafLeft}
            />
            <img
                className="floating-leaf right right-leaf faded-light"
                src={leafRight}
            />
            <img
                className="floating-leaf left left-wax faded-light"
                src={waxLeft}
            />
            <img
                className="floating-leaf right right-wax faded-light"
                src={waxRight}
            />
        </>
    );
};

export const Section5Images = () => {
    return (
        <>
            <img
                className="floating-leaf left left-wave2"
                src={leafWaveRight}
            />
            <img
                className="floating-leaf right right-wave2"
                src={leafWaveLeft}
            />
        </>
    );
};

export const OurMissionImages = () => {
    return (
        <>
            <img className="landscape z-index-1" src={landscapeGreen3} />
            <img
                className="floating-leaf left left-wave z-index-1"
                src={leafWaveLeft}
            />
            <img
                className="floating-leaf right right-wave z-index-1"
                src={leafWaveRight}
            />
        </>
    );
};
