import React from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { logoutUser } from "../../helpers/caboodleHelper";
import { isLoggedIn } from "../../helpers/sessionHelper";
import History from "../../History";
import { JournalsUpperLeaves } from "../Journals/FloatingLeaves";
import OrderHistory from "./OrderHistory";
import PersonalData from "./PersonalData";

const Account = ({ location }) => {
    const slug = location.pathname.replace("/manage-account/", "");

    if (!isLoggedIn()) {
        History.push("/");
        return null;
    }

    return (
        <div className="account-page">
            <JournalsUpperLeaves />
            <div className="container pad-bottom-150 pad-top-100">
                <div className="row margin-right-30">
                    <div className="col-md-3 pad-30 nav-container">
                        <ul>
                            <li>
                                <h4>My Account</h4>
                                <ul>
                                    <li>
                                        <NavLink to="/manage-account/personal-data">
                                            Personal Data
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink to="/manage-account/personal-data">
                                            Payment Settings
                                        </NavLink>
                                    </li> */}
                                </ul>
                            </li>
                            <li>
                                <h4>Order Information</h4>
                                <ul>
                                    <li>
                                        <NavLink to="/manage-account/order-history">
                                            Order History
                                        </NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <Link to="#" onClick={() => logoutUser()}>
                                    Sign Out
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-9 content-container">
                        {slug === "order-history" ? (
                            <OrderHistory />
                        ) : (
                            <PersonalData />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(Account);
