import React, { Component } from 'react';

class CustomSelect extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        open: false
    }
    
    render() {
        return (
            <div className={`relative-container custom-dropdown-select ${this.state.open ? "open" : ""} txt-medium-large`}>
                <div
                    className="flex align-center clickable"
                    onClick={() => this.setState({open: !this.state.open})}
                >
                    <div className="flex-1">
                        {this.props.value ?  this.props.value : 'Select here'}
                    </div>
                    <div>
                        <i className="fas fa-caret-down txt-gray-light txt-large margin-left" />
                    </div>
                </div>
                <div className="selection">
                    {
                        this.props.selection.map((option, index) => {
                            return (
                                <div
                                    className="select-option clickable txt-medium-large"
                                    onClick={() => this.handleValueSelect(option)}
                                    key={index}
                                >
                                    {option.name}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    handleValueSelect = (option) => {
        this.setState({
            open: !this.state.open
        }, () => {
            this.props.onSelect(option);
        });
    }
}

export default CustomSelect;