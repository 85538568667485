export const FULFILLED = "_FULFILLED";
export const REJECTED = "_REJECTED";
export const PENDING = "_PENDING";

export const GET_PAGE = "GET_PAGE";

export const GET_PARENTS_CATEGORY = "GET_PARENTS_CATEGORY";
export const GET_SELECTED_CATEGORY = "GET_SELECTED_CATEGORY";
export const SET_NEW_PARENTS_CATEGORY = "SET_NEW_PARENTS_CATEGORY";
export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";

export const LOAD_PRODUCTS = "LOAD_PRODUCTS";
export const LOAD_RELATED_PRODUCTS = "LOAD_RELATED_PRODUCTS";
export const PRODUCTS_GET_FEATURED = "PRODUCTS_GET_FEATURED";
export const PRODUCTS_GET_NEW_ARRIVAL = "PRODUCTS_GET_NEW_ARRIVAL";
export const PRODUCTS_GET_BEST_SELLER = "PRODUCTS_GET_BEST_SELLER";
export const CHANGE_PRODUCT_OBJECT = "CHANGE_PRODUCT_OBJECT";
export const ADD_TO_WISHLIST = "ADD_TO_WISHLIST";
export const REMOVE_TO_WISHLIST = "REMOVE_TO_WISHLIST";
export const LOAD_WISHLISTED_PRODUCTS = "LOAD_WISHLISTED_PRODUCTS";
export const UNSELECT_PRODUCT = "UNSELECT_PRODUCT";
export const FIND_PRODUCT = "FIND_PRODUCT";
export const GET_PRODUCT_BREADCRUMBS = "GET_PRODUCT_BREADCRUMBS";
export const FIND_BY_TAG = "FIND_BY_TAG";
export const UPDATE_PRODUCT_REVIEW = "UPDATE_PRODUCT_REVIEW";
export const ADD_TO_RECENT_VIEWS = "ADD_TO_RECENT_VIEWS";
export const GET_VIEWED_PRODUCTS = "GET_VIEWED_PRODUCTS";
export const GET_BUNDLES = "GET_BUNDLES";

export const UNSELECT_PRODUCT_REVIEW = "UNSELECT_PRODUCT_REVIEW";
export const GET_PRODUCT_REVIEW = "GET_PRODUCT_REVIEW";
export const UPDATE_REVIEW_LIST = "UPDATE_REVIEW_LIST";

export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER = "UPDATE_USER";
export const CONFIRM_USER = "CONFIRM_USER";
export const GET_USER_CREDITS = "GET_USER_CREDITS";
export const CONNECT_SOCIAL_MEDIA = "CONNECT_SOCIAL_MEDIA";

export const SET_CART = "SET_CART";
export const RESET_CART = "RESET_CART";
export const SYNC_USER_CART = "SYNC_USER_CART";
export const CART_UPDATE_ITEMS = "CART_UPDATE_ITEMS";
export const SYNC_ORDER_TO_CART = "SYNC_ORDER_TO_CART";
export const UPDATE_ORDER_OBJECT = "UPDATE_ORDER_OBJECT";
export const GET_SHIPPING_FEE = "GET_SHIPPING_FEE";
export const RESET_ORDER = "RESET_ORDER";

export const COUNTRY_LIST = "COUNTRY_LIST";
export const PROVINCE_LIST = "PROVINCE_LIST";
export const CITY_LIST = "CITY_LIST";
export const AREA_LIST = "AREA_LIST";
export const GET_BRANCH_LIST = "GET_BRANCH_LIST";

export const CHANGE_SHIPPING_FORM_STATE = "CHANGE_SHIPPING_FORM_STATE";
export const RESET_SHIPPING_FROM = "RESET_SHIPPING_FROM";

export const CHANGE_BILLING_FORM_STATE = "CHANGE_BILLING_FORM_STATE";
export const RESET_BILLING_FROM = "RESET_BILLING_FROM";

export const GET_SHIPPING_METHODS = "GET_SHIPPING_METHODS";
export const SET_SHIPPING_METHOD = "SET_SHIPPING_METHOD";

export const GET_COUPON = "GET_COUPON";

export const SET_MODAL_CONTENT = "SET_MODAL_CONTENT";
export const TOGGLE_MODAL = "TOGGLE_MODAL";

export const GET_SHIPPING_ZONE_LIST = "GET_SHIPPING_ZONE_LIST";

export const GET_SITE_OPTION = "GET_SITE_OPTION";

export const GET_BLOCK_OFF_DATES = "GET_BLOCK_OFF_DATES";

//COLLECTIONS
export const GET_FEATURED_COLLECTIONS = "GET_FEATURED_COLLECTIONS";
export const UPDATE_SELECTED_COLLECTION = "UPDATE_SELECTED_COLLECTION";

//BLOGS
export const SET_SELECTED_BLOG = "SET_SELECTED_BLOG";
export const GET_PAGES_CATEGORY = "GET_PAGES_CATEGORY";
export const GET_PAGE_ITEMS = "GET_PAGE_ITEMS";

//BRANDS
export const GET_BRANDS_LIST = "GET_BRANDS_LIST";

//DISCOUNTS
export const GET_ACTIVE_FLASH_SALE = "GET_ACTIVE_FLASH_SALE";

//CUSTOMER
export const GET_CUSTOMER_ADDRESS = "GET_CUSTOMER_ADDRESS";
export const SET_CUSTOMER_PRIMARY_ADDRESS = "SET_CUSTOMER_PRIMARY_ADDRESS";
// export const CREATE_CUSTOMER_ADDRESS = "CREATE_CUSTOMER_ADDRESS";
// export const UPDATE_CUSTOMER_ADDRESS = "UPDATE_CUSTOMER_ADDRESS";
export const DELETE_CUSTOMER_ADDRESS = "DELETE_CUSTOMER_ADDRESS";
export const ORDER_REQUEST_SENT = "ORDER_REQUEST_SENT";
export const TOGGLE_WHOLE_PAGE_LOADER = "TOGGLE_WHOLE_PAGE_LOADER";

// ADDRESS
export const GET_ADDRESS = "GET_ADDRESS";
export const SET_SHIPPING_TO_BILLING = "SET_SHIPPING_TO_BILLING";
export const RESET_ADDRESSES = "RESET_ADDRESSES";
export const SET_SHIPPING_TO_BILLING_2 = "SET_SHIPPING_TO_BILLING_2";
export const SET_SELECTED_BILLING = "SET_SELECTED_BILLING";
export const RESET_ADDRESS_FORM = "RESET_ADDRESS_FORM";
export const SET_CUSTOMER_ADDRESS_TO_ADDRESS_FORM =
    "SET_CUSTOMER_ADDRESS_TO_ADDRESS_FORM";
