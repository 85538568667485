import * as types from '../constants/actionTypes';
import initialState from './initialState';

export default function (state = initialState.wholePageLoader, action) {
    switch(action.type) {
        case types.TOGGLE_WHOLE_PAGE_LOADER:
            return Object.assign({},
                state, 
                {
                    show: action.payload.show
                }
            );
        default: 
            return state;
    }
}