import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Dropdown from "reactstrap/lib/Dropdown";
import DropdownMenu from "reactstrap/lib/DropdownMenu";
import DropdownToggle from "reactstrap/lib/DropdownToggle";
import { logoutUser } from "../../../../helpers/caboodleHelper";
import { getUser } from "../../../../helpers/sessionHelper";

const ProfileDropDown = () => {
    const [profileOpen, setProfileOpen] = useState(false);

    return (
        <Dropdown
            className="profile-dropdown hide-md"
            isOpen={profileOpen}
            toggle={e => setProfileOpen(!profileOpen)}
            onClick={e => setProfileOpen(!profileOpen)}
        >
            <DropdownToggle>
                <i className="fas fa-user" />{" "}
                {`${getUser().customer.firstname} ${
                    getUser().customer.lastname
                }`}
                <i className="fas fa-caret-down margin-left-10" />
            </DropdownToggle>
            <DropdownMenu>
                <ul>
                    <li>
                        <NavLink to="/manage-account/personal-data">
                            My Account
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/manage-account/order-history">
                            Orders
                        </NavLink>
                    </li>
                    {/* <li>
                        <NavLink to="#">Help</NavLink>
                    </li> */}
                    <li>
                        <NavLink to="#" onClick={() => logoutUser()}>
                            Sign Out
                        </NavLink>
                    </li>
                </ul>
            </DropdownMenu>
        </Dropdown>
    );
};

export default ProfileDropDown;
