import moment from "moment";
import * as apiHelper from "../helpers/apiHelper";
import { store } from "../index";
import * as types from "../constants/actionTypes";
import history from "../History";

export default class ProductFactory {
    constructor({ product = {}, parentProduct = {} }) {
        this.product = product;
        this.parentProduct = parentProduct;
    }

    getInventory() {
        const product = Object.assign({}, this.product);

        if (product.enable_inventory == 1 && product.inventory <= 0) {
            return 0;
        } else if (product.enable_inventory == 1 && product.inventory > 0) {
            return product.inventory;
        } else {
            return 999;
        }
    }

    getPrice() {
        const product = Object.assign({}, this.product);
        let price = 0;
        console.log(product);
        if (product && product.price) price = product.price;

        if (this.getDiscount()) price -= this.getDiscount();

        return parseFloat(price).toFixed(2);
    }

    getDiscountPercentage() {
        const product = Object.assign({}, this.product);
        console.log(product);
        let discount = 0;
        if (this.getDiscount()) {
            discount = (100 * this.getDiscount()) / product.price;
        }

        return discount;
    }

    getDiscount() {
        const product = Object.assign({}, this.product);
        let discount = 0;
        let discountInfo = {};

        if (product && product.active_sale && product.active_sale.length > 0) {
            discountInfo = product.active_sale[0];
        }

        if (
            product &&
            product.active_flash_sale &&
            product.active_flash_sale.length > 0
        ) {
            discountInfo = product.active_flash_sale[0];
        }

        if (discountInfo && discountInfo.id && discountInfo.pivot) {
            discount = discountInfo.pivot.discount_value;
        }

        return discount;
    }

    async addToCart({
        cart,
        user,
        quantity = 1,
        overWriteQty = false,
        proceedToCheckout = false
    }) {
        let items = cart.items.slice();
        const product = Object.assign({}, this.product);

        let index = items.findIndex(item => item.product_id == product.id);

        if (index > -1) {
            if (!overWriteQty)
                quantity = parseFloat(items[index].quantity) + quantity;

            if (this.getInventory() < quantity) quantity = this.getInventory();
            if (quantity < 1) quantity = 1;

            items[index].quantity = quantity;
        } else {
            items.push({
                product_id: product.id,
                quantity: quantity,
                product: product
            });
        }

        let params = {
            customer_id: user && user.hasOwnProperty("name") ? user.customer_id : 0,
            source: "Webiste",
            last_device: window.mobilecheck() ? "Mobile" : "Desktop",
            last_active: moment().format("YYYY-MM-DD"),
            items,
            customer_type:
                user && user.hasOwnProperty("name")
                    ? user.customer_active_type
                    : "regular"
        };

        if (user && user.hasOwnProperty("name")) {
            await apiHelper.connect().post("/orders/sync-cart", params);
        }

        if (proceedToCheckout) {
            store.dispatch({
                type: types.SET_CART,
                payload: params
            });

            history.push("/checkout/information");
            return;
        }

        return await store.dispatch({
            type: types.SET_CART,
            payload: params
        });
    }
}
