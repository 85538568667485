import React from "react";
import PropTypes from "prop-types";
import history from "../../../History";
import { useSelector } from "react-redux";
import { setShippingMethod } from "../../../actions/shippingMethods.actions";

const Shipping = ({ order, proceedToNextStep, checkoutFactory }) => {
    const shippingMethods = useSelector(state => state.shippingMethods);
    const address = useSelector(state => state.address_form.shipping);

    if (
        shippingMethods.list.length > 0 &&
        (!shippingMethods.selected.hasOwnProperty("id") ||
            !shippingMethods.list.find(
                method => method.slug == shippingMethods.selected.slug
            ))
    ) {
        setShippingMethod(shippingMethods.list[0]);
    }

    return (
        <div className="shipping">
            <div className="margin-top-30">
                <h5 className="txt-14-px txt-cerapro-medium">
                    Shipping Method
                </h5>

                {shippingMethods &&
                    shippingMethods.list.length > 0 &&
                    shippingMethods.list.map(shippingMethod => (
                        <div
                            key={shippingMethod.id}
                            className={`select-method margin-bottom clickable ${
                                shippingMethods.selected.slug ==
                                shippingMethod.slug
                                    ? "active"
                                    : ""
                            }`}
                            onClick={() => setShippingMethod(shippingMethod)}
                        >
                            <div className="flex align-center">
                                <div className="icon-holder margin-right-20">
                                    <i className="fas fa-check-circle" />
                                </div>
                                <div className="title">
                                    {shippingMethod.name}
                                </div>
                            </div>
                            <div className="price">
                                {`${
                                    order.currency.symbol
                                } ${checkoutFactory.getOnTopShippingMethod(
                                    shippingMethod
                                )}`}
                            </div>
                        </div>
                    ))}
            </div>

            <div className="margin-top-30">
                <h5 className="txt-14-px txt-cerapro-medium">
                    Shipping Summary
                </h5>

                <div className="shipping-summary-item margin-bottom">
                    <div className="title">Contact</div>
                    <div className="content">{address.email}</div>
                    <button
                        className="action"
                        onClick={() => history.push("/checkout/information")}
                    >
                        <span className="txt-medium">Change</span>{" "}
                        <i className="far fa-pen" />
                    </button>
                </div>
                <div className="shipping-summary-item">
                    <div className="title">Ship to</div>
                    <div className="content">
                        {address.address_line_1}, {address.area_name},{" "}
                        {address.city_name}, {address.province_name},
                        Philippines
                    </div>
                    <button
                        className="action"
                        onClick={() => history.push("/checkout/information")}
                    >
                        <span className="txt-medium">Change</span>{" "}
                        <i className="far fa-pen" />
                    </button>
                </div>
            </div>
            <div className="margin-top-30 pb-3 d-flex justify-content-end">
                <button
                    className="btn btn-primary"
                    onClick={() => proceedToNextStep()}
                >
                    Continue to Payment
                </button>
            </div>
        </div>
    );
};

Shipping.propTypes = {
    proceedToNextStep: PropTypes.func,
    checkoutFactory: PropTypes.object
};

export default Shipping;
