import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Label, Input, FormFeedback } from "reactstrap";

const TextInput = ({
    formGroupClassName = "",
    label = "",
    error = undefined,
    onKeyEnter = false,
    ...props
}) => {
    const validation = {};
    if (error) {
        validation.invalid = true;
    }
    return (
        <FormGroup className={formGroupClassName}>
            {label && <Label>{label}</Label>}
            <Input
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        onKeyEnter && onKeyEnter();
                    }
                }}
                {...validation}
                {...props}
            />
            {error && validation.invalid && (
                <FormFeedback>{error}</FormFeedback>
            )}
        </FormGroup>
    );
};

TextInput.propTypes = {
    onKeyEnter: PropTypes.func,
    label: PropTypes.string,
    error: PropTypes.string,
    valid: PropTypes.bool
};

export default TextInput;
