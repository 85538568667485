import React, { useState } from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import { Link } from "react-router-dom";
import { setCustomerAddressToAddressForm } from "../../../actions/address_form.actions";
import google from "../../../assets/img/google.png";
import * as config from "../../../constants/config";
import {
    loginUser,
    userSocialMediaLogin
} from "../../../helpers/caboodleHelper";
import { isLoggedIn } from "../../../helpers/sessionHelper";
import { validate } from "../../../helpers/validationHelper";
import History from "../../../History";
import { JournalsUpperLeaves } from "../../Journals/FloatingLeaves";
import TitleLeaf from "../Elements/TitleLeaf";
import TextInput from "../Form/TextInput";
import ForgotPassword from "./ForgotPassword";

const Login = () => {
    const initialForm = { email: "", password: "" };

    const [socialMediaOpen, setSocialMediaOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(initialForm);
    const [errorMessage, setErrorMessage] = useState(null);

    const schema = {
        properties: {
            email: {
                type: "string",
                required: true,
                allowEmpty: false
            },
            password: {
                type: "string",
                required: true,
                allowEmpty: false
            }
        }
    };

    if (isLoggedIn()) History.push("/");

    const handleSocialMediaLogin = (response, socialMedia) => {
        let creds = {
            social_media_name: socialMedia
        };
        if (socialMedia === "facebook") {
            creds.oauth_id = response.id;
            creds.firstname = response.first_name;
            creds.lastname = response.last_name;
            creds.email = response.email;
        } else {
            const { profileObj } = response;
            creds.oauth_id = profileObj.googleId;
            creds.firstname = profileObj.givenName;
            creds.lastname = profileObj.familyName;
            creds.email = profileObj.email;
        }
        creds.api_response = JSON.stringify(response);
        userSocialMediaLogin(creds)
            .then(res => {})
            .catch(err => {
                setErrorMessage("Something went wrong! Try again later.");
            });
    };

    const handleFormChange = e => {
        let name = e.target.name,
            value = e.target.value;

        setForm({ ...form, [name]: value });
    };

    const handleLogin = () => {
        if (!isFormValid())
            return setErrorMessage("Email and password is required.");

        setIsLoading(true);
        setErrorMessage("");

        loginUser(form)
            .then(res => {
                setForm(initialForm);
                setErrorMessage("");
                setCustomerAddressToAddressForm();
                History.push("/");
            })
            .catch(err => {
                if (err.status === 400) {
                    setErrorMessage(err.data.message);
                } else {
                    setErrorMessage("Something went wrong! Try again later.");
                }
            })
            .finally(() => setIsLoading(false));
    };

    const isFormValid = () => {
        let validation = validate(form, schema);

        return validation.valid;
    };

    return (
        <div className="login-container pad-bottom-50">
            <JournalsUpperLeaves />
            <div className="login pad-top-100">
                <TitleLeaf
                    className="txt-header txt-cerapro-bold"
                    title="Log In"
                />

                <div className="login-form">
                    <TextInput
                        placeholder="Email Address"
                        name="email"
                        value={form.email}
                        onChange={handleFormChange}
                        onKeyEnter={handleLogin}
                    />
                    <TextInput
                        placeholder="Password"
                        type="password"
                        name="password"
                        value={form.password}
                        onChange={handleFormChange}
                        onKeyEnter={handleLogin}
                        autoComplete="new-password"
                    />
                    <div className="text-center txt-error txt-small">
                        {errorMessage}
                    </div>
                    <div className="flex align-center login-options-container">
                        <div className="flex align-center remember-option">
                            {/* <Checkbox
                                onChange={this.onInputChange}
                                name="remember"
                                material-checkbox="primary"
                            />
                            <span className="txt-small">
                                Remember Me
                            </span> */}
                        </div>
                        <div className="text-right flex-1 forgot-password">
                            <ForgotPassword />
                        </div>
                    </div>
                    <div className="separator">
                        <span>or</span>
                    </div>
                    <div className="flex align-center margin-top-20 gap-20">
                        <div className="flex-1">
                            <FacebookLogin
                                appId={config.FB_ID}
                                autoLoad={false}
                                fields="first_name,last_name,name,email,picture"
                                scope="public_profile,email"
                                callback={res =>
                                    handleSocialMediaLogin(res, "facebook")
                                }
                                onFailure={err => setSocialMediaOpen(false)}
                                disableMobileRedirect={true}
                                render={renderProps => (
                                    <button
                                        className="btn btn-green btn-block btn-block-center"
                                        style={{ paddingLeft: "17px" }}
                                        onClick={() => {
                                            setSocialMediaOpen(true);
                                            renderProps.onClick();
                                        }}
                                        disabled={socialMediaOpen || isLoading}
                                    >
                                        <i className="margin-right fab fa-facebook-f" />{" "}
                                        Via Facebook
                                    </button>
                                )}
                            />
                        </div>
                        <div className="flex-1">
                            <GoogleLogin
                                clientId={config.GOOGLE_ID}
                                buttonText="Login"
                                onSuccess={res =>
                                    handleSocialMediaLogin(res, "google")
                                }
                                onFailure={err => setSocialMediaOpen(false)}
                                cookiePolicy={"single_host_origin"}
                                render={renderProps => (
                                    <button
                                        className="btn btn-green btn-block btn-block-center"
                                        onClick={() => {
                                            setSocialMediaOpen(true);
                                            renderProps.onClick();
                                        }}
                                        disabled={socialMediaOpen || isLoading}
                                    >
                                        <img
                                            src={google}
                                            className="margin-right"
                                            width="24px;"
                                        />{" "}
                                        Via Gmail
                                    </button>
                                )}
                            />
                        </div>
                    </div>
                    <div className="text-center">
                        <button
                            className="btn btn-green btn-block btn-block-center submit-btn"
                            onClick={handleLogin}
                            disabled={isLoading || socialMediaOpen}
                        >
                            Log in
                        </button>
                    </div>
                    <div className="registration-link txt-small flex justify-content-between">
                        <span>Don't have an account yet?? </span>
                        <Link
                            className="arti-link clickable txt-light-green"
                            to="/registration"
                        >
                            <span>Create Account</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

Login.propTypes = {};

export default Login;
