import PropTypes from "prop-types";
import React, { useState } from "react";
import Slider from "react-slick";
import landscapeYellow from "../../../assets/img/landscape_yellow.png";
import sliderLeafLeft from "../../../assets/img/slider-leaf-left.png";
import sliderLeafRight from "../../../assets/img/slider-leaf-right.png";
import sliderLowerLeft from "../../../assets/img/slider-lower-left.png";
import sliderLowerRight from "../../../assets/img/slider-lower-right.png";
import { extractAssetObj } from "../../../helpers/caboodleHelper";
import FloatingSliderSkeleton from "./FloatingSliderSkeleton";

const FloatingSlider = ({ data, hasBgColor = false }) => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true
    };

    if (data == undefined) {
        return <FloatingSliderSkeleton hasBgColor={hasBgColor} />;
    }

    const [slides, setSlides] = useState(data.items);

    if (slides == undefined || slides.length <= 0) {
        return <></>;
    }

    return (
        <div
            className={`floating-slider pad-bottom-100 ${hasBgColor &&
                "bg-green"}`}
        >
            <div className="white-bottom">
                <img className="landscape" src={landscapeYellow} />
            </div>

            <div className="container">
                <div className="items position-relative">
                    <img
                        className="floating-leaf left left-slider-leaf faded-9"
                        src={sliderLeafLeft}
                    />
                    <img
                        className="floating-leaf right right-slider-leaf faded-9"
                        src={sliderLeafRight}
                    />
                    <img
                        className="floating-leaf left left-slider-lower faded-light"
                        src={sliderLowerLeft}
                    />
                    <img
                        className="floating-leaf right right-slider-lower faded-light"
                        src={sliderLowerRight}
                    />

                    <Slider {...settings}>
                        {slides.map((item, idx) => (
                            <div key={idx}>
                                <div
                                    className="item flex direction-column pad-top-20 pad-bottom-50 align-center flex-end text-center"
                                    style={{
                                        backgroundImage: `url(${extractAssetObj(
                                            item,
                                            ["image", "path"]
                                        )})`
                                    }}
                                >
                                    <h4 className="txt-cerapro-bold txt-xxlarge">
                                        {item.title}
                                    </h4>
                                    <div
                                        className="txt-medium text-center txt-cerapro-regular"
                                        dangerouslySetInnerHTML={{
                                            __html: item.description
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

FloatingSlider.propTypes = {
    data: PropTypes.object,
    hasBgColor: PropTypes.bool
};

export default FloatingSlider;
