import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import { logoutConfirmation } from '../../../helpers/caboodleHelper';

class UserDropDown extends Component {
    state = {
        dropdownOpen: false
    };

    toggle = (state) => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }
    
    render() {
        const {categories} = this.props;
        
        return (
            <Dropdown className="cart-bag-container" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle
                    tag="div"
                    className="bag-icon-container flex align-items-center"
                    style={{
                        "--tooltip-color": (categories && categories.selected && categories.selected.color) ? categories.selected.color : "#009EE2"
                    }}
                >
                    <i className="fas fa-user-circle txt-header-2" />
                </DropdownToggle>
                <DropdownMenu
                    right
                    tag="div"
                    className="user-links-dropdown dropdown-card"
                >
                    <ul className="user-links">
                        <li>
                            <Link
                                to="/manage-account/user-info"
                                onClick={() => this.toggle(false)}
                            >
                                <div className="flex align-center relative-container">
                                    <i className="fal fa-address-book" />
                                    <span>
                                        Account
                                    </span>
                                    {
                                        this.props.alert && (
                                            <i className="fas fa-exclamation-circle user-indicator txt-medium txt-error" />
                                        )
                                    }
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/manage-account/orders"
                                onClick={() => this.toggle(false)}
                            >
                                <div className="flex align-center">
                                    <i className="fal fa-boxes" /> Orders
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/manage-account/wishlists"
                                onClick={() => this.toggle(false)}
                            >
                                <div className="flex align-center">
                                    <i className="fal fa-heart" /> Wishlist
                                </div>
                            </Link>
                        </li>
                        <li>
                            <div
                                className="clickable flex align-center"
                                onClick={() => logoutConfirmation()}
                            >
                                <i className="fal fa-sign-out" /> Logout
                            </div>
                        </li>
                    </ul>
                </DropdownMenu>
            </Dropdown>

        );
    }
}

export default UserDropDown;