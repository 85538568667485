import React, { useState } from "react";
import { Link } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { resetPassword } from "../../../helpers/caboodleHelper";

const ForgotPassword = () => {
    const MySwal = withReactContent(Swal);

    const toggleForgotPassword = async () => {
        const { value: email } = await MySwal.fire({
            title: <p>Forgot Password?</p>,
            input: "email",
            inputLabel: "Your email address",
            inputPlaceholder: "Enter your email address",
            showCloseButton: true
        });

        if (email) {
            resetPassword(email)
                .then(res => {
                    MySwal.fire(
                        "Successful!",
                        "A reset password link will be sent to your email shortly.",
                        "success"
                    );
                })
                .catch(err => {
                    MySwal.fire(err.data.message, "", "error");
                });
        }
    };

    return (
        <>
            <Link
                className="arti-link txt-small clickable"
                onClick={() => toggleForgotPassword()}
            >
                Forgot Password?
            </Link>
        </>
    );
};

export default ForgotPassword;
