import * as apiHelper from "../helpers/apiHelper";
import * as types from "../constants/actionTypes";
import { store } from "..";

export function reviewProduct(params, reducerType = false) {
    let reviewType = types.GET_PRODUCT_REVIEW;
    if (reducerType) {
        reviewType = reducerType;
    }

    return store.dispatch({
        type: reviewType,
        payload: apiHelper.connect(false).post(`/product_reviews/store`, params)
    });
}

export function resetReviews() {
    return {
        type: types.UNSELECT_PRODUCT_REVIEW,
        payload: {}
    };
}

export function updateReviewList(review) {
    return {
        type: types.UPDATE_REVIEW_LIST,
        payload: review
    };
}

export function getReviews(params) {
    return {
        type: types.GET_PRODUCT_REVIEW,
        payload: apiHelper.connect(false).get(`/product_reviews/load_more`, {
            params: params
        })
    };
}
