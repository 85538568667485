import React, {useState} from "react";
import axios from "axios";
import {
    extractAssetObj
} from "../../helpers/caboodleHelper";

export const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <>
            <div className="with-border-bottom">
                <div onClick={() => setIsActive(!isActive)}
                    className={`title-group pad-top-20 ${isActive ? "green padding-bottom-20" : ""}`}
                >
                    <div className="txt-cerapro-bold txt-large">
                        {title}
                    </div>
                    <i className={`fas fa-chevron-down ${isActive ? "hide" : ""}`}></i>
                    <i className={`fas fa-chevron-up ${isActive ? "" : "hide"}`}></i>
                </div>
                <div className={`txt-cerapro-regular txt-medium pad-bottom-20 desc ${isActive ? "show" : ""}`}
                    dangerouslySetInnerHTML={{
                        __html: content
                    }}
                >
                </div>
            </div>
            
        </>
    );
};