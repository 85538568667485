import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';

import {
        extractAssetObj
} from '../../../helpers/caboodleHelper';
import {
    getBrandsList
} from '../../../actions/brands.actions';

class BrandsList extends React.Component {

    componentDidMount() {
        getBrandsList();
    }

    render() {
        const {
                brands
            } = this.props;
        let featuredBrands = [];

        if (brands && brands.list) {
            featuredBrands = brands.list.filter(brand => {
                return brand.featured === 1;
            });
        }

        if (brands && featuredBrands && featuredBrands.length) {
            return (
                <div className="margin-top-100 pad-bottom-100 bg-gray-almost-white pad-50 position-relative">
                    <div className="txt-header-2 txt-bold-qs-bold text-center">
                        Brand Partners
                    </div>
                    <div className="partner-list grid grid-8 grid-md-4 grid-xs-2 grid-gap-50 margin-top-50 align-center">
                        {
                            featuredBrands.map((brand, index) => {
                                return (
                                    <div
                                        className="partner-card fade-in-up"
                                        key={index + "brands"}
                                        style={{
                                            animationDelay: `${(index + 1) * 0.3}s`
                                        }}
                                    >
                                        <NavLink
                                            to={`/products?brands=${brand.slug}`}
                                        >
                                            <img
                                                src={extractAssetObj(brand, ["asset", "path"])}
                                            />
                                        </NavLink>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            );
        } else {
            return false;
        }
    }
}

function mapStateToProps(state, ownProps) {
	return {
        brands: state.brands
	};
}

export default connect(mapStateToProps, null)(BrandsList);
