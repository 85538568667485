import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { extractAssetObj, removeToCart, updateUserCartProducts, resetCart, resetOrder } from '../../../helpers/caboodleHelper';
import {getCustomerCart} from '../../../actions/customer.actions';
import FormattedTotal from '../Elements/FormattedTotal';

class CartBag extends Component {
    state = {
        dropdownOpen: false
    };

    componentDidMount() {
        if (window.location.href.indexOf("success") === -1 && window.location.href.indexOf("order") === -1) {
            if (this.props.cart.expiration && (moment(moment().format("hh:mmA"), "hh:mmA").isSameOrAfter(this.props.cart.exipration))) {
                resetCart();
                resetOrder();
                alert("Your cart has expire!");
            } else {
                if (this.props.user.hasOwnProperty("name")) {
                    setTimeout(() => {
                        getCustomerCart().then(res => {
                            if (res) {
                                // console.log(res.payload);
                                if (this.props.user.customer_active_type !== res.payload.customer_type) {
    
                                    if (this.props.cart.hasOwnProperty("items") && this.props.cart.items.length > 0) {
                                        updateUserCartProducts(this.props.cart.items);
                                    }
                                }
                            }
                        });
                    }, 100);
                }
            }
        }
    }

    componentDidUpdate(oldProps) {
        if (!oldProps.user.hasOwnProperty("name") && this.props.user.hasOwnProperty("name")) {
            getCustomerCart();
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    computeSubtotal = () => {
        const { cart } = this.props;
        let subTotal = 0;
        if (cart && cart.items && cart.items.length > 0) {
            cart.items.map((item,index) => {
                subTotal += (item.product) && item.product.converted_price * item.quantity;
                return subTotal;
            });
        }

        return subTotal;
    }

    computeFormattedSubtotal = () => {
        const { cart } = this.props;
        let subTotal = 0,
            symbol = "";
        if (cart && cart.items && cart.items.length > 0) {
            cart.items.map((item,index) => {
                const formatted = item.product.formatted_price.split(" ");
                symbol = formatted[0];
                subTotal += (item.product) && parseFloat(formatted[1]) * item.quantity;
                return subTotal;
            });
        }

        return symbol + " " + subTotal;
    }

    render() {
        const { cart, categories } = this.props;
        const subTotal = this.computeSubtotal();

        return (
            <Dropdown className="cart-bag-container" isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle
                    tag="div"
                    className="bag-icon-container flex align-items-center"
                    style={{
                        "--tooltip-color": (categories && categories.selected && categories.selected.color) ? categories.selected.color : "#009EE2"
                    }}
                >
                    <div className="bag-count flex align-all-center">
                    {cart && cart.items && cart.items.length > 0 ?
                        <>{cart.items.length}</>
                        :
                        <>0</>
                    }
                    </div>
                    <i
                        className={`fas fa-shopping-cart txt-header-2`}
                    />
                </DropdownToggle>
                <DropdownMenu
                    right
                    tag="div"
                    className="cart-bag-dropdown-menu dropdown-card"
                >
                    {cart && cart.items && cart.items.length > 0 ?
                        <Fragment>
                            <ul>
                                <li className="cart-header margin-bottom-10">
                                    <div className="txt-medium txt-bold">
                                        My Cart
                                    </div>
                                    <div className="txt-small">
                                        Items: {cart.items.length}
                                    </div>
                                </li>
                            </ul>
                            <ul className="item-list">
                                {cart.items.map((item, key) => (
                                    <li className="flex cart-item" key={key}>

                                        <div className="image-container">
                                            <img 
                                                src={extractAssetObj(item.product, ['image_paths', 'path'])}
                                                alt={item.product.title}
                                            />
                                        </div>
                                        <div className="item-details">
                                            <div className="delete-item"
                                                onClick={() => removeToCart(item.product)}
                                            >
                                                <i className="fal fa-times" />
                                            </div>
                                            <span className="item-title">
                                                <Link
                                                    to={`/product/${item.product.slug}/view`}
                                                >
                                                    {item.product.title}
                                                </Link>
                                            </span>
                                            <span className="item-qty flex flex-flow-column">
                                                Quantity: {item.quantity / 1}
                                            </span>
                                            <span className="item-price">
                                                <FormattedTotal
                                                    formattedPrice={item.product.formatted_price}
                                                    qty={item.quantity}
                                                />
                                            </span>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            <ul>
                                <li className="seperator" />
                                <li className="cart-subtotal-container">
                                    <span className="subtotal-label">Total</span>
                                    <span className="subtotal-number">
                                        <FormattedTotal
                                            formattedPrice={this.computeFormattedSubtotal()}
                                            qty={1}
                                        />
                                    </span>
                                </li>
                                <li className="button-container flex">
                                    <Link
                                        onClick={this.toggle}
                                        to="/checkout/my-cart"
                                        className="btn btn-gray btn-sqr"
                                    >
                                        View Cart
                                    </Link>
                                    {/* <Link
                                        onClick={this.toggle}
                                        to="/checkout"
                                        className="btn btn-primary btn-sqr"
                                    >
                                        Checkout Now
                                    </Link> */}
                                </li>
                            </ul>
                        </Fragment>
                        : 
                    <div className="no-whitespace">
                            Your Cart is Empty
                        </div>
                    }
                    
                </DropdownMenu>
            </Dropdown>

        );
    }
}

function mapStateToProps(state) {
    return {
        cart: state.cart,
        categories: state.categories,
        user: state.user
    };
}

export default connect(
    mapStateToProps
)(CartBag);